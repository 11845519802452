import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { getScreensaver } from '../../redux/actions';

const Screensaver = ({ screensaver, getScreensaver }) => {
  const { screensavers, screensaverLoading } = screensaver;
  const { url } = useRouteMatch();

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        getScreensaver({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getScreensaver]);

  return (
    <Card>
      <CardBody>
        {screensaverLoading ? null : screensavers.length === 1 ? null : (
          <Link to={`${url}/editor?mode=Tambah`}>
            <Button color="primary">Tambah</Button>
          </Link>
        )}
        <div style={{ height: '16px' }} />
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {screensavers.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>
                    <Link to={`${url}/editor?mode=Update&id=${el.id}`}>
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                        transform="grow-12 right-6"
                        className="text-info"
                      />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Screensaver.propTypes = {
  screensaver: PropTypes.object,
  getScreensaver: PropTypes.func,
};

const mapStateToProps = (state) => ({
  screensaver: state.screensaver,
});

const mapActionToProps = {
  getScreensaver,
};

export default connect(mapStateToProps, mapActionToProps)(Screensaver);
