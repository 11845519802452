import {
  VEHICLES,
  VEHICLES_SUCCESS,
  VEHICLES_ERROR,
  CARS,
  CARS_SUCCESS,
  CARS_ERROR,
  DRIVERS,
  DRIVERS_SUCCESS,
  DRIVERS_ERROR,
  ADD_TRIP,
  ADD_TRIP_SUCCESS,
  ADD_TRIP_ERROR,
  UPDATE_TRIP,
  UPDATE_TRIP_SUCCESS,
  UPDATE_TRIP_ERROR,
  DELETE_TRIP,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    vehicles: [],
    vehiclesLoading: false,
    cars: [],
    carsLoading: false,
    drivers: [],
    driversLoading: false,
    addTripLoading: false,
    updateTripLoading: false,
    deleteTripLoading: false,
  },
  action
) => {
  switch (action.type) {
    case VEHICLES:
      return {
        ...state,
        vehiclesLoading: true,
      };
    case VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload,
        vehiclesLoading: false,
      };
    case VEHICLES_ERROR:
      return {
        ...state,
        vehiclesLoading: false,
      };

    case CARS:
      return {
        ...state,
        carsLoading: true,
      };
    case CARS_SUCCESS:
      return {
        ...state,
        cars: action.payload,
        carsLoading: false,
      };
    case CARS_ERROR:
      return {
        ...state,
        carsLoading: false,
      };

    case DRIVERS:
      return {
        ...state,
        driversLoading: true,
      };
    case DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload,
        driversLoading: false,
      };
    case DRIVERS_ERROR:
      return {
        ...state,
        driversLoading: false,
      };

    case ADD_TRIP:
      return {
        ...state,
        addTripLoading: true,
      };
    case ADD_TRIP_SUCCESS:
      return {
        ...state,
        addTripLoading: false,
      };
    case ADD_TRIP_ERROR:
      return {
        ...state,
        addTripLoading: false,
      };

    case UPDATE_TRIP:
      return {
        ...state,
        updateTripLoading: true,
      };
    case UPDATE_TRIP_SUCCESS:
      return {
        ...state,
        updateTripLoading: false,
      };
    case UPDATE_TRIP_ERROR:
      return {
        ...state,
        updateTripLoading: false,
      };

    case DELETE_TRIP:
      return {
        ...state,
        deleteTripLoading: true,
      };
    case DELETE_TRIP_SUCCESS:
      return {
        ...state,
        deleteTripLoading: false,
      };
    case DELETE_TRIP_ERROR:
      return {
        ...state,
        deleteTripLoading: false,
      };

    default:
      return state;
  }
};
