import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import { getDispositionHead, addDispositionHead, deleteDispositionHead } from '../../../redux/actions';

const Head = ({ dispositionhead, getDispositionHead, addDispositionHead, deleteDispositionHead }) => {
  const { register, handleSubmit, errors } = useForm();
  const [token, setToken] = useState(null);
  const { disposition, addDispositionLoading } = dispositionhead;
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({
    name: '',
    order: -1,
    positionType: 'Other',
  });
  const positionType = ['From', 'Head', 'Unit', 'Other'];

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getDispositionHead({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDispositionHead]);

  const resetForm = () => {
    setShowForm(false);
    setForm({
      name: '',
      order: -1,
      positionType: 'Other',
    });
  };

  const reload = () => {
    getDispositionHead({ token });
  };

  const onSubmit = (data) => {
    addDispositionHead({
      ...form,
      ...data,
      resetForm,
      reload,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        <>
          <Button
            onClick={() => {
              setShowForm(!showForm);
            }}
            color="primary"
          >
            Tambah
          </Button>
          <div style={{ height: '16px' }} />
        </>
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="name">
                Nama<span style={{ color: 'red' }}>*</span>{' '}
                {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.name}
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="order">
                Urutan<span style={{ color: 'red' }}>*</span>{' '}
                {errors.order && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.order}
                type="text"
                name="order"
                id="order"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="positionType">
                Tipe<span style={{ color: 'red' }}>*</span>{' '}
                {errors.positionType && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.positionType}
                type="select"
                name="positionType"
                id="positionType"
                innerRef={register({ required: true })}
              >
                {positionType.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Button disabled={addDispositionLoading} type="submit" color="primary" block className="mt-3">
                Tambah
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>Urutan</th>
                <th>Tipe</th>
              </tr>
            </thead>
            <tbody>
              {disposition
                .sort((i, j) => {
                  return i.order > j.order;
                })
                .map((el, i) => (
                  <tr key={el.id}>
                    <th>{i + 1}</th>
                    <td>{el.name}</td>
                    <td>{el.order}</td>
                    <td>{el.positionType}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Head.propTypes = {
  dispositionhead: PropTypes.object,
  getDispositionHead: PropTypes.func,
  addDispositionHead: PropTypes.func,
  deleteDispositionHead: PropTypes.func,
};

const mapStateToProps = (state) => ({
  dispositionhead: state.dispositionhead,
});

const mapActionToProps = {
  getDispositionHead,
  addDispositionHead,
  deleteDispositionHead,
};

export default connect(mapStateToProps, mapActionToProps)(Head);
