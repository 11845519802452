import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

import { getUMKM, deleteUMKM } from '../../redux/actions';

const UMKM = ({ umkm, getUMKM, deleteUMKM }) => {
  const { umkms } = umkm;
  const [type, setType] = useState('');
  const { url } = useRouteMatch();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getUMKM({ token: JSON.parse(data).data.token, type });
      }
    };
    getUserData();
  }, [getUMKM, type]);

  return (
    <Card>
      <CardBody>
        <Link to={`${url}/editor?mode=Tambah`}>
          <Button color="primary">Tambah</Button>
        </Link>
        <FormGroup>
          <Label for="type">
            Tipe<span style={{ color: 'red' }}>*</span>{' '}
          </Label>
          <Input value={type} type="select" name="type" id="type" onChange={(e) => setType(e.target.value)}>
            <option value="">Pilih Tipe</option>
            <option value="UMKM">UMKM</option>
            <option value="KUPVA">KUPVA</option>
            <option value="PTD">PTD</option>
            <option value="PSBI">PSBI</option>
          </Input>
        </FormGroup>
        <div style={{ height: '16px' }} />
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                {type === 'KUPVA' ? <th>No. Urut Cabang</th> : null}
                <th>Nama</th>
                <th>Alamat</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {umkms.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  {type === 'KUPVA' ? <td>{el.orderSeq}</td> : null}
                  <td>{el.name}</td>
                  <td>{el.address}</td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Link to={`${url}/editor?mode=Update&id=${el.id}`}>
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faPencilAlt}
                          transform="grow-12 right-6"
                          className="text-info"
                        />
                      </Link>
                      <div style={{ width: '16px' }} />
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faTrash}
                        transform="grow-12 right-6"
                        className="text-danger"
                        onClick={() => deleteUMKM({ token, getUMKM, id: el.id, type })}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

UMKM.propTypes = {
  umkm: PropTypes.object,
  getUMKM: PropTypes.func,
  deleteUMKM: PropTypes.func,
};

const mapStateToProps = (state) => ({
  umkm: state.umkm,
});

const mapActionToProps = {
  getUMKM,
  deleteUMKM,
};

export default connect(mapStateToProps, mapActionToProps)(UMKM);
