import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DatePicker, TimePicker } from '@material-ui/pickers';

import { getAgendas, addAgenda, updateAgenda, deleteAgenda } from '../../redux/actions';

const Agendas = ({ agenda, getAgendas, addAgenda, updateAgenda, deleteAgenda }) => {
  const [token, setToken] = useState(null);
  const { agendas, addAgendaLoading, updateAgendaLoading } = agenda;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    date: new Date(),
    toDate: new Date(),
    allday: false,
    from: new Date(),
    to: new Date(),
    name: '',
    description: '',
    location: '',
    additional: '',
  });

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getAgendas({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getAgendas]);

  const onChangeInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      date: new Date(),
      toDate: new Date(),
      allday: false,
      from: new Date(),
      to: new Date(),
      name: '',
      description: '',
      location: '',
      additional: '',
    });
  };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v.id,
      date: new Date(v.date),
      toDate: new Date(v.toDate),
      allday: v.allday,
      from: new Date(v.from),
      to: new Date(v.to),
      name: v.name,
      description: v.description,
      location: v.location,
      additional: v.additional,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (mode === 'Tambah') {
      return addAgenda({
        ...form,
        resetForm,
        getAgendas,
        token,
      });
    }
    updateAgenda({
      ...form,
      resetForm,
      getAgendas,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah Agenda
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={onSubmit.bind(this)}>
            <Label>Tanggal</Label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormGroup>
                <Label>Mulai</Label>
                <br />
                <DatePicker
                  format="D MMMM YYYY"
                  value={form.date}
                  onChange={(date) => {
                    setForm({
                      ...form,
                      date: new Date(date.toISOString()),
                    });
                  }}
                  animateYearScrolling
                  inputVariant="outlined"
                  autoOk
                />
              </FormGroup>
              <FormGroup style={{ marginLeft: '16px' }}>
                <Label>Sampai</Label>
                <br />
                <DatePicker
                  format="D MMMM YYYY"
                  value={form.toDate}
                  onChange={(date) => {
                    setForm({
                      ...form,
                      toDate: new Date(date.toISOString()),
                    });
                  }}
                  animateYearScrolling
                  inputVariant="outlined"
                  autoOk
                />
              </FormGroup>
            </div>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={form.allday}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      allday: e.target.checked,
                    })
                  }
                />{' '}
                All-day
              </Label>
            </FormGroup>
            {!form.allday && (
              <>
                <Label>Jam</Label>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormGroup>
                    <Label>Mulai</Label>
                    <br />
                    <TimePicker
                      ampm={false}
                      format="HH:mm"
                      value={form.from}
                      onChange={(date) => {
                        setForm({
                          ...form,
                          from: new Date(date.toISOString()),
                        });
                      }}
                      animateYearScrolling
                      inputVariant="outlined"
                      autoOk
                    />
                  </FormGroup>
                  <FormGroup style={{ marginLeft: '16px' }}>
                    <Label>Sampai</Label>
                    <br />
                    <TimePicker
                      ampm={false}
                      format="HH:mm"
                      value={form.to}
                      onChange={(date) => {
                        setForm({
                          ...form,
                          to: new Date(date.toISOString()),
                        });
                      }}
                      animateYearScrolling
                      inputVariant="outlined"
                      autoOk
                    />
                  </FormGroup>
                </div>
              </>
            )}
            <FormGroup>
              <Label for="name">Subject</Label>
              <Input value={form.name} type="text" name="name" id="name" onChange={onChangeInput.bind(this)} />
            </FormGroup>
            <FormGroup>
              <Label for="location">Lokasi</Label>
              <Input
                value={form.location}
                type="text"
                name="location"
                id="location"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Deskripsi</Label>
              <Input
                value={form.description}
                type="textarea"
                name="description"
                id="description"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="additional">Informasi Tambahan</Label>
              <Input
                value={form.additional}
                type="text"
                name="additional"
                id="additional"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Button
                disabled={addAgendaLoading || updateAgendaLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Tanggal</th>
                <th>Allday</th>
                <th>Waktu</th>
                <th>Perihal</th>
                <th>Deskripsi</th>
                <th>Lokasi</th>
                <th>Informasi Tambahan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {agendas.map((el, i) => {
                const a = moment(el.date).format('D MMMM YYYY');
                const b = moment(el.toDate).format('D MMMM YYYY');
                return (
                  <tr key={el.id}>
                    <th>{i + 1}</th>
                    <td>{a === b ? a : `${a} - ${b}`}</td>
                    <td>
                      {el.allday ? (
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCheck}
                          transform="grow-12 right-6"
                          className="text-success"
                        />
                      ) : (
                        <FontAwesomeIcon size="sm" icon={faTimes} transform="grow-12 right-6" className="text-danger" />
                      )}
                    </td>
                    <td>
                      {moment(el.from).format('HH:mm')} - {moment(el.to).format('HH:mm')}
                    </td>
                    <td>{el.name}</td>
                    <td>{el.description}</td>
                    <td>{el.location}</td>
                    <td>{el.additional}</td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faPencilAlt}
                          transform="grow-12 right-6"
                          className="text-info"
                          onClick={setFilledForm.bind(this, el)}
                        />
                        <div style={{ width: '16px' }} />
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faTrash}
                          transform="grow-12 right-6"
                          className="text-danger"
                          onClick={() => deleteAgenda({ id: el.id, getAgendas, token })}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Agendas.propTypes = {
  agenda: PropTypes.object,
  getAgendas: PropTypes.func,
  addAgenda: PropTypes.func,
  updateAgenda: PropTypes.func,
  deleteAgenda: PropTypes.func,
};

const mapStateToProps = (state) => ({
  agenda: state.agenda,
});

const mapActionToProps = {
  getAgendas,
  addAgenda,
  updateAgenda,
  deleteAgenda,
};

export default connect(mapStateToProps, mapActionToProps)(Agendas);
