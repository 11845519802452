import {
  ACTIVITIES,
  ACTIVITIES_SUCCESS,
  ACTIVITIES_ERROR,
  ADD_ACTIVITY,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_ERROR,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_ACTIVITY,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    activities: [],
    activitiesLoading: false,
    addActivityLoading: false,
    updateActivityLoading: false,
    deleteActivityLoading: false,
  },
  action
) => {
  switch (action.type) {
    case ACTIVITIES:
      return {
        ...state,
        activitiesLoading: true,
      };
    case ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        activitiesLoading: false,
      };
    case ACTIVITIES_ERROR:
      return {
        ...state,
        activitiesLoading: false,
      };

    case ADD_ACTIVITY:
      return {
        ...state,
        addActivityLoading: true,
      };
    case ADD_ACTIVITY_SUCCESS:
      return {
        ...state,
        addActivityLoading: false,
      };
    case ADD_ACTIVITY_ERROR:
      return {
        ...state,
        addActivityLoading: false,
      };

    case UPDATE_ACTIVITY:
      return {
        ...state,
        updateActivityLoading: true,
      };
    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        updateActivityLoading: false,
      };
    case UPDATE_ACTIVITY_ERROR:
      return {
        ...state,
        updateActivityLoading: false,
      };

    case DELETE_ACTIVITY:
      return {
        ...state,
        deleteActivityLoading: true,
      };
    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteActivityLoading: false,
      };
    case DELETE_ACTIVITY_ERROR:
      return {
        ...state,
        deleteActivityLoading: false,
      };

    default:
      return state;
  }
};
