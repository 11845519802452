import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHost, getHostV2 } from '../../constants';
import {
  MEETING_ROOM_SUCCESS,
  MEETING_ROOM_ERROR,
  ADD_MEETING_ROOM_SUCCESS,
  ADD_MEETING_ROOM_ERROR,
  UPDATE_MEETING_ROOM_SUCCESS,
  UPDATE_MEETING_ROOM_ERROR,
  MEETING_SUCCESS,
  MEETING_ERROR,
  ADD_MEETING_SUCCESS,
  ADD_MEETING_ERROR,
  UPDATE_MEETING_SUCCESS,
  UPDATE_MEETING_ERROR,
  DELETE_MEETING_SUCCESS,
  DELETE_MEETING_ERROR,
} from '../../constants/ActionTypes';

export function* fetchMeetingRooms(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/meeting-room`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: MEETING_ROOM_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: MEETING_ROOM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: MEETING_ROOM_ERROR };
    });
  yield put(result);
}

export function* createMeetingRoom(params) {
  const { name, location, capacity, facility, cover, resetForm, getMeetingRooms, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/meeting-room`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      location,
      capacity: parseInt(capacity),
      facility,
      cover,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getMeetingRooms({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan kegiatan');
        return { type: ADD_MEETING_ROOM_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_MEETING_ROOM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_MEETING_ROOM_ERROR };
    });
  yield put(result);
}

export function* updateMeetingRoom(params) {
  const { id, name, location, capacity, facility, cover, resetForm, getMeetingRooms, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/meeting-room/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      location,
      capacity,
      facility,
      cover,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getMeetingRooms({ token });
        toast.success('Berhasil memperbarui kegiatan');
        return { type: UPDATE_MEETING_ROOM_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_MEETING_ROOM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_MEETING_ROOM_ERROR };
    });
  yield put(result);
}

export function* fetchMeetings(params) {
  const { token, from, to, internal } = params.payload;
  let query = '';
  if (from && to) {
    query = `?from=${from}&to=${to}`;
  } else if (from) {
    query = `?from=${from}`;
  }
  query = `${query}&internal=${internal}`;
  const result = yield fetch(`${getHostV2()}/v1/meeting${query}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: MEETING_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: MEETING_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: MEETING_ERROR };
    });
  yield put(result);
}

export function* createMeeting(params) {
  const { meetingRoom, agenda, startTime, endTime, description, internal, resetForm, getMeetings, token } =
    params.payload;
  const result = yield fetch(`${getHostV2()}/v1/meeting`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      meetingRoom,
      agenda,
      startTime,
      endTime,
      description,
      internal,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getMeetings({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan kegiatan');
        return { type: ADD_MEETING_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_MEETING_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_MEETING_ERROR };
    });
  yield put(result);
}

export function* updateMeeting(params) {
  const { id, meetingRoom, agenda, startTime, endTime, description, internal, resetForm, getMeetings, token } =
    params.payload;
  const result = yield fetch(`${getHostV2()}/v1/meeting/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      meetingRoom,
      agenda,
      startTime,
      endTime,
      description,
      internal,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getMeetings({ token });
        toast.success('Berhasil memperbarui kegiatan');
        return { type: UPDATE_MEETING_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_MEETING_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_MEETING_ERROR };
    });
  yield put(result);
}

export function* deleteMeeting(params) {
  const { id, token, getMeetings } = params.payload;
  const result = yield fetch(`${getHost()}/api/meeting/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getMeetings({ token });
        toast.success('Berhasil menghapus meeting');
        return { type: DELETE_MEETING_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_MEETING_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_MEETING_ERROR };
    });
  yield put(result);
}
