import {
  SCREEN_SAVER,
  SCREEN_SAVER_SUCCESS,
  SCREEN_SAVER_ERROR,
  ADD_SCREEN_SAVER,
  ADD_SCREEN_SAVER_SUCCESS,
  ADD_SCREEN_SAVER_ERROR,
  UPDATE_SCREEN_SAVER,
  UPDATE_SCREEN_SAVER_SUCCESS,
  UPDATE_SCREEN_SAVER_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    screensavers: [],
    screensaverLoading: false,
    addScreensaverLoading: false,
    updateScreensaverLoading: false,
  },
  action
) => {
  switch (action.type) {
    case SCREEN_SAVER:
      return {
        ...state,
        screensaverLoading: true,
      };
    case SCREEN_SAVER_SUCCESS:
      return {
        ...state,
        screensavers: action.payload,
        screensaverLoading: false,
      };
    case SCREEN_SAVER_ERROR:
      return {
        ...state,
        screensaverLoading: false,
      };

    case ADD_SCREEN_SAVER:
      return {
        ...state,
        addScreensaverLoading: true,
      };
    case ADD_SCREEN_SAVER_SUCCESS:
      return {
        ...state,
        addScreensaverLoading: false,
      };
    case ADD_SCREEN_SAVER_ERROR:
      return {
        ...state,
        addScreensaverLoading: false,
      };

    case UPDATE_SCREEN_SAVER:
      return {
        ...state,
        updateScreensaverLoading: true,
      };
    case UPDATE_SCREEN_SAVER_SUCCESS:
      return {
        ...state,
        updateScreensaverLoading: false,
      };
    case UPDATE_SCREEN_SAVER_ERROR:
      return {
        ...state,
        updateScreensaverLoading: false,
      };
    default:
      return state;
  }
};
