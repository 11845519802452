import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import DocumentReducer from './DocumentReducer';
import AgendaReducer from './AgendaReducer';
import VehicleReducer from './VehicleReducer';
import UMKMReducer from './UMKMReducer';
import ActivityReducer from './ActivityReducer';
import MeetingReducer from './MeetingReducer';
import ScreensaverReducer from './ScreensaverReducer';
import ScreensaverVideoReducer from './ScreensaverVideoReducer';
import DispositionHeadReducer from './DispositionHeadReducer';
import DispositionUnitReducer from './DispositionUnitReducer';
import DispositionActionReducer from './DispositionActionReducer';
import TripRequest from './TripRequest';

export default combineReducers({
  user: UserReducer,
  document: DocumentReducer,
  agenda: AgendaReducer,
  vehicle: VehicleReducer,
  umkm: UMKMReducer,
  activity: ActivityReducer,
  meeting: MeetingReducer,
  screensaver: ScreensaverReducer,
  screensaverVideo: ScreensaverVideoReducer,
  dispositionhead: DispositionHeadReducer,
  dispositionunit: DispositionUnitReducer,
  dispositionaction: DispositionActionReducer,
  triprequest: TripRequest,
});
