import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHostV2 } from '../../constants';
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  USERS_SUCCESS,
  USERS_ERROR,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from '../../constants/ActionTypes';

export function* doLogin(params) {
  const { nip, password, history, from } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      nip,
      password,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        localStorage.setItem('loginInformation', JSON.stringify(response));
        history.replace(from);
        toast.success('Berhasil login');
        return { type: LOGIN_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: LOGIN_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: LOGIN_ERROR };
    });
  yield put(result);
}

export function* fetchUsers(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/user`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: USERS_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: USERS_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: USERS_ERROR };
    });
  yield put(result);
}

export function* createUser(params) {
  const { name, nip, password, position1, position2, occupation1, occupation2, resetForm, getUsers, token } =
    params.payload;
  const result = yield fetch(`${getHostV2()}/v1/user/add-user`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      nip,
      password,
      position1,
      position2,
      occupation1,
      occupation2,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getUsers({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan user');
        return { type: ADD_USER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_USER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_USER_ERROR };
    });
  yield put(result);
}

export function* updateUser(params) {
  const {
    id,
    name,
    nip,
    password,
    position1,
    position2,
    occupation1,
    occupation2,
    isActive,
    resetForm,
    getUsers,
    token,
  } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/user/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      nip,
      password,
      position1,
      position2,
      occupation1,
      occupation2,
      isActive,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getUsers({ token });
        toast.success('Berhasil memperbarui user');
        return { type: UPDATE_USER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_USER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_USER_ERROR };
    });
  yield put(result);
}

export function* deleteUser(params) {
  const { id, token, getUsers } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/user/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getUsers({ token });
        toast.success('Berhasil menghapus user');
        return { type: DELETE_USER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_USER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_USER_ERROR };
    });
  yield put(result);
}
