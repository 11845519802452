import {
  DOCUMENTS,
  DOCUMENTS_SUCCESS,
  DOCUMENTS_ERROR,
  ADD_DOCUMENT,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  DOCUMENT_CONFIG,
  DOCUMENT_CONFIG_SUCCESS,
  DOCUMENT_CONFIG_ERROR,
  ADD_DOCUMENT_CONFIG,
  ADD_DOCUMENT_CONFIG_SUCCESS,
  ADD_DOCUMENT_CONFIG_ERROR,
  UPDATE_DOCUMENT_CONFIG,
  UPDATE_DOCUMENT_CONFIG_SUCCESS,
  UPDATE_DOCUMENT_CONFIG_ERROR,
  DOCUMENT_NUMBER,
  DOCUMENT_NUMBER_SUCCESS,
  DOCUMENT_NUMBER_ERROR,
  ADD_DOCUMENT_NUMBER,
  ADD_DOCUMENT_NUMBER_SUCCESS,
  ADD_DOCUMENT_NUMBER_ERROR,
  UPDATE_DOCUMENT_NUMBER,
  UPDATE_DOCUMENT_NUMBER_SUCCESS,
  UPDATE_DOCUMENT_NUMBER_ERROR,
  DELETE_DOCUMENT_NUMBER,
  DELETE_DOCUMENT_NUMBER_SUCCESS,
  DELETE_DOCUMENT_NUMBER_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    documents: [],
    documentsLoading: false,
    addDocumentLoading: false,
    deleteDocumentLoading: false,
    documentConfigs: [],
    documentConfigsLoading: false,
    addDocumentConfigLoading: false,
    updateDocumentConfigLoading: false,
    documentNumbers: [],
    documentNumbersLoading: false,
    addDocumentNumberLoading: false,
    updateDocumentNumberLoading: false,
    deleteDocumentNumberLoading: false,
  },
  action
) => {
  switch (action.type) {
    case DOCUMENTS:
      return {
        ...state,
        documentsLoading: true,
      };
    case DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        documentsLoading: false,
      };
    case DOCUMENTS_ERROR:
      return {
        ...state,
        documentsLoading: false,
      };

    case ADD_DOCUMENT:
      return {
        ...state,
        addDocumentLoading: true,
      };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        addDocumentLoading: false,
      };
    case ADD_DOCUMENT_ERROR:
      return {
        ...state,
        addDocumentLoading: false,
      };

    case DELETE_DOCUMENT:
      return {
        ...state,
        deleteDocumentLoading: true,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        deleteDocumentLoading: false,
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        deleteDocumentLoading: false,
      };

    case DOCUMENT_CONFIG:
      return {
        ...state,
        documentConfigsLoading: true,
      };
    case DOCUMENT_CONFIG_SUCCESS:
      return {
        ...state,
        documentConfigs: action.payload,
        documentConfigsLoading: false,
      };
    case DOCUMENT_CONFIG_ERROR:
      return {
        ...state,
        documentConfigsLoading: false,
      };

    case ADD_DOCUMENT_CONFIG:
      return {
        ...state,
        addDocumentConfigLoading: true,
      };
    case ADD_DOCUMENT_CONFIG_SUCCESS:
      return {
        ...state,
        addDocumentConfigLoading: false,
      };
    case ADD_DOCUMENT_CONFIG_ERROR:
      return {
        ...state,
        addDocumentConfigLoading: false,
      };

    case UPDATE_DOCUMENT_CONFIG:
      return {
        ...state,
        updateDocumentConfigLoading: true,
      };
    case UPDATE_DOCUMENT_CONFIG_SUCCESS:
      return {
        ...state,
        updateDocumentConfigLoading: false,
      };
    case UPDATE_DOCUMENT_CONFIG_ERROR:
      return {
        ...state,
        updateDocumentConfigLoading: false,
      };

    case DOCUMENT_NUMBER:
      return {
        ...state,
        documentNumbersLoading: true,
      };
    case DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        documentNumbers: action.payload,
        documentNumbersLoading: false,
      };
    case DOCUMENT_NUMBER_ERROR:
      return {
        ...state,
        documentNumbersLoading: false,
      };

    case ADD_DOCUMENT_NUMBER:
      return {
        ...state,
        addDocumentNumberLoading: true,
      };
    case ADD_DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        addDocumentNumberLoading: false,
      };
    case ADD_DOCUMENT_NUMBER_ERROR:
      return {
        ...state,
        addDocumentNumberLoading: false,
      };

    case UPDATE_DOCUMENT_NUMBER:
      return {
        ...state,
        updateDocumentNumberLoading: true,
      };
    case UPDATE_DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        updateDocumentNumberLoading: false,
      };
    case UPDATE_DOCUMENT_NUMBER_ERROR:
      return {
        ...state,
        updateDocumentNumberLoading: false,
      };

    case DELETE_DOCUMENT_NUMBER:
      return {
        ...state,
        deleteDocumentNumberLoading: true,
      };
    case DELETE_DOCUMENT_NUMBER_SUCCESS:
      return {
        ...state,
        deleteDocumentNumberLoading: false,
      };
    case DELETE_DOCUMENT_NUMBER_ERROR:
      return {
        ...state,
        deleteDocumentNumberLoading: false,
      };
    default:
      return state;
  }
};
