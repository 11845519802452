import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { getActivities, deleteActivity } from '../../redux/actions';

const Activities = ({ activity, getActivities, deleteActivity }) => {
  const { activities } = activity;
  const { url } = useRouteMatch();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getActivities({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getActivities]);

  return (
    <Card>
      <CardBody>
        <Link to={`${url}/editor?mode=Tambah`}>
          <Button color="primary">Tambah Kegiatan</Button>
        </Link>
        <div style={{ height: '16px' }} />
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>Lokasi</th>
                <th>Tanggal</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {activities.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>{el.name}</td>
                  <td>{el.location}</td>
                  <td>{moment(el.datetime).format('D MMMM YYYY')}</td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Link to={`${url}/editor?mode=Update&id=${el.id}`}>
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faPencilAlt}
                          transform="grow-12 right-6"
                          className="text-info"
                        />
                      </Link>
                      <div style={{ width: '16px' }} />
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faTrash}
                        transform="grow-12 right-6"
                        className="text-danger"
                        onClick={() => deleteActivity({ token, getActivities, id: el.id })}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Activities.propTypes = {
  activity: PropTypes.object,
  getActivities: PropTypes.func,
  deleteActivity: PropTypes.func,
};

const mapStateToProps = (state) => ({
  activity: state.activity,
});

const mapActionToProps = {
  getActivities,
  deleteActivity,
};

export default connect(mapStateToProps, mapActionToProps)(Activities);
