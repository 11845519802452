import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHost } from '../../constants';
import {
  AGENDAS_SUCCESS,
  AGENDAS_ERROR,
  ADD_AGENDA_SUCCESS,
  ADD_AGENDA_ERROR,
  UPDATE_AGENDA_SUCCESS,
  UPDATE_AGENDA_ERROR,
  DELETE_AGENDA_SUCCESS,
  DELETE_AGENDA_ERROR,
} from '../../constants/ActionTypes';

export function* fetchAgendas(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/agenda`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: AGENDAS_SUCCESS, payload: response.agenda };
      } else {
        toast.error(response.message);
        return { type: AGENDAS_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: AGENDAS_ERROR };
    });
  yield put(result);
}

export function* createAgenda(params) {
  const { date, toDate, allday, from, to, name, description, location, additional, resetForm, getAgendas, token } =
    params.payload;
  const result = yield fetch(`${getHost()}/api/agenda`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: allday
      ? JSON.stringify({
          date,
          toDate: toDate ? new Date(toDate) : null,
          allday,
          name,
          description,
          location,
          additional,
          from: null,
          to: null,
        })
      : JSON.stringify({
          date,
          toDate: toDate ? new Date(toDate) : null,
          allday,
          from,
          to,
          name,
          description,
          location,
          additional,
        }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getAgendas({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan agenda');
        return { type: ADD_AGENDA_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_AGENDA_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_AGENDA_ERROR };
    });
  yield put(result);
}

export function* updateAgenda(params) {
  const { id, date, toDate, allday, from, to, name, description, location, additional, resetForm, getAgendas, token } =
    params.payload;
  const result = yield fetch(`${getHost()}/api/agenda/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: allday
      ? JSON.stringify({
          date,
          toDate: toDate ? new Date(toDate) : null,
          allday,
          name,
          description,
          location,
          additional,
          from: null,
          to: null,
        })
      : JSON.stringify({
          date,
          toDate: toDate ? new Date(toDate) : null,
          allday,
          from,
          to,
          name,
          description,
          location,
          additional,
        }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getAgendas({ token });
        toast.success('Berhasil memperbarui agenda');
        return { type: UPDATE_AGENDA_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_AGENDA_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_AGENDA_ERROR };
    });
  yield put(result);
}

export function* deleteAgenda(params) {
  const { id, token, getAgendas } = params.payload;
  const result = yield fetch(`${getHost()}/api/agenda/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getAgendas({ token });
        toast.success('Berhasil menghapus agenda');
        return { type: DELETE_AGENDA_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_AGENDA_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_AGENDA_ERROR };
    });
  yield put(result);
}
