import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHostV2 } from '../../constants';
import {
  UMKM_SUCCESS,
  UMKM_ERROR,
  ADD_UMKM_SUCCESS,
  ADD_UMKM_ERROR,
  UPDATE_UMKM_SUCCESS,
  UPDATE_UMKM_ERROR,
  DELETE_UMKM_SUCCESS,
  DELETE_UMKM_ERROR,
} from '../../constants/ActionTypes';

export function* fetchUMKMs(params) {
  const { token, type } = params.payload;
  var query = '';
  if (type) {
    query = `?type=${type}`;
  }
  const result = yield fetch(`${getHostV2()}/v1/maps${query}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: UMKM_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: UMKM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UMKM_ERROR };
    });
  yield put(result);
}

export function* createUMKM(params) {
  const {
    name,
    description,
    address,
    product,
    latitude,
    longitude,
    cover,
    photos,
    contact,
    email,
    type,
    orderSeq,
    owner,
    resetForm,
    token,
    expiredAt,
    umkmType,
  } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/maps`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      description,
      address,
      product,
      latitude,
      longitude,
      cover,
      photos,
      contact,
      email,
      type,
      orderSeq: parseInt(orderSeq),
      owner,
      expiredAt,
      umkmType,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan UMKM');
        return { type: ADD_UMKM_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_UMKM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_UMKM_ERROR };
    });
  yield put(result);
}

export function* updateUMKM(params) {
  const {
    id,
    name,
    description,
    address,
    product,
    latitude,
    longitude,
    cover,
    photos,
    contact,
    email,
    type,
    orderSeq,
    owner,
    token,
    expiredAt,
    umkmType,
  } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/maps/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      description,
      address,
      product,
      latitude,
      longitude,
      cover,
      photos,
      contact,
      email,
      type,
      orderSeq: parseInt(orderSeq),
      owner,
      expiredAt,
      umkmType,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        toast.success('Berhasil memperbarui UMKM');
        return { type: UPDATE_UMKM_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_UMKM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_UMKM_ERROR };
    });
  yield put(result);
}

export function* deleteUMKM(params) {
  const { id, token, getUMKM, type } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/maps/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getUMKM({ token, type });
        toast.success('Berhasil menghapus');
        return { type: DELETE_UMKM_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_UMKM_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_UMKM_ERROR };
    });
  yield put(result);
}
