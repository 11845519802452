import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { taskMiddleware } from 'react-palm/tasks';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Layout from './layouts/Layout';

import reducers from './redux/reducers';
import rootSaga from './redux/sagas';
import AppContext from './context/Context';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

import 'antd/dist/antd.css';

const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const store = createStore(reducers, initialState, applyMiddleware(taskMiddleware, sagaMiddleware));

sagaMiddleware.run(rootSaga);

const publicUrl = process.env.PUBLIC_URL;

const App = () => {
  const { isDark } = useContext(AppContext);
  const theme = createMuiTheme({
    palette: {
      type: isDark ? 'dark' : 'light',
    },
  });
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router basename={publicUrl}>
            <Layout />
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

export default App;
