import {
  TRIP_REQUEST,
  TRIP_REQUEST_SUCCESS,
  TRIP_REQUEST_ERROR,
  UPDATE_TRIP_REQUEST,
  UPDATE_TRIP_REQUEST_SUCCESS,
  UPDATE_TRIP_REQUEST_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    tripRequest: [],
    tripRequestLoading: false,
    updateTripRequestLoading: false,
  },
  action
) => {
  switch (action.type) {
    case TRIP_REQUEST:
      return {
        ...state,
        tripRequestLoading: true,
      };
    case TRIP_REQUEST_SUCCESS:
      return {
        ...state,
        tripRequest: action.payload,
        tripRequestLoading: false,
      };
    case TRIP_REQUEST_ERROR:
      return {
        ...state,
        tripRequestLoading: false,
      };

    case UPDATE_TRIP_REQUEST:
      return {
        ...state,
        updateTripRequestLoading: true,
      };
    case UPDATE_TRIP_REQUEST_SUCCESS:
      return {
        ...state,
        updateTripRequestLoading: false,
      };
    case UPDATE_TRIP_REQUEST_ERROR:
      return {
        ...state,
        updateTripRequestLoading: false,
      };
    default:
      return state;
  }
};
