export const getHost = () => {
  return 'https://bankindonesia-backend.reynandaptr.dev';
};

export const getHostV2 = () => {
  return 'https://gurindam-backend.reynandaptr.dev';
};

// export const getHostV2 = () => {
//   return 'http://localhost:3001';
// };
