import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHostV2 } from '../../constants';
import {
  DISPOSITION_HEAD_SUCCESS,
  DISPOSITION_HEAD_ERROR,
  ADD_DISPOSITION_HEAD_SUCCESS,
  ADD_DISPOSITION_HEAD_ERROR,
  DELETE_DISPOSITION_HEAD_SUCCESS,
  DELETE_DISPOSITION_HEAD_ERROR,
} from '../../constants/ActionTypes';

export function* fetchDispositionHead(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/position`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: DISPOSITION_HEAD_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: DISPOSITION_HEAD_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DISPOSITION_HEAD_ERROR };
    });
  yield put(result);
}

export function* createDispositionHead(params) {
  const { name, order, positionType, resetForm, reload, token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/position`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      order,
      positionType,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        resetForm();
        reload();
        toast.success('Berhasil menambahkan');
        return { type: ADD_DISPOSITION_HEAD_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_DISPOSITION_HEAD_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_DISPOSITION_HEAD_ERROR };
    });
  yield put(result);
}

export function* deleteDispositionHead(params) {
  const { id, reload, token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/position/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        reload();
        toast.success('Berhasil menghapus');
        return { type: DELETE_DISPOSITION_HEAD_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_DISPOSITION_HEAD_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_DISPOSITION_HEAD_ERROR };
    });
  yield put(result);
}
