import React from 'react';
import { Card, CardBody } from 'reactstrap';

import { getHostV2 } from '../../constants';

const DocumentNumberReport = () => {
  return (
    <Card>
      <CardBody>
        <a
          style={{ margin: '8px 0' }}
          className="btn btn-primary"
          href={`${getHostV2()}/v1/document-number/download/report`}
        >
          Download
        </a>
      </CardBody>
    </Card>
  );
};

export default DocumentNumberReport;
