import {
  faHome,
  faUser,
  faFile,
  faCalendar,
  faCar,
  faFileExcel,
  faMapMarkerAlt,
  faUserFriends,
  faCalendarWeek,
  faFileWord,
  faImages,
} from '@fortawesome/free-solid-svg-icons';

export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: faHome,
};

export const usersRoutes = {
  name: 'Users',
  to: '/users',
  exact: true,
  icon: faUser,
};

export const documentRoutes = {
  name: 'Dokumen',
  to: '/documents',
  exact: true,
  icon: faFile,
};

export const agendasRoutes = {
  name: 'Agenda',
  to: '/agendas',
  exact: true,
  icon: faCalendar,
};

// export const vehiclesRoutes = {
//   name: 'Kendaraan',
//   to: '/vehicles',
//   exact: true,
//   icon: faCar
// };

export const reportsRoutes = {
  name: 'Report',
  to: '/reports',
  exact: true,
  icon: faFileExcel,
};

export const umkmsRoutes = {
  name: 'Maps',
  to: '/maps',
  exact: true,
  icon: faMapMarkerAlt,
};

export const activitiesRoutes = {
  name: 'Kegiatan',
  to: '/activity',
  exact: true,
  icon: faUserFriends,
};

export const meetingsRoutes = {
  name: 'Meeting',
  to: '/meeting',
  exact: true,
  icon: faCalendarWeek,
  children: [
    {
      name: 'Meeting',
      to: '/meeting',
      exact: true,
    },
    {
      name: 'Meeting Room',
      to: '/meeting-room',
      exact: true,
    },
  ],
};

// export const documentNumberRoutes = {
//   name: 'Setting Nomor Dokumen',
//   to: '/document-number-setting',
//   exact: true,
//   icon: faCogs
// };

export const documentNumberRoutes = {
  name: 'Nomor Dokumen',
  to: '/document-number',
  exact: true,
  icon: faFileWord,
  children: [
    {
      name: 'Nomor Dokumen',
      to: '/document-number',
      exact: true,
    },
    {
      name: 'Setting Nomor Dokumen',
      to: '/document-number-setting',
      exact: true,
    },
    {
      name: 'Laporan Nomor Dokumen',
      to: '/document-number-report',
      exact: true,
    },
  ],
};

export const screenSaverRoutes = {
  name: 'Screensaver',
  to: '/screen-saver',
  exact: true,
  icon: faImages,
};

export const dispositionRoutes = {
  name: 'Disposisi',
  to: '/disposition',
  exact: true,
  icon: faFile,
  children: [
    {
      name: 'Structure Organization',
      to: '/structure/organization',
      exact: true,
    },
    {
      name: 'Action',
      to: '/disposition/action',
      exact: true,
    },
  ],
};

export const tripRoutes = {
  name: 'Trip',
  to: '/trip',
  exact: true,
  icon: faCar,
};

export default [
  homeRoutes,
  usersRoutes,
  documentRoutes,
  agendasRoutes,
  // vehiclesRoutes,
  reportsRoutes,
  umkmsRoutes,
  activitiesRoutes,
  meetingsRoutes,
  documentNumberRoutes,
  screenSaverRoutes,
  dispositionRoutes,
  tripRoutes,
];
