import { takeLatest, all } from 'redux-saga/effects';

import {
  LOGIN,
  USERS,
  ADD_USER,
  UPDATE_USER,
  DOCUMENTS,
  ADD_DOCUMENT,
  AGENDAS,
  ADD_AGENDA,
  UPDATE_AGENDA,
  VEHICLES,
  CARS,
  DRIVERS,
  ADD_TRIP,
  UPDATE_TRIP,
  DELETE_TRIP,
  UMKM,
  ADD_UMKM,
  UPDATE_UMKM,
  ACTIVITIES,
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  MEETING,
  ADD_MEETING,
  UPDATE_MEETING,
  MEETING_ROOM,
  ADD_MEETING_ROOM,
  UPDATE_MEETING_ROOM,
  DELETE_DOCUMENT,
  DOCUMENT_CONFIG,
  ADD_DOCUMENT_CONFIG,
  UPDATE_DOCUMENT_CONFIG,
  DOCUMENT_NUMBER,
  ADD_DOCUMENT_NUMBER,
  UPDATE_DOCUMENT_NUMBER,
  SCREEN_SAVER,
  ADD_SCREEN_SAVER,
  UPDATE_SCREEN_SAVER,
  SCREEN_SAVER_VIDEO,
  ADD_SCREEN_SAVER_VIDEO,
  DELETE_SCREEN_SAVER_VIDEO,
  DELETE_USER,
  DELETE_AGENDA,
  DELETE_UMKM,
  DELETE_ACTIVITY,
  DELETE_MEETING,
  DELETE_DOCUMENT_NUMBER,
  DISPOSITION_HEAD,
  ADD_DISPOSITION_HEAD,
  DELETE_DISPOSITION_HEAD,
  DISPOSITION_UNIT,
  ADD_DISPOSITION_UNIT,
  DELETE_DISPOSITION_UNIT,
  DISPOSITION_ACTION,
  ADD_DISPOSITION_ACTION,
  DELETE_DISPOSITION_ACTION,
  TRIP_REQUEST,
  UPDATE_TRIP_REQUEST,
} from '../../constants/ActionTypes';

import { doLogin, fetchUsers, createUser, updateUser, deleteUser } from './user';
import {
  fetchDocuments,
  createDocument,
  deleteDocument,
  fetchDocumentConfigs,
  createDocumentConfig,
  updateDocumentConfig,
  fetchDocumentNumbers,
  createDocumentNumber,
  updateDocumentNumber,
  deleteDocumentNumber,
} from './document';
import { fetchAgendas, createAgenda, updateAgenda, deleteAgenda } from './agenda';
import { fetchVehicles, fetchCars, fetchDrivers, createTrip, updateTrip, deleteTrip } from './vehicle';
import { fetchUMKMs, createUMKM, updateUMKM, deleteUMKM } from './umkm';
import { fetchActivities, createActivity, updateActivity, deleteActivity } from './activity';
import {
  fetchMeetings,
  createMeeting,
  updateMeeting,
  fetchMeetingRooms,
  createMeetingRoom,
  updateMeetingRoom,
  deleteMeeting,
} from './meeting';
import { fetchScreensaver, createScreensaver, updateScreensaver } from './screensaver';
import { fetchScreensaverVideo, createScreensaverVideo, deleteScreensaverVideo } from './screensavervideo';
import { fetchDispositionHead, createDispositionHead, deleteDispositionHead } from './dispositionhead';
import { fetchDispositionUnit, createDispositionUnit, deleteDispositionUnit } from './dispositionunit';
import { fetchDispositionAction, createDispositionAction, deleteDispositionAction } from './dispositionaction';
import { fetchTripRequest, updateTripRequest } from './triprequest';

function* loginWatcher() {
  yield takeLatest(LOGIN, doLogin.bind(this));
}

function* getUsersWatcher() {
  yield takeLatest(USERS, fetchUsers.bind(this));
}

function* addUserWatcher() {
  yield takeLatest(ADD_USER, createUser.bind(this));
}

function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER, updateUser.bind(this));
}

function* fetchDocumentWatcher() {
  yield takeLatest(DOCUMENTS, fetchDocuments.bind(this));
}

function* updateDocumentWatcher() {
  yield takeLatest(ADD_DOCUMENT, createDocument.bind(this));
}

function* updateDeleteWatcher() {
  yield takeLatest(DELETE_DOCUMENT, deleteDocument.bind(this));
}

function* getAgendasWatcher() {
  yield takeLatest(AGENDAS, fetchAgendas.bind(this));
}

function* addAgendaWatcher() {
  yield takeLatest(ADD_AGENDA, createAgenda.bind(this));
}

function* updateAgendaWatcher() {
  yield takeLatest(UPDATE_AGENDA, updateAgenda.bind(this));
}

function* fetchVehiclesWatcher() {
  yield takeLatest(VEHICLES, fetchVehicles.bind(this));
}

function* fetchCarsWatcher() {
  yield takeLatest(CARS, fetchCars.bind(this));
}

function* fetchDriversWatcher() {
  yield takeLatest(DRIVERS, fetchDrivers.bind(this));
}

function* addTripWatcher() {
  yield takeLatest(ADD_TRIP, createTrip.bind(this));
}

function* updateTripWatcher() {
  yield takeLatest(UPDATE_TRIP, updateTrip.bind(this));
}

function* deleteTripWatcher() {
  yield takeLatest(DELETE_TRIP, deleteTrip.bind(this));
}

function* fetchUMKMsWatcher() {
  yield takeLatest(UMKM, fetchUMKMs.bind(this));
}

function* addUMKMWatcher() {
  yield takeLatest(ADD_UMKM, createUMKM.bind(this));
}

function* updateUMKMWatcher() {
  yield takeLatest(UPDATE_UMKM, updateUMKM.bind(this));
}

function* fetchActivitiesWatcher() {
  yield takeLatest(ACTIVITIES, fetchActivities.bind(this));
}

function* addActivityWatcher() {
  yield takeLatest(ADD_ACTIVITY, createActivity.bind(this));
}

function* updateActivityWatcher() {
  yield takeLatest(UPDATE_ACTIVITY, updateActivity.bind(this));
}

function* fetchMeetingsWatcher() {
  yield takeLatest(MEETING, fetchMeetings.bind(this));
}

function* addMeetingWatcher() {
  yield takeLatest(ADD_MEETING, createMeeting.bind(this));
}

function* updateMeetingWatcher() {
  yield takeLatest(UPDATE_MEETING, updateMeeting.bind(this));
}

function* fetchMeetingRoomsWatcher() {
  yield takeLatest(MEETING_ROOM, fetchMeetingRooms.bind(this));
}

function* addMeetingRoomWatcher() {
  yield takeLatest(ADD_MEETING_ROOM, createMeetingRoom.bind(this));
}

function* updateMeetingRoomWatcher() {
  yield takeLatest(UPDATE_MEETING_ROOM, updateMeetingRoom.bind(this));
}

function* fetchDocumentConfigsWatcher() {
  yield takeLatest(DOCUMENT_CONFIG, fetchDocumentConfigs.bind(this));
}

function* addDocumentConfigWatcher() {
  yield takeLatest(ADD_DOCUMENT_CONFIG, createDocumentConfig.bind(this));
}

function* updateDocumentConfigWatcher() {
  yield takeLatest(UPDATE_DOCUMENT_CONFIG, updateDocumentConfig.bind(this));
}

function* fetchDocumentNumbersWatcher() {
  yield takeLatest(DOCUMENT_NUMBER, fetchDocumentNumbers.bind(this));
}

function* addDocumentNumberWatcher() {
  yield takeLatest(ADD_DOCUMENT_NUMBER, createDocumentNumber.bind(this));
}

function* updateDocumentNumberWatcher() {
  yield takeLatest(UPDATE_DOCUMENT_NUMBER, updateDocumentNumber.bind(this));
}

function* fetchScreensaverWatcher() {
  yield takeLatest(SCREEN_SAVER, fetchScreensaver.bind(this));
}

function* addScreensaverWatcher() {
  yield takeLatest(ADD_SCREEN_SAVER, createScreensaver.bind(this));
}

function* updateScreensaverWatcher() {
  yield takeLatest(UPDATE_SCREEN_SAVER, updateScreensaver.bind(this));
}

function* fetchScreensaverVideoWatcher() {
  yield takeLatest(SCREEN_SAVER_VIDEO, fetchScreensaverVideo.bind(this));
}

function* addScreensaverVideoWatcher() {
  yield takeLatest(ADD_SCREEN_SAVER_VIDEO, createScreensaverVideo.bind(this));
}

function* updateScreensaverVideoWatcher() {
  yield takeLatest(DELETE_SCREEN_SAVER_VIDEO, deleteScreensaverVideo.bind(this));
}

function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER, deleteUser.bind(this));
}

function* deleteAgendaWatcher() {
  yield takeLatest(DELETE_AGENDA, deleteAgenda.bind(this));
}

function* deleteUmkmWatcher() {
  yield takeLatest(DELETE_UMKM, deleteUMKM.bind(this));
}

function* deleteActivityWatcher() {
  yield takeLatest(DELETE_ACTIVITY, deleteActivity.bind(this));
}

function* deleteMeetingWatcher() {
  yield takeLatest(DELETE_MEETING, deleteMeeting.bind(this));
}

function* deleteDocumentNumberWatcher() {
  yield takeLatest(DELETE_DOCUMENT_NUMBER, deleteDocumentNumber.bind(this));
}

function* getDispositionHeadWatcher() {
  yield takeLatest(DISPOSITION_HEAD, fetchDispositionHead.bind(this));
}

function* addDispositionHeadWatcher() {
  yield takeLatest(ADD_DISPOSITION_HEAD, createDispositionHead.bind(this));
}

function* deleteDispositionHeadWatcher() {
  yield takeLatest(DELETE_DISPOSITION_HEAD, deleteDispositionHead.bind(this));
}

function* getDispositionUnitWatcher() {
  yield takeLatest(DISPOSITION_UNIT, fetchDispositionUnit.bind(this));
}

function* addDispositionUnitWatcher() {
  yield takeLatest(ADD_DISPOSITION_UNIT, createDispositionUnit.bind(this));
}

function* deleteDispositionUnitWatcher() {
  yield takeLatest(DELETE_DISPOSITION_UNIT, deleteDispositionUnit.bind(this));
}

function* getDispositionActionWatcher() {
  yield takeLatest(DISPOSITION_ACTION, fetchDispositionAction.bind(this));
}

function* addDispositionActionWatcher() {
  yield takeLatest(ADD_DISPOSITION_ACTION, createDispositionAction.bind(this));
}

function* deleteDispositionActionWatcher() {
  yield takeLatest(DELETE_DISPOSITION_ACTION, deleteDispositionAction.bind(this));
}

function* geTripRequestWatcher() {
  yield takeLatest(TRIP_REQUEST, fetchTripRequest.bind(this));
}

function* updateTripRequestWatcher() {
  yield takeLatest(UPDATE_TRIP_REQUEST, updateTripRequest.bind(this));
}

export default function* rootSaga() {
  yield all([
    loginWatcher(),
    getUsersWatcher(),
    addUserWatcher(),
    updateUserWatcher(),
    fetchDocumentWatcher(),
    updateDocumentWatcher(),
    updateDeleteWatcher(),
    getAgendasWatcher(),
    addAgendaWatcher(),
    updateAgendaWatcher(),
    fetchVehiclesWatcher(),
    fetchCarsWatcher(),
    fetchDriversWatcher(),
    addTripWatcher(),
    updateTripWatcher(),
    deleteTripWatcher(),
    fetchUMKMsWatcher(),
    addUMKMWatcher(),
    updateUMKMWatcher(),
    fetchActivitiesWatcher(),
    addActivityWatcher(),
    updateActivityWatcher(),
    fetchMeetingsWatcher(),
    addMeetingWatcher(),
    updateMeetingWatcher(),
    fetchMeetingRoomsWatcher(),
    addMeetingRoomWatcher(),
    updateMeetingRoomWatcher(),
    fetchDocumentConfigsWatcher(),
    addDocumentConfigWatcher(),
    updateDocumentConfigWatcher(),
    fetchDocumentNumbersWatcher(),
    addDocumentNumberWatcher(),
    updateDocumentNumberWatcher(),
    fetchScreensaverWatcher(),
    addScreensaverWatcher(),
    updateScreensaverWatcher(),
    fetchScreensaverVideoWatcher(),
    addScreensaverVideoWatcher(),
    updateScreensaverVideoWatcher(),
    deleteUserWatcher(),
    deleteAgendaWatcher(),
    deleteUmkmWatcher(),
    deleteActivityWatcher(),
    deleteMeetingWatcher(),
    deleteDocumentNumberWatcher(),
    getDispositionHeadWatcher(),
    addDispositionHeadWatcher(),
    deleteDispositionHeadWatcher(),
    getDispositionUnitWatcher(),
    addDispositionUnitWatcher(),
    deleteDispositionUnitWatcher(),
    getDispositionActionWatcher(),
    addDispositionActionWatcher(),
    deleteDispositionActionWatcher(),
    geTripRequestWatcher(),
    updateTripRequestWatcher(),
  ]);
}
