import {
  AGENDAS,
  AGENDAS_SUCCESS,
  AGENDAS_ERROR,
  ADD_AGENDA,
  ADD_AGENDA_SUCCESS,
  ADD_AGENDA_ERROR,
  UPDATE_AGENDA,
  UPDATE_AGENDA_SUCCESS,
  UPDATE_AGENDA_ERROR,
  DELETE_AGENDA,
  DELETE_AGENDA_SUCCESS,
  DELETE_AGENDA_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    agendas: [],
    agendasLoading: false,
    addAgendaLoading: false,
    updateAgendaLoading: false,
    deleteAgendaLoading: false,
  },
  action
) => {
  switch (action.type) {
    case AGENDAS:
      return {
        ...state,
        agendasLoading: true,
      };
    case AGENDAS_SUCCESS:
      return {
        ...state,
        agendas: action.payload,
        agendasLoading: false,
      };
    case AGENDAS_ERROR:
      return {
        ...state,
        agendasLoading: false,
      };

    case ADD_AGENDA:
      return {
        ...state,
        addAgendaLoading: true,
      };
    case ADD_AGENDA_SUCCESS:
      return {
        ...state,
        addAgendaLoading: false,
      };
    case ADD_AGENDA_ERROR:
      return {
        ...state,
        addAgendaLoading: false,
      };

    case UPDATE_AGENDA:
      return {
        ...state,
        updateAgendaLoading: true,
      };
    case UPDATE_AGENDA_SUCCESS:
      return {
        ...state,
        updateAgendaLoading: false,
      };
    case UPDATE_AGENDA_ERROR:
      return {
        ...state,
        updateAgendaLoading: false,
      };

    case DELETE_AGENDA:
      return {
        ...state,
        deleteAgendaLoading: true,
      };
    case DELETE_AGENDA_SUCCESS:
      return {
        ...state,
        deleteAgendaLoading: false,
      };
    case DELETE_AGENDA_ERROR:
      return {
        ...state,
        deleteAgendaLoading: false,
      };
    default:
      return state;
  }
};
