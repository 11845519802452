import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
// import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
// import { ViewState, GroupingState, IntegratedGrouping } from '@devexpress/dx-react-scheduler';
// import {
//   Scheduler,
//   DayView,
//   WeekView,
//   Appointments,
//   Toolbar,
//   ViewSwitcher,
//   DateNavigator,
//   TodayButton,
//   AppointmentTooltip,
//   AppointmentForm,
//   Resources,
//   GroupingPanel
// } from '@devexpress/dx-react-scheduler-material-ui';
// import { Paper, LinearProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, TimePicker } from 'antd';
import StackGrid from 'react-stack-grid';

import { getMeetings, addMeeting, updateMeeting, getMeetingRooms, deleteMeeting } from '../../redux/actions';
// import { Content, DayScaleCell, TimeTableCell, useWindowDimensions } from './MeetingHelper';

const generateColor = (ranges) => {
  if (!ranges) {
    ranges = [
      [150, 256],
      [0, 190],
      [0, 30],
    ];
  }
  var g = function () {
    //select random range and remove
    var range = ranges.splice(Math.floor(Math.random() * ranges.length), 1)[0];
    //pick a random number from within the range
    return Math.floor(Math.random() * (range[1] - range[0])) + range[0];
  };
  return 'rgb(' + g() + ',' + g() + ',' + g() + ')';
};

const Meetings = ({ meeting, getMeetings, addMeeting, updateMeeting, getMeetingRooms, deleteMeeting }) => {
  const [token, setToken] = useState(null);
  const { meetings, addMeetingLoading, updateMeetingLoading, meetingRooms } = meeting;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    meetingRoom: '',
    agenda: '',
    startTime: new Date(),
    endTime: new Date(),
    description: '',
    internal: true,
    paxDetail: '',
  });
  const [filterMeeting, setFilterMeeting] = useState({
    isRange: false,
    from: new Date(),
    to: new Date(),
    internal: true,
  });
  const { register, handleSubmit, errors } = useForm();
  // const [scheduleData, setScheduleData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  // const { height } = useWindowDimensions();
  // const currentDate = moment().format('YYYY-MM-DD');
  // const data = {
  //   resources: [
  //     {
  //       fieldName: 'roomID',
  //       title: 'Ruang Meeting',
  //       instances: roomData
  //     }
  //   ],
  //   grouping: [
  //     {
  //       resourceName: 'roomID'
  //     }
  //   ]
  // };

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        if (filterMeeting.isRange) {
          getMeetings({
            token: JSON.parse(data).data.token,
            from: filterMeeting.from.toISOString(),
            to: filterMeeting.to.toISOString(),
            internal: filterMeeting.internal,
          });
        } else {
          getMeetings({
            token: JSON.parse(data).data.token,
            from: filterMeeting.from.toISOString(),
            internal: filterMeeting.internal,
          });
        }
        getMeetingRooms({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [
    filterMeeting.from,
    filterMeeting.internal,
    filterMeeting.isRange,
    filterMeeting.to,
    getMeetingRooms,
    getMeetings,
  ]);

  useEffect(() => {
    var rID = [];
    var r = [];
    meetings.forEach((el) => {
      if (!rID.includes(el.meetingRoom._id)) {
        rID.push(el.meetingRoom._id);
        r.push({
          ...el.meetingRoom,
          color: generateColor(),
        });
      }
    });
    setRoomData(r);
  }, [meetings]);

  // useEffect(() => {
  //   var s = [];
  //   var rID = [];
  //   var r = [];
  //   meetings.forEach(el => {
  //     if (!rID.includes(el.meetingRoom._id)) {
  //       rID.push(el.meetingRoom._id);
  //       r.push({
  //         id: el.meetingRoom._id,
  //         text: `${el.meetingRoom.name} (${el.meetingRoom.location})`,
  //         color: generateColor()
  //       });
  //     }
  //     s.push({
  //       id: el._id,
  //       startDate: el.startTime,
  //       endDate: el.endTime,
  //       title: el.agenda,
  //       description: el.description,
  //       roomID: el.meetingRoom._id,
  //       roomCapacity: el.meetingRoom.capacity,
  //       roomFacility: el.meetingRoom.facility,
  //       roomLocation: el.meetingRoom.location,
  //       creator: el.creator.name,
  //       raw: el
  //     });
  //   });
  //   setScheduleData(s);
  //   setRoomData(r);
  // }, [meetings]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      meetingRoom: '',
      agenda: '',
      startTime: new Date(),
      endTime: new Date(),
      description: '',
      internal: true,
      paxDetail: '',
    });
  };

  // const setFilledForm = v => {
  //   window.scrollTo(0, 0);
  //   setShowForm(true);
  //   setMode('Update');
  //   setForm({
  //     id: v.raw.id,
  //     meetingRoom: v.raw.meetingRoom.id,
  //     agenda: v.raw.agenda,
  //     startTime: v.raw.startTime,
  //     endTime: v.raw.endTime,
  //     description: v.raw.description
  //   });
  // };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v.id,
      meetingRoom: v.meetingRoom.id,
      agenda: v.agenda,
      startTime: v.startTime,
      endTime: v.endTime,
      description: v.description,
      internal: v.internal,
      paxDetail: v.paxDetail,
    });
  };

  const onSubmit = (data) => {
    if (mode === 'Tambah') {
      return addMeeting({
        ...form,
        ...data,
        resetForm,
        getMeetings,
        token,
      });
    }
    updateMeeting({
      ...form,
      ...data,
      resetForm,
      getMeetings,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah Meeting
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {!showForm && (
          <>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={filterMeeting.isRange}
                  onChange={(e) =>
                    setFilterMeeting({
                      ...filterMeeting,
                      isRange: e.target.checked,
                    })
                  }
                />{' '}
                Range
              </Label>
            </FormGroup>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormGroup>
                <Label>
                  Mulai<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <br />
                <div>
                  <DatePicker
                    allowClear={false}
                    placeholder="Pilih Tanggal"
                    onChange={(v, s) => {
                      setFilterMeeting({
                        ...filterMeeting,
                        from: moment(v).toDate(),
                      });
                    }}
                    value={moment(filterMeeting.from)}
                  />
                </div>
              </FormGroup>
              {filterMeeting.isRange && (
                <FormGroup style={{ marginLeft: '16px' }}>
                  <Label>
                    Sampai<span style={{ color: 'red' }}>*</span>{' '}
                  </Label>
                  <br />
                  <div>
                    <DatePicker
                      allowClear={false}
                      placeholder="Pilih Tanggal"
                      onChange={(v, s) => {
                        setFilterMeeting({
                          ...filterMeeting,
                          to: moment(v).toDate(),
                        });
                      }}
                      value={moment(filterMeeting.to)}
                    />
                  </div>
                </FormGroup>
              )}
            </div>
            <FormGroup
              tag="fieldset"
              onChange={(e) =>
                setFilterMeeting({ ...filterMeeting, internal: e.target.value === 'true' ? true : false })
              }
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormGroup check>
                <Label check>
                  <Input type="radio" checked={filterMeeting.internal} name="radio1" value={true} /> Internal
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" checked={!filterMeeting.internal} name="radio1" value={false} /> External
                </Label>
              </FormGroup>
            </FormGroup>
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="meetingRoom">
                Ruang Meeting<span style={{ color: 'red' }}>*</span>{' '}
                {errors.meetingRoom && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.meetingRoom}
                type="select"
                name="meetingRoom"
                id="meetingRoom"
                innerRef={register({ required: true })}
              >
                <option value="">Pilih Ruang Meeting</option>
                {meetingRooms.map((el, i) => (
                  <option key={i} value={el.id}>
                    {el.name} ({el.location})
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="agenda">
                Agenda<span style={{ color: 'red' }}>*</span>{' '}
                {errors.agenda && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.agenda}
                type="text"
                name="agenda"
                id="agenda"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="paxDetail">
                Pax<span style={{ color: 'red' }}>*</span>{' '}
                {errors.paxDetail && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.paxDetail}
                type="text"
                name="paxDetail"
                id="paxDetail"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Deskripsi</Label>
              <Input
                defaultValue={form.description}
                type="textarea"
                name="description"
                id="description"
                innerRef={register}
              />
            </FormGroup>
            <Label>Tanggal</Label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormGroup>
                <Label>
                  Mulai<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <br />
                <div>
                  <DatePicker
                    allowClear={false}
                    placeholder="Pilih Tanggal"
                    onChange={(v, s) => {
                      setForm({
                        ...form,
                        startTime: moment(v).toDate(),
                      });
                    }}
                    value={moment(form.startTime)}
                  />
                </div>
                <div>
                  <TimePicker
                    allowClear={false}
                    placeholder="Pilih Waktu"
                    onChange={(v, s) => {
                      setForm({
                        ...form,
                        startTime: moment(v).toDate(),
                      });
                    }}
                    minuteStep={30}
                    secondStep={60}
                    value={moment(form.startTime)}
                  />
                </div>
                {/* <DateTimePicker
                  ampm={false}
                  format="D/MM/YYYY, HH:mm"
                  value={form.startTime}
                  onChange={date => {
                    setForm({
                      ...form,
                      startTime: new Date(date.toISOString())
                    });
                  }}
                  animateYearScrolling
                  inputVariant="outlined"
                  autoOk
                /> */}
              </FormGroup>
              <FormGroup style={{ marginLeft: '16px' }}>
                <Label>
                  Sampai<span style={{ color: 'red' }}>*</span>{' '}
                </Label>
                <br />
                <div>
                  <DatePicker
                    allowClear={false}
                    placeholder="Pilih Tanggal"
                    onChange={(v, s) => {
                      setForm({
                        ...form,
                        endTime: moment(v).toDate(),
                      });
                    }}
                    value={moment(form.endTime)}
                  />
                </div>
                <div>
                  <TimePicker
                    allowClear={false}
                    placeholder="Pilih Waktu"
                    onChange={(v, s) => {
                      setForm({
                        ...form,
                        endTime: moment(v).toDate(),
                      });
                    }}
                    minuteStep={30}
                    secondStep={60}
                    value={moment(form.endTime)}
                  />
                </div>
                {/* <DateTimePicker
                  ampm={false}
                  format="D/MM/YYYY, HH:mm"
                  value={form.endTime}
                  onChange={date => {
                    setForm({
                      ...form,
                      endTime: new Date(date.toISOString())
                    });
                  }}
                  animateYearScrolling
                  inputVariant="outlined"
                  autoOk
                /> */}
              </FormGroup>
            </div>
            <FormGroup
              tag="fieldset"
              onChange={(e) => setForm({ ...form, internal: e.target.value === 'true' ? true : false })}
            >
              <FormGroup check>
                <Label check>
                  <Input type="radio" checked={form.internal} name="radio1" value={true} /> Internal
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" checked={!form.internal} name="radio1" value={false} /> External
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={addMeetingLoading || updateMeetingLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        {/* <Paper>
          {roomData.length > 0 && scheduleData.length > 0 ? (
            <Scheduler data={scheduleData} height={height - 48}>
              <ViewState defaultCurrentDate={currentDate} defaultCurrentViewName="Week" />
              <GroupingState grouping={data.grouping} />
              <DayView startDayHour={6} timeTableCellComponent={TimeTableCell} dayScaleCellComponent={DayScaleCell} />
              <WeekView startDayHour={6} timeTableCellComponent={TimeTableCell} dayScaleCellComponent={DayScaleCell} />
              <DayView startDayHour={6} timeTableCellComponent={TimeTableCell} dayScaleCellComponent={DayScaleCell} />
              <WeekView startDayHour={6} timeTableCellComponent={TimeTableCell} dayScaleCellComponent={DayScaleCell} />
              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <ViewSwitcher />
              <Appointments />
              <AppointmentTooltip
                contentComponent={({ ...props }) => <Content {...props} setFilledForm={setFilledForm} />}
              />
              <AppointmentForm readOnly />
              <Resources data={data.resources} mainResourceName="roomID" />
              <IntegratedGrouping />
              <GroupingPanel />
            </Scheduler>
          ) : (
            <LinearProgress />
          )}
        </Paper> */}
        {/* <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Ruang Meeting</th>
                <th>Agenda</th>
                <th>Mulai</th>
                <th>Akhir</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {meetings.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>
                    {el.meetingRoom?.name} ({el.meetingRoom?.location})
                  </td>
                  <td>{el.agenda}</td>
                  <td>{moment(el.startTime).format('D MMMM YYYY, HH:mm')}</td>
                  <td>{moment(el.endTime).format('D MMMM YYYY, HH:mm')}</td>
                  <td>
                    <FontAwesomeIcon
                      size="sm"
                      style={{ cursor: 'pointer' }}
                      icon={faPencilAlt}
                      transform="grow-12 right-6"
                      className="text-info"
                      onClick={setFilledForm.bind(this, el)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div> */}

        <StackGrid columnWidth={roomData.length === 0 ? '100%' : '50%'} style={{ padding: '0px 8px' }}>
          {roomData.length !== 0 ? (
            roomData.map((el) => (
              <div
                key={el._id}
                style={{ borderRadius: '8px', backgroundColor: el.color, boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)' }}
              >
                <div style={{ width: '100%', height: '200px', position: 'relative' }}>
                  <img
                    style={{
                      width: '100%',
                      height: '200px',
                      margin: '0px auto',
                      objectFit: 'cover',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                    }}
                    alt="cover"
                    src={
                      el.cover?.length >= 1
                        ? el.cover[0].url
                        : 'https://ik.imagekit.io/zmtoue4art/1602238492_1Mpol5KzT.png'
                    }
                  />
                  <div
                    style={{
                      position: 'absolute',
                      backgroundColor: 'black',
                      width: '100%',
                      height: '200px',
                      bottom: '0px',
                      left: '0px',
                      borderTopLeftRadius: '8px',
                      borderTopRightRadius: '8px',
                      opacity: '0.5',
                    }}
                  />
                  <div style={{ maxWidth: '50%', position: 'absolute', bottom: '8px', left: '16px' }}>
                    <p style={{ fontSize: '24px', color: 'white', margin: '0px', fontWeight: 'bold' }}>
                      {el.name} ({el.location})
                    </p>
                  </div>
                  <div style={{ maxWidth: '50%', position: 'absolute', bottom: '8px', right: '16px' }}>
                    <p style={{ fontSize: '16px', color: 'white', margin: '0px' }}>Kapasitas: {el.capacity}</p>
                    <p style={{ fontSize: '16px', color: 'white', margin: '0px' }}>Fasilitas: {el.facility}</p>
                  </div>
                </div>
                <div>
                  {meetings
                    .filter((mf) => mf.meetingRoom._id === el._id)
                    .map((meeting) => (
                      <div
                        key={meeting._id}
                        style={{
                          backgroundColor: 'white',
                          borderBottom: '1px solid #ccc',
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div style={{ width: '32px', backgroundColor: el.color }} />
                        <div style={{ width: '100%' }}>
                          <div
                            style={{
                              padding: '16px',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'flex-end',
                            }}
                          >
                            <div>
                              <h2 style={{ margin: '0px' }}>{meeting.agenda}</h2>
                              <p style={{ margin: '0px' }}>
                                <FontAwesomeIcon
                                  size="1x"
                                  icon={faClock}
                                  style={{ cursor: 'pointer', marginRight: '8px' }}
                                />
                                {moment(meeting.startTime).format('HH:mm')} - {moment(meeting.endTime).format('HH:mm')}
                              </p>
                            </div>
                            <div>
                              <p style={{ margin: '0px', color: 'grey' }}>Pax: {meeting.paxDetail}</p>
                            </div>
                            <div>
                              <p style={{ margin: '0px', color: 'grey' }}>Created by: {meeting.creator.name}</p>
                            </div>
                          </div>
                          <div style={{ padding: '16px' }}>
                            <Button onClick={() => setFilledForm(meeting)} color="primary">
                              Update
                            </Button>
                            <Button
                              onClick={() => deleteMeeting({ id: meeting._id, token, getMeetings })}
                              color="danger"
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <p>Tidak ada meeting</p>
            </div>
          )}
        </StackGrid>
      </CardBody>
    </Card>
  );
};

Meetings.propTypes = {
  meeting: PropTypes.object,
  getMeetings: PropTypes.func,
  addMeeting: PropTypes.func,
  updateMeeting: PropTypes.func,
  getMeetingRooms: PropTypes.func,
  deleteMeeting: PropTypes.func,
};

const mapStateToProps = (state) => ({
  meeting: state.meeting,
});

const mapActionToProps = {
  getMeetings,
  addMeeting,
  updateMeeting,
  getMeetingRooms,
  deleteMeeting,
};

export default connect(mapStateToProps, mapActionToProps)(Meetings);
