import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  USERS,
  USERS_SUCCESS,
  USERS_ERROR,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    users: [],
    usersLoading: false,
    loginUserLoading: false,
    addUserLoading: false,
    updateUserLoading: false,
    deleteUserLoading: false,
  },
  action
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loginUserLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginUserLoading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginUserLoading: false,
      };

    case USERS:
      return {
        ...state,
        usersLoading: true,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersLoading: false,
      };
    case USERS_ERROR:
      return {
        ...state,
        usersLoading: false,
      };

    case ADD_USER:
      return {
        ...state,
        addUserLoading: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        addUserLoading: false,
      };
    case ADD_USER_ERROR:
      return {
        ...state,
        addUserLoading: false,
      };

    case UPDATE_USER:
      return {
        ...state,
        updateUserLoading: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserLoading: false,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserLoading: false,
      };

    case DELETE_USER:
      return {
        ...state,
        deleteUserLoading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserLoading: false,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserLoading: false,
      };
    default:
      return state;
  }
};
