import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import { getDispositionAction, addDispositionAction, deleteDispositionAction } from '../../../redux/actions';

const Action = ({ dispositionaction, getDispositionAction, addDispositionAction, deleteDispositionAction }) => {
  const { register, handleSubmit, errors } = useForm();
  const [token, setToken] = useState(null);
  const { disposition, addDispositionLoading } = dispositionaction;
  const [showForm, setShowForm] = useState(false);
  const [form, setForm] = useState({
    name: '',
    order: -1,
  });

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getDispositionAction({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDispositionAction]);

  const resetForm = () => {
    setShowForm(false);
    setForm({
      name: '',
      order: -1,
    });
  };

  const reload = () => {
    getDispositionAction({ token });
  };

  const onSubmit = (data) => {
    addDispositionAction({
      ...form,
      ...data,
      resetForm,
      reload,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        <>
          <Button
            onClick={() => {
              setShowForm(!showForm);
            }}
            color="primary"
          >
            Tambah
          </Button>
          <div style={{ height: '16px' }} />
        </>
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="name">
                Nama<span style={{ color: 'red' }}>*</span>{' '}
                {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.name}
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="order">
                Urutan<span style={{ color: 'red' }}>*</span>{' '}
                {errors.order && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.order}
                type="text"
                name="order"
                id="order"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Button disabled={addDispositionLoading} type="submit" color="primary" block className="mt-3">
                Tambah
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>Urutan</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {disposition
                .sort((i, j) => {
                  return i.order > j.order;
                })
                .map((el, i) => (
                  <tr key={el.id}>
                    <th>{i + 1}</th>
                    <td>{el.name}</td>
                    <td>{el.order}</td>
                    <td>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '16px' }} />
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faTrash}
                          transform="grow-12 right-6"
                          className="text-danger"
                          onClick={() => deleteDispositionAction({ id: el._id, reload, token })}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Action.propTypes = {
  dispositionaction: PropTypes.object,
  getDispositionAction: PropTypes.func,
  addDispositionAction: PropTypes.func,
  deleteDispositionAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  dispositionaction: state.dispositionaction,
});

const mapActionToProps = {
  getDispositionAction,
  addDispositionAction,
  deleteDispositionAction,
};

export default connect(mapStateToProps, mapActionToProps)(Action);
