import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import { getUsers, addUser, updateUser, deleteUser, getDispositionHead } from '../../redux/actions';

const occupations = [
  'Kepala Perwakilan',
  'Kepala Tim',
  'Kepala Fungsi',
  'Kepala Unit',
  'Pegawai',
  'Calon Pegawai',
  'Ketua',
  'Driver',
  'Agendaris',
  'General Affair',
  'Messenger',
  'CS',
  'Swakelola',
  'ME',
  'Teknisi MSUK',
  'Teknisi MRUK',
  'Teknisi ESS',
  'Pimpinan Lokasi PAM',
  'Pengawas PAM',
  'Admin',
  'Safety',
  'Operator ESS',
  'PAM'
];

const Users = ({ user, getUsers, addUser, updateUser, deleteUser, getDispositionHead, dispositionhead }) => {
  const [token, setToken] = useState(null);
  const { users, addUserLoading, updateUserLoading } = user;
  const { disposition } = dispositionhead;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    name: '',
    nip: '',
    password: '',
    position1: '',
    position2: '',
    occupation1: '',
    occupation2: '',
    isActive: true
  });
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getUsers({ token: JSON.parse(data).data.token });
        getDispositionHead({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDispositionHead, getUsers]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      name: '',
      nip: '',
      password: '',
      position1: '',
      position2: '',
      occupation1: '',
      occupation2: '',
      isActive: true
    });
  };

  const setFilledForm = v => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v._id,
      name: v.name,
      nip: v.nip,
      password: '',
      position1: v.position1 ? v.position1._id : null,
      position2: v.position2 ? v.position2._id : null,
      occupation1: v.occupation1,
      occupation2: v.occupation2,
      isActive: v.isActive
    });
  };

  const onSubmit = data => {
    if (mode === 'Tambah') {
      return addUser({
        ...form,
        ...data,
        resetForm,
        getUsers,
        token
      });
    }
    updateUser({
      ...form,
      ...data,
      resetForm,
      getUsers,
      token
    });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah User
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="name">
                Nama<span style={{ color: 'red' }}>*</span>{' '}
                {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.name}
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="nip">
                NIP<span style={{ color: 'red' }}>*</span>{' '}
                {errors.nip && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                disabled={mode === 'Update' ? true : false}
                defaultValue={form.nip}
                type="text"
                name="nip"
                id="nip"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">
                Password<span style={{ color: 'red' }}>*</span>{' '}
                {errors.password && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.password}
                type="password"
                name="password"
                id="password"
                innerRef={register({ required: mode === 'Create' })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="position1">Posisi 1</Label>
              <Input
                defaultValue={form.position1}
                type="select"
                name="position1"
                id="position1"
                innerRef={register({ required: false })}
              >
                <option value="">Pilih User Posisi 1</option>
                {disposition.map(el => (
                  <option key={el._id} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="occupation1">Jabatan 1</Label>
              <Input
                defaultValue={form.occupation1}
                type="select"
                name="occupation1"
                id="occupation1"
                innerRef={register({ required: false })}
              >
                <option value="">Pilih User Jabatan 1</option>
                {occupations.map(el => (
                  <option key={el._id} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="position2">Posisi 2</Label>
              <Input
                defaultValue={form.position2}
                type="select"
                name="position2"
                id="position2"
                innerRef={register({ required: false })}
              >
                <option value="">Pilih User Posisi 2</option>
                {disposition.map(el => (
                  <option key={el._id} value={el._id}>
                    {el.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="occupation2">Jabatan 2</Label>
              <Input
                defaultValue={form.occupation2}
                type="select"
                name="occupation2"
                id="occupation2"
                innerRef={register({ required: false })}
              >
                <option value="">Pilih User Jabatan 2</option>
                {occupations.map(el => (
                  <option key={el._id} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {mode === 'Update' && (
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={form.isActive}
                    onChange={e =>
                      setForm({
                        ...form,
                        isActive: e.target.checked
                      })
                    }
                  />{' '}
                  Aktif
                </Label>
              </FormGroup>
            )}
            <FormGroup>
              <Button
                disabled={addUserLoading || updateUserLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>NIP</th>
                <th>Nama</th>
                <th>Posisi 1</th>
                <th>Jabatan 1</th>
                <th>Posisi 2</th>
                <th>Jabatan 2</th>
                <th>Aktif</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((el, i) => (
                <tr key={el._id}>
                  <th>{i + 1}</th>
                  <td>{el.nip}</td>
                  <td>{el.name}</td>
                  <td>{el.position1 && el.position1.name}</td>
                  <td>{el.occupation1}</td>
                  <td>{el.position2 && el.position2.name}</td>
                  <td>{el.occupation2}</td>
                  <td>
                    {el.isActive ? (
                      <FontAwesomeIcon size="sm" icon={faCheck} transform="grow-12 right-6" className="text-success" />
                    ) : (
                      <FontAwesomeIcon size="sm" icon={faTimes} transform="grow-12 right-6" className="text-danger" />
                    )}
                  </td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {el.role !== 'Admin' && (
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faPencilAlt}
                          transform="grow-12 right-6"
                          className="text-info"
                          onClick={setFilledForm.bind(this, el)}
                        />
                      )}
                      <div style={{ width: '16px' }} />
                      {el.role !== 'Admin' && (
                        <FontAwesomeIcon
                          size="sm"
                          style={{ cursor: 'pointer' }}
                          icon={faTrash}
                          transform="grow-12 right-6"
                          className="text-danger"
                          onClick={() => deleteUser({ token, getUsers, id: el._id })}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Users.propTypes = {
  user: PropTypes.object,
  getUsers: PropTypes.func,
  addUser: PropTypes.func,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  getDispositionHead: PropTypes.func,
  dispositionhead: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.user,
  dispositionhead: state.dispositionhead
});

const mapActionToProps = {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
  getDispositionHead
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Users);
