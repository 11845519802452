import {
  SCREEN_SAVER_VIDEO,
  SCREEN_SAVER_VIDEO_SUCCESS,
  SCREEN_SAVER_VIDEO_ERROR,
  ADD_SCREEN_SAVER_VIDEO,
  ADD_SCREEN_SAVER_VIDEO_SUCCESS,
  ADD_SCREEN_SAVER_VIDEO_ERROR,
  DELETE_SCREEN_SAVER_VIDEO,
  DELETE_SCREEN_SAVER_VIDEO_SUCCESS,
  DELETE_SCREEN_SAVER_VIDEO_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    screensaversVideo: [],
    screensaverVideoLoading: false,
    addScreensaverVideoLoading: false,
    deleteScreensaverVideoLoading: false,
  },
  action
) => {
  switch (action.type) {
    case SCREEN_SAVER_VIDEO:
      return {
        ...state,
        screensaverVideoLoading: true,
      };
    case SCREEN_SAVER_VIDEO_SUCCESS:
      return {
        ...state,
        screensaversVideo: action.payload,
        screensaverVideoLoading: false,
      };
    case SCREEN_SAVER_VIDEO_ERROR:
      return {
        ...state,
        screensaverVideoLoading: false,
      };

    case ADD_SCREEN_SAVER_VIDEO:
      return {
        ...state,
        addScreensaverVideoLoading: true,
      };
    case ADD_SCREEN_SAVER_VIDEO_SUCCESS:
      return {
        ...state,
        addScreensaverVideoLoading: false,
      };
    case ADD_SCREEN_SAVER_VIDEO_ERROR:
      return {
        ...state,
        addScreensaverVideoLoading: false,
      };

    case DELETE_SCREEN_SAVER_VIDEO:
      return {
        ...state,
        deleteScreensaverVideoLoading: true,
      };
    case DELETE_SCREEN_SAVER_VIDEO_SUCCESS:
      return {
        ...state,
        deleteScreensaverVideoLoading: false,
      };
    case DELETE_SCREEN_SAVER_VIDEO_ERROR:
      return {
        ...state,
        deleteScreensaverVideoLoading: false,
      };
    default:
      return state;
  }
};
