import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input, Media, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimes, faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import mimeType from 'mime-types';
import { toast } from 'react-toastify';

import { getMeetingRooms, addMeetingRoom, updateMeetingRoom } from '../../redux/actions';
import FalconDropzone from '../../components/common/FalconDropzone';
import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import { getHost } from '../../constants';

const DropzoneExample = ({ files, setFiles, field, multiple }) => {
  return (
    <Media className="flex-center pb-3 d-block d-md-flex text-center mb-2">
      <Media body className="ml">
        <FalconDropzone
          files={files[field]}
          onChange={(f) => {
            if (field === 'photos') {
              setFiles({
                ...files,
                [field]: [...files[field], ...f],
              });
            } else if (field === 'cover') {
              setFiles({
                ...files,
                [field]: f,
              });
            }
          }}
          multiple={multiple}
          accept="image/*"
          placeholder={
            <Fragment>
              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                <img src={cloudUpload} alt="" width={25} className="mr-2" />
                <Media>
                  <p className="fs-0 mb-0 text-700">Upload</p>
                </Media>
              </Media>
            </Fragment>
          }
        />
      </Media>
    </Media>
  );
};

DropzoneExample.propTypes = {
  files: PropTypes.object,
  setFiles: PropTypes.func,
  field: PropTypes.string,
  multiple: PropTypes.bool.isRequired,
};

const MeetingRooms = ({ meeting, getMeetingRooms, addMeetingRoom, updateMeetingRoom }) => {
  const [token, setToken] = useState(null);
  const { meetingRooms, addMeetingRoomLoading, updateMeetingRoomLoading } = meeting;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    name: '',
    location: '',
    capacity: 0,
    facility: '',
  });
  const { register, handleSubmit, errors } = useForm();
  const [files, setFiles] = useState({
    cover: [],
  });

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getMeetingRooms({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getMeetingRooms]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      name: '',
      location: '',
      capacity: 0,
      facility: '',
    });
    setFiles({
      cover: [],
    });
  };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v.id,
      name: v.name,
      location: v.location,
      capacity: v.capacity,
      facility: v.facility,
    });
    setFiles({
      cover: v.cover,
    });
  };

  const onSubmit = (data) => {
    const tempFiles = Object.assign({}, files);
    tempFiles.cover = tempFiles.cover
      .filter((f) => f.uploaded)
      .map((el) => {
        return {
          id: el.id,
          loading: el.loading,
          path: el.path,
          size: el.size,
          thumbnailUrl: el.thumbnailUrl,
          type: el.type,
          uploaded: el.uploaded,
          url: el.url,
          width: el.width,
          height: el.height,
        };
      });
    if (mode === 'Tambah') {
      return addMeetingRoom({
        ...form,
        ...data,
        cover: tempFiles.cover,
        resetForm,
        getMeetingRooms,
        token,
      });
    }
    updateMeetingRoom({
      ...form,
      ...data,
      cover: tempFiles.cover,
      resetForm,
      getMeetingRooms,
      token,
    });
  };

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  function dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const uploadImage = async (field, index) => {
    const formData = new FormData();
    const file = dataURItoBlob(files[field][index].base64);
    const fileName = `${moment().unix()}.${mimeType.extension(files[field][index].type)}`;
    var loadingTrue = Object.assign({}, files);
    loadingTrue[field][index].loading = true;
    setFiles(loadingTrue);
    formData.append('image', file, fileName);
    fetch(`${getHost()}/api/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then(async (results) => {
        const response = await results.json();
        if (results.status === 201) {
          var done = Object.assign({}, files);
          done[field][index].uploaded = true;
          done[field][index].loading = false;
          done[field][index].url = response.url;
          done[field][index].thumbnailUrl = response.thumbnailUrl;
          done[field][index].width = response.width;
          done[field][index].height = response.height;
          setFiles(done);
          toast.success('Uploaded');
        } else {
          var loadingFalse = Object.assign({}, files);
          loadingFalse[field][index].loading = true;
          setFiles(loadingFalse);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah Meeting Room
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="name">
                Nama<span style={{ color: 'red' }}>*</span>{' '}
                {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.name}
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="location">
                Lokasi<span style={{ color: 'red' }}>*</span>{' '}
                {errors.location && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.location}
                type="text"
                name="location"
                id="location"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="capacity">
                Kapasitas<span style={{ color: 'red' }}>*</span>{' '}
                {errors.capacity && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.capacity}
                type="number"
                name="capacity"
                id="capacity"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="facility">
                Fasilitas<span style={{ color: 'red' }}>*</span>{' '}
                {errors.facility && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.facility}
                type="text"
                name="facility"
                id="facility"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label>Foto</Label>
              <DropzoneExample files={files} setFiles={setFiles} field="cover" multiple={false} />
              <div>
                <div
                  style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}
                >
                  {files.cover.map((el, i) => (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px',
                        borderBottom: i === files.cover.length - 1 ? 'none' : '1px solid #d8e2ef',
                      }}
                    >
                      <img
                        alt={el.path}
                        src={el.thumbnailUrl ? el.thumbnailUrl : el.base64}
                        style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                      />
                      <div style={{ width: '25vw' }}>
                        <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.path}</p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                          {bytesToSize(el.size)}
                        </p>
                        {el.uploaded && el.thumbnailUrl ? (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FontAwesomeIcon
                              size="sm"
                              icon={faCheck}
                              transform="grow-12 right-6"
                              className="text-success"
                              style={{ margin: '0px 8px' }}
                            />
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                var fl = Object.assign({}, files);
                                fl.cover = fl.cover.filter((f) => f.id !== el.id);
                                setFiles(fl);
                              }}
                            >
                              <FontAwesomeIcon
                                size="1x"
                                icon={faTimes}
                                transform="grow-12 right-6"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        ) : el.loading ? (
                          <Spinner color="primary" style={{ margin: '0px 8px' }} />
                        ) : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                uploadImage('cover', i);
                              }}
                            >
                              <FontAwesomeIcon
                                size="sm"
                                icon={faUpload}
                                transform="grow-12 right-6"
                                className="text-primary"
                              />
                            </div>
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                var fl = Object.assign({}, files);
                                fl.cover = fl.cover.filter((f) => f.id !== el.id);
                                setFiles(fl);
                              }}
                            >
                              <FontAwesomeIcon
                                size="1x"
                                icon={faTimes}
                                transform="grow-12 right-6"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ height: '16px' }} />
              </div>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={addMeetingRoomLoading || updateMeetingRoomLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>Lokasi</th>
                <th>Kapasitas</th>
                <th>Fasilitas</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {meetingRooms.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>{el.name}</td>
                  <td>{el.location}</td>
                  <td>{el.capacity} orang</td>
                  <td>{el.facility}</td>
                  <td>
                    <FontAwesomeIcon
                      size="sm"
                      style={{ cursor: 'pointer' }}
                      icon={faPencilAlt}
                      transform="grow-12 right-6"
                      className="text-info"
                      onClick={setFilledForm.bind(this, el)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

MeetingRooms.propTypes = {
  meeting: PropTypes.object,
  getMeetingRooms: PropTypes.func,
  addMeetingRoom: PropTypes.func,
  updateMeetingRoom: PropTypes.func,
};

const mapStateToProps = (state) => ({
  meeting: state.meeting,
});

const mapActionToProps = {
  getMeetingRooms,
  addMeetingRoom,
  updateMeetingRoom,
};

export default connect(mapStateToProps, mapActionToProps)(MeetingRooms);
