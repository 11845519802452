import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/id';
import { useForm } from 'react-hook-form';

import { getVehicles, getCars, getDrivers, addTrip, updateTrip, deleteTrip } from '../../redux/actions';
import { getHost } from '../../constants';

const Vehicles = ({ getVehicles, getCars, getDrivers, addTrip, updateTrip, deleteTrip, vehicle }) => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState({
    isSLA: '',
    level: '',
  });
  const { vehicles, cars, drivers, addTripLoading, updateTripLoading, deleteTripLoading } = vehicle;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    car: '',
    driver: '',
    from: new Date(),
    to: new Date(),
    destination: '',
    user: '',
    requirement: '',
  });
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        setUserData(JSON.parse(data));
        getVehicles({ token: JSON.parse(data).data.token });
        getCars({ token: JSON.parse(data).data.token });
        getDrivers({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getCars, getDrivers, getVehicles]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      car: '',
      driver: '',
      from: new Date(),
      to: new Date(),
      destination: '',
      user: '',
      requirement: '',
    });
  };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v.id,
      car: v.car._id,
      driver: v.driver._id,
      from: v.from,
      to: v.to,
      destination: v.destination,
      user: v.user,
      requirement: v.requirement,
    });
  };

  const onSubmit = (data) => {
    if (mode === 'Tambah') {
      return addTrip({
        ...form,
        ...data,
        resetForm,
        getVehicles,
        token,
      });
    }
    updateTrip({
      ...form,
      ...data,
      resetForm,
      getVehicles,
      token,
    });
  };

  const addDays = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  var vehicleData = vehicles;
  if (vehicleData) {
    vehicleData = vehicleData.filter((el) => {
      if (new Date().toLocaleDateString() === new Date(el.to).toLocaleDateString()) {
        return new Date().toLocaleDateString() === new Date(el.from).toLocaleDateString();
      }
      return (
        new Date().toLocaleDateString() === new Date(el.from).toLocaleDateString() ||
        new Date() < addDays(new Date(el.to), 1)
      );
    });
    if (!userData.isSLA && userData.level !== 'Driver') {
      vehicleData = vehicleData.filter((el) => new Date().toISOString() >= el.from || el.departTime);
    }
  }
  let car = cars;
  let driver = drivers;
  let filteredCar;
  let filteredDriver;
  let nonbookedCar;
  let nonbookedDriver;
  if (car && driver && vehicleData) {
    car = car.map((el) => ({
      ...el,
      status: 'Tersedia',
    }));
    driver = driver.map((el) => ({
      ...el,
      status: 'Tersedia',
    }));
    if (vehicleData.length !== 0) {
      const a = [];
      const b = [];
      car.forEach((el) => {
        vehicleData.forEach((vehel) => {
          if (vehel.departTime) {
            if (el.id === vehel.car._id) {
              a.push(el);
            }
          }
        });
      });
      driver.forEach((el) => {
        vehicleData.forEach((vehel) => {
          if (vehel.departTime) {
            if (el.id === vehel.driver._id) {
              b.push(el);
            }
          }
        });
      });
      nonbookedCar = car.filter((el) => vehicleData.filter((anEl) => anEl.car._id === el.id).length === 0);
      filteredCar = car.filter((el) => vehicleData.filter((anEl) => anEl.car._id === el.id).length !== 0);
      nonbookedDriver = driver.filter((el) => vehicleData.filter((anEl) => anEl.driver._id === el.id).length === 0);
      filteredDriver = driver.filter((el) => vehicleData.filter((anEl) => anEl.driver._id === el.id).length !== 0);
      nonbookedCar = nonbookedCar.map((el) => ({
        ...el,
        status: 'Tersedia',
      }));
      nonbookedDriver = nonbookedDriver.map((el) => ({
        ...el,
        status: 'Tersedia',
      }));
      filteredCar = filteredCar.map((el) => ({
        ...el,
        status: 'Dipesan',
      }));
      filteredDriver = filteredDriver.map((el) => ({
        ...el,
        status: 'Dipesan',
      }));
      car = [...nonbookedCar, ...filteredCar];
      car = car.map((el) => {
        let newStatus;
        a.forEach((elA) => {
          if (elA.id === el.id) {
            newStatus = 'Digunakan';
          }
        });
        return {
          ...el,
          status: newStatus || el.status,
        };
      });
      driver = [...nonbookedDriver, ...filteredDriver];
      driver = driver.map((el) => {
        let newStatus;
        b.forEach((elB) => {
          if (elB.id === el.id) {
            newStatus = 'Digunakan';
          }
        });
        return {
          ...el,
          status: newStatus || el.status,
        };
      });
    }
  }
  return (
    <Card>
      <CardBody>
        <div>
          <h3>Trip - {vehicleData && vehicleData.length}</h3>
          {mode !== 'Update' && (
            <>
              <Button
                onClick={() => {
                  setShowForm(!showForm);
                  setMode('Tambah');
                }}
                color="primary"
              >
                Tambah Trip
              </Button>
              <div style={{ height: '16px' }} />
            </>
          )}
          {showForm && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="car">
                  Mobil<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.car && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.car}
                  type="select"
                  name="car"
                  id="car"
                  innerRef={register({ required: true })}
                >
                  <option value="">Pilih Mobil</option>
                  {car &&
                    car.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.plateNumber} | {el.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="driver">
                  Driver<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.driver && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.driver}
                  type="select"
                  name="driver"
                  id="driver"
                  innerRef={register({ required: true })}
                >
                  <option value="">Pilih Driver</option>
                  {driver &&
                    driver.map((el) => (
                      <option key={el.id} value={el.id}>
                        {el.name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormGroup>
                  <Label>Mulai</Label>
                  <br />
                  <DateTimePicker
                    value={form.from}
                    onChange={(date) => {
                      setForm({
                        ...form,
                        from: new Date(date.toISOString()),
                      });
                    }}
                    ampm={false}
                    animateYearScrolling
                    inputVariant="outlined"
                    autoOk
                  />
                </FormGroup>
                <FormGroup style={{ marginLeft: '16px' }}>
                  <Label>Sampai</Label>
                  <br />
                  <DateTimePicker
                    value={form.to}
                    onChange={(date) => {
                      setForm({
                        ...form,
                        to: new Date(date.toISOString()),
                      });
                    }}
                    ampm={false}
                    animateYearScrolling
                    inputVariant="outlined"
                    autoOk
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <Label for="destination">
                  Tujuan<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.destination && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.destination}
                  type="text"
                  name="destination"
                  id="destination"
                  innerRef={register({ required: true })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="user">
                  Pengguna<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.user && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.user}
                  type="text"
                  name="user"
                  id="user"
                  innerRef={register({ required: true })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="requirement">
                  Keperluan<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.requirement && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.requirement}
                  type="text"
                  name="requirement"
                  id="requirement"
                  innerRef={register({ required: true })}
                />
              </FormGroup>
              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  block
                  className="mt-3"
                  disabled={addTripLoading || updateTripLoading}
                >
                  {mode}
                </Button>
              </FormGroup>
              <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
                Batal
              </Button>
              <div style={{ height: '16px' }} />
            </Form>
          )}
          {vehicleData.map((el) => {
            const { id, car, driver, from, to, destination, user, requirement, departTime, currentKm } = el;
            return (
              <div
                key={id}
                style={{
                  padding: '12px',
                  margin: '8px 0',
                  backgroundColor: departTime && currentKm ? '#f37665' : '#fef491',
                }}
              >
                <p style={{ margin: '0' }}>
                  {car.plateNumber} | {car.name}
                </p>
                <p style={{ margin: '0' }}>{driver.name}</p>
                <p style={{ margin: '0' }}>
                  {moment(from).locale('id').format('D MMMM')}, {moment(from).locale('id').format('HH:mm')} -{' '}
                  {moment(to).locale('id').format('D MMMM')}, {moment(to).locale('id').format('HH:mm')}
                </p>
                <p style={{ margin: '0' }}>{`Tujuan : ${destination}`}</p>
                <p style={{ margin: '0' }}>{`Pengguna : ${user}`}</p>
                <p style={{ margin: '0' }}>{`Keperluan : ${requirement}`}</p>
                {departTime && currentKm && (
                  <p style={{ margin: '0' }}>Waktu Berangkat : {moment(departTime).locale('id').format('HH:mm')}</p>
                )}
                {!departTime && !currentKm && (
                  <div>
                    <Button type="button" color="success" onClick={() => setFilledForm(el)}>
                      Update
                    </Button>
                    <Button
                      style={{ marginLeft: '8px' }}
                      type="button"
                      color="danger"
                      onClick={() => {
                        deleteTrip({
                          id,
                          resetForm,
                          getVehicles,
                          token,
                        });
                      }}
                      disabled={deleteTripLoading}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ padding: '8px', width: '50%' }}>
              <h3>Mobil - {car && car.length}</h3>
              <Button type="button" color="primary">
                Tambah Mobil
              </Button>
              {car.map((el) => {
                let newStatus;
                switch (el.status) {
                  case 'Tersedia':
                    newStatus = '#a6d277';
                    break;
                  case 'Digunakan':
                    newStatus = '#f37665';
                    break;
                  case 'Dipesan':
                    newStatus = '#fef491';
                    break;
                  default:
                    break;
                }
                return (
                  <div
                    key={el.id}
                    style={{
                      padding: '12px',
                      margin: '8px 0',
                      backgroundColor: newStatus,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p style={{ margin: '0', color: 'black' }}>
                      {el.plateNumber} | {el.name}
                    </p>
                    <a href={`${getHost()}/api/vehicle/car/report/${el.id}?format=xlsx`}>
                      <FontAwesomeIcon icon={faDownload} color="white" />
                    </a>
                  </div>
                );
              })}
            </div>
            <div style={{ padding: '8px', width: '50%' }}>
              <h3>Driver - {driver && driver.length}</h3>
              <div style={{ height: '38px' }} />
              {driver.map((el) => {
                let newStatus;
                switch (el.status) {
                  case 'Tersedia':
                    newStatus = '#a6d277';
                    break;
                  case 'Digunakan':
                    newStatus = '#f37665';
                    break;
                  case 'Dipesan':
                    newStatus = '#fef491';
                    break;
                  default:
                    break;
                }
                if (!el.attendance) {
                  newStatus = '#ccc';
                }
                return (
                  <div
                    key={el.id}
                    style={{
                      padding: '12px',
                      margin: '8px 0',
                      backgroundColor: newStatus,
                    }}
                  >
                    <p style={{ margin: '0', color: 'black' }}>{el.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Vehicles.propTypes = {
  vehicle: PropTypes.object,
  getVehicles: PropTypes.func,
  getCars: PropTypes.func,
  getDrivers: PropTypes.func,
  addTrip: PropTypes.func,
  updateTrip: PropTypes.func,
  deleteTrip: PropTypes.func,
};

const mapStateToProps = (state) => ({
  vehicle: state.vehicle,
});

const mapActionToProps = {
  getVehicles,
  getCars,
  getDrivers,
  addTrip,
  updateTrip,
  deleteTrip,
};

export default connect(mapStateToProps, mapActionToProps)(Vehicles);
