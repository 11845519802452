import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHost } from '../../constants';
import {
  VEHICLES_SUCCESS,
  VEHICLES_ERROR,
  CARS_SUCCESS,
  CARS_ERROR,
  DRIVERS_SUCCESS,
  DRIVERS_ERROR,
  ADD_TRIP_SUCCESS,
  ADD_TRIP_ERROR,
  UPDATE_TRIP_SUCCESS,
  UPDATE_TRIP_ERROR,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_ERROR,
} from '../../constants/ActionTypes';

export function* fetchVehicles(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/vehicle?status=true`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: VEHICLES_SUCCESS, payload: response.vehicle };
      } else {
        toast.error(response.message);
        return { type: VEHICLES_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: VEHICLES_ERROR };
    });
  yield put(result);
}

export function* fetchCars(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/vehicle/car/list`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: CARS_SUCCESS, payload: response.car };
      } else {
        toast.error(response.message);
        return { type: CARS_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: CARS_ERROR };
    });
  yield put(result);
}

export function* fetchDrivers(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/user?role=Driver`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: DRIVERS_SUCCESS, payload: response.user };
      } else {
        toast.error(response.message);
        return { type: DRIVERS_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DRIVERS_ERROR };
    });
  yield put(result);
}

export function* createTrip(params) {
  const { car, driver, from, to, destination, user, requirement, resetForm, getVehicles, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/vehicle`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      car,
      driver,
      from,
      to,
      destination,
      user,
      requirement,
      status: true,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getVehicles({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan trip');
        return { type: ADD_TRIP_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_TRIP_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_TRIP_ERROR };
    });
  yield put(result);
}

export function* updateTrip(params) {
  const { id, car, driver, from, to, destination, user, requirement, resetForm, getVehicles, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/vehicle/data/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      car,
      driver,
      from,
      to,
      destination,
      user,
      requirement,
      status: true,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getVehicles({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil memperbarui trip');
        return { type: UPDATE_TRIP_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_TRIP_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_TRIP_ERROR };
    });
  yield put(result);
}
export function* deleteTrip(params) {
  const { id, resetForm, getVehicles, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/vehicle/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        getVehicles({ token });
        toast.success('Berhasil menghapus trip');
        return { type: DELETE_TRIP_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_TRIP_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_TRIP_ERROR };
    });
  yield put(result);
}
