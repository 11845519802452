import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';

import { getHost, getHostV2 } from '../../constants';
import {
  DOCUMENTS_SUCCESS,
  DOCUMENTS_ERROR,
  ADD_DOCUMENT_SUCCESS,
  ADD_DOCUMENT_ERROR,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  DOCUMENT_CONFIG_SUCCESS,
  DOCUMENT_CONFIG_ERROR,
  ADD_DOCUMENT_CONFIG_SUCCESS,
  ADD_DOCUMENT_CONFIG_ERROR,
  UPDATE_DOCUMENT_CONFIG_SUCCESS,
  UPDATE_DOCUMENT_CONFIG_ERROR,
  DOCUMENT_NUMBER_SUCCESS,
  DOCUMENT_NUMBER_ERROR,
  ADD_DOCUMENT_NUMBER_SUCCESS,
  ADD_DOCUMENT_NUMBER_ERROR,
  UPDATE_DOCUMENT_NUMBER_SUCCESS,
  UPDATE_DOCUMENT_NUMBER_ERROR,
  DELETE_DOCUMENT_NUMBER_SUCCESS,
  DELETE_DOCUMENT_NUMBER_ERROR,
} from '../../constants/ActionTypes';

export function* fetchDocuments(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: DOCUMENTS_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: DOCUMENTS_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DOCUMENTS_ERROR };
    });
  yield put(result);
}

export function* createDocument(params) {
  const {
    documentIn,
    name,
    documentNumber,
    documentType,
    date,
    sender,
    dateReceive,
    to,
    from,
    additional,
    numerator,
    documentLink,
    resetForm,
    getDocuments,
    token,
  } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document/`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentIn,
      name,
      documentNumber,
      documentType,
      date,
      sender,
      dateReceive,
      disposition: [],
      to,
      from,
      additional,
      originalDocument: [],
      numerator,
      documentLink,
    }),
  })
    .then(async (results) => {
      if (results.status === 201) {
        if (documentIn) {
          const file = await results.blob();
          var url = window.URL.createObjectURL(file);
          var a = document.createElement('a');
          a.href = url;
          a.download = `Lembar_Disposisi_${documentType}_${moment().unix()}.docx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          const response = await results.json();
          window.open(response.qrcodeUrl, '_blank');
        }
        resetForm();
        getDocuments({ token });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan dokumen');
        return { type: ADD_DOCUMENT_SUCCESS };
      }
      const response = await results.json();
      toast.error(response.message);
      return { type: ADD_DOCUMENT_ERROR };
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_DOCUMENT_ERROR };
    });
  yield put(result);
}

export function* deleteDocument(params) {
  const { id, getDocuments, token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getDocuments({ token });
        toast.success('Berhasil menghapus dokumen');
        return { type: DELETE_DOCUMENT_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_DOCUMENT_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_DOCUMENT_ERROR };
    });
  yield put(result);
}

export function* fetchDocumentConfigs(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/document-config`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: DOCUMENT_CONFIG_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: DOCUMENT_CONFIG_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DOCUMENT_CONFIG_ERROR };
    });
  yield put(result);
}

export function* createDocumentConfig(params) {
  const { lastDocumentNumber, documentType, resetForm, getDocumentConfigs, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/document-config`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lastDocumentNumber: parseInt(lastDocumentNumber),
      documentType,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan kegiatan');
        getDocumentConfigs({ token });
        return { type: ADD_DOCUMENT_CONFIG_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_DOCUMENT_CONFIG_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_DOCUMENT_CONFIG_ERROR };
    });
  yield put(result);
}

export function* updateDocumentConfig(params) {
  const { id, lastDocumentNumber, documentType, resetForm, getDocumentConfigs, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/document-config/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      lastDocumentNumber: parseInt(lastDocumentNumber),
      documentType,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        toast.success('Berhasil memperbarui kegiatan');
        resetForm();
        getDocumentConfigs({ token });
        return { type: UPDATE_DOCUMENT_CONFIG_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_DOCUMENT_CONFIG_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_DOCUMENT_CONFIG_ERROR };
    });
  yield put(result);
}

export function* fetchDocumentNumbers(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document-number`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: DOCUMENT_NUMBER_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: DOCUMENT_NUMBER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DOCUMENT_NUMBER_ERROR };
    });
  yield put(result);
}

export function* createDocumentNumber(params) {
  const { documentType, subject, to, resetForm, getDocumentNumbers, token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document-number`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentType,
      subject,
      to,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan kegiatan');
        getDocumentNumbers({ token });
        return { type: ADD_DOCUMENT_NUMBER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_DOCUMENT_NUMBER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_DOCUMENT_NUMBER_ERROR };
    });
  yield put(result);
}

export function* updateDocumentNumber(params) {
  const { id, documentNumber, documentType, creator, subject, to, published, resetForm, getDocumentNumbers, token } =
    params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document-number/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      documentNumber,
      documentType,
      creator,
      subject,
      to,
      published,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        toast.success('Berhasil memperbarui kegiatan');
        resetForm();
        getDocumentNumbers({ token });
        return { type: UPDATE_DOCUMENT_NUMBER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_DOCUMENT_NUMBER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_DOCUMENT_NUMBER_ERROR };
    });
  yield put(result);
}

export function* deleteDocumentNumber(params) {
  const { id, token, getDocumentNumbers } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/document-number/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getDocumentNumbers({ token });
        toast.success('Berhasil menghapus nomor dokumen');
        return { type: DELETE_DOCUMENT_NUMBER_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_DOCUMENT_NUMBER_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_DOCUMENT_NUMBER_ERROR };
    });
  yield put(result);
}
