import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Switch from 'react-switch';
import moment from 'moment';

import {
  getDocumentNumbers,
  addDocumentNumber,
  updateDocumentNumber,
  getUsers,
  deleteDocumentNumber,
} from '../../redux/actions';

const DocumentNumber = ({
  document,
  user,
  getDocumentNumbers,
  addDocumentNumber,
  updateDocumentNumber,
  getUsers,
  deleteDocumentNumber,
}) => {
  const [token, setToken] = useState(null);
  const { documentNumbers, addDocumentNumberLoading, updateDocumentNumberLoading } = document;
  const { users } = user;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    documentNumber: 0,
    documentType: '',
    creator: '',
    subject: '',
    to: '',
    published: false,
  });
  const { register, handleSubmit, errors } = useForm();
  const documentTypes = [
    'M01',
    'M02',
    'Surat',
    'Faksimili',
    'Berita Acara',
    'Perjanjian',
    'Risalah Rapat',
    'Pengumuman ',
    'Sertifikat',
    'Surat Kuasa',
    'UAM',
    'CBS'
  ];

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getDocumentNumbers({ token: JSON.parse(data).data.token });
        getUsers({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDocumentNumbers, getUsers]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      documentNumber: 0,
      documentType: '',
      creator: '',
      subject: '',
      to: '',
      published: false,
    });
  };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v._id,
      subject: v.subject,
      documentType: v.documentType,
      to: v.to,
      creator: v.creator._id,
      published: v.published,
      documentNumber: v.documentNumber,
    });
  };

  const onSubmit = (data) => {
    if (mode === 'Tambah') {
      return addDocumentNumber({
        ...form,
        ...data,
        resetForm,
        getDocumentNumbers,
        token,
      });
    }
    updateDocumentNumber({
      ...form,
      ...data,
      resetForm,
      getDocumentNumbers,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="documentType">
                Tipe Dokumen<span style={{ color: 'red' }}>*</span>{' '}
                {errors.documentType && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.documentType}
                type="select"
                name="documentType"
                id="documentType"
                innerRef={register({ required: true })}
              >
                <option value="">Pilih Tipe Dokumen</option>
                {documentTypes.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="subject">
                Perihal<span style={{ color: 'red' }}>*</span>{' '}
                {errors.subject && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.subject}
                type="text"
                name="subject"
                id="subject"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="to">
                Kepada<span style={{ color: 'red' }}>*</span>{' '}
                {errors.to && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input defaultValue={form.to} type="text" name="to" id="to" innerRef={register({ required: true })} />
            </FormGroup>
            {mode === 'Update' && (
              <FormGroup>
                <Label for="creator">
                  Pengguna<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.creator && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.creator}
                  type="select"
                  name="creator"
                  id="creator"
                  innerRef={register({ required: true })}
                >
                  {users.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
            {mode === 'Update' && (
              <FormGroup check>
                <Label>Published</Label>
                <br />
                <Switch onChange={(e) => setForm({ ...form, published: e })} checked={form.published} />
              </FormGroup>
            )}
            <FormGroup>
              <Button
                disabled={addDocumentNumberLoading || updateDocumentNumberLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Tipe Dokumen</th>
                <th>Tanggal</th>
                <th>Nomor Dokumen</th>
                <th>Perihal</th>
                <th>Kepada</th>
                <th>Pengguna</th>
                <th>Terpublish</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {documentNumbers.map((el, i) => (
                <tr key={el._id}>
                  <th>{i + 1}</th>
                  <td>{el.documentType}</td>
                  <td>{moment(el.created_at).format('LL')}</td>
                  <td>{el.documentNumber}</td>
                  <td>{el.subject}</td>
                  <td>{el.to}</td>
                  <td>{el.creator.name}</td>
                  <td>
                    {el.published ? (
                      <FontAwesomeIcon size="sm" icon={faCheck} transform="grow-12 right-6" className="text-success" />
                    ) : (
                      <FontAwesomeIcon size="sm" icon={faTimes} transform="grow-12 right-6" className="text-danger" />
                    )}
                  </td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faPencilAlt}
                        transform="grow-12 right-6"
                        className="text-info"
                        onClick={setFilledForm.bind(this, el)}
                      />
                      <div style={{ width: '16px' }} />
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faTrash}
                        transform="grow-12 right-6"
                        className="text-danger"
                        onClick={() => {
                          deleteDocumentNumber({ token, getDocumentNumbers, id: el._id });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

DocumentNumber.propTypes = {
  document: PropTypes.object,
  user: PropTypes.object,
  getDocumentNumbers: PropTypes.func,
  addDocumentNumber: PropTypes.func,
  updateDocumentNumber: PropTypes.func,
  getUsers: PropTypes.func,
  deleteDocumentNumber: PropTypes.func,
};

const mapStateToProps = (state) => ({
  document: state.document,
  user: state.user,
});

const mapActionToProps = {
  getDocumentNumbers,
  addDocumentNumber,
  updateDocumentNumber,
  getUsers,
  deleteDocumentNumber,
};

export default connect(mapStateToProps, mapActionToProps)(DocumentNumber);
