import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';

import { getDocumentConfigs, addDocumentConfig, updateDocumentConfig } from '../../redux/actions';

const DocumentNumberSetting = ({ document, getDocumentConfigs, addDocumentConfig, updateDocumentConfig }) => {
  const [token, setToken] = useState(null);
  const { documentConfigs, addDocumentConfigLoading, updateDocumentConfigLoading } = document;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    lastDocumentNumber: '',
    documentType: '',
  });
  const { register, handleSubmit, errors } = useForm();
  const documentTypes = [
    'M01',
    'M02',
    'Surat',
    'Faksimili',
    'Berita Acara',
    'Perjanjian',
    'Risalah Rapat',
    'Pengumuman ',
    'Sertifikat',
    'Surat Kuasa',
    'UAM',
    'CBS'
  ];

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getDocumentConfigs({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDocumentConfigs]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      lastDocumentNumber: '',
      documentType: '',
    });
  };

  const setFilledForm = (v) => {
    window.scrollTo(0, 0);
    setShowForm(true);
    setMode('Update');
    setForm({
      id: v._id,
      lastDocumentNumber: v.lastDocumentNumber,
      documentType: v.documentType,
    });
  };

  const onSubmit = (data) => {
    if (mode === 'Tambah') {
      return addDocumentConfig({
        ...form,
        ...data,
        resetForm,
        getDocumentConfigs,
        token,
      });
    }
    updateDocumentConfig({
      ...form,
      ...data,
      resetForm,
      getDocumentConfigs,
      token,
    });
  };

  return (
    <Card>
      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="documentType">
                Tipe Dokumen<span style={{ color: 'red' }}>*</span>{' '}
                {errors.documentType && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.documentType}
                type="select"
                name="documentType"
                id="documentType"
                innerRef={register({ required: true })}
              >
                <option value="">Pilih Tipe Dokumen</option>
                {documentTypes.map((el) => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="lastDocumentNumber">
                Nomor Dokumen Terakhir<span style={{ color: 'red' }}>*</span>{' '}
                {errors.lastDocumentNumber && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.lastDocumentNumber}
                type="text"
                name="lastDocumentNumber"
                id="lastDocumentNumber"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Button
                disabled={addDocumentConfigLoading || updateDocumentConfigLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Tipe Dokumen</th>
                <th>Nomor Dokumen Terakhir</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {documentConfigs.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>{el.documentType}</td>
                  <td>{el.lastDocumentNumber}</td>
                  <td>
                    <FontAwesomeIcon
                      size="sm"
                      style={{ cursor: 'pointer' }}
                      icon={faPencilAlt}
                      transform="grow-12 right-6"
                      className="text-info"
                      onClick={setFilledForm.bind(this, el)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

DocumentNumberSetting.propTypes = {
  document: PropTypes.object,
  getDocumentConfigs: PropTypes.func,
  addDocumentConfig: PropTypes.func,
  updateDocumentConfig: PropTypes.func,
};

const mapStateToProps = (state) => ({
  document: state.document,
});

const mapActionToProps = {
  getDocumentConfigs,
  addDocumentConfig,
  updateDocumentConfig,
};

export default connect(mapStateToProps, mapActionToProps)(DocumentNumberSetting);
