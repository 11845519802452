import {
  UMKM,
  UMKM_SUCCESS,
  UMKM_ERROR,
  ADD_UMKM,
  ADD_UMKM_SUCCESS,
  ADD_UMKM_ERROR,
  UPDATE_UMKM,
  UPDATE_UMKM_SUCCESS,
  UPDATE_UMKM_ERROR,
  DELETE_UMKM,
  DELETE_UMKM_SUCCESS,
  DELETE_UMKM_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    umkms: [],
    umkmsLoading: false,
    addUMKMLoading: false,
    updateUMKMLoading: false,
    deleteUMKMLoading: false,
  },
  action
) => {
  switch (action.type) {
    case UMKM:
      return {
        ...state,
        umkmsLoading: true,
      };
    case UMKM_SUCCESS:
      return {
        ...state,
        umkms: action.payload,
        umkmsLoading: false,
      };
    case UMKM_ERROR:
      return {
        ...state,
        umkmsLoading: false,
      };

    case ADD_UMKM:
      return {
        ...state,
        addUMKMLoading: true,
      };
    case ADD_UMKM_SUCCESS:
      return {
        ...state,
        addUMKMLoading: false,
      };
    case ADD_UMKM_ERROR:
      return {
        ...state,
        addUMKMLoading: false,
      };

    case UPDATE_UMKM:
      return {
        ...state,
        updateUMKMLoading: true,
      };
    case UPDATE_UMKM_SUCCESS:
      return {
        ...state,
        updateUMKMLoading: false,
      };
    case UPDATE_UMKM_ERROR:
      return {
        ...state,
        updateUMKMLoading: false,
      };

    case DELETE_UMKM:
      return {
        ...state,
        deleteUMKMLoading: true,
      };
    case DELETE_UMKM_SUCCESS:
      return {
        ...state,
        deleteUMKMLoading: false,
      };
    case DELETE_UMKM_ERROR:
      return {
        ...state,
        deleteUMKMLoading: false,
      };

    default:
      return state;
  }
};
