import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import { getHost } from '../../constants';

const Reports = () => {
  const [month1, setMonth1] = useState('');
  const [month2, setMonth2] = useState('');
  return (
    <Card>
      <CardBody>
        <h3>Download Attendance Report</h3>
        <select
          value={month1}
          className="form-control"
          onChange={(e) => {
            setMonth1(e.target.value);
          }}
        >
          <option value="">Select Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </select>
        <a
          style={{ margin: '8px 0' }}
          className="btn btn-primary"
          href={`${getHost()}/api/attendance/attendance/report?month=${month1}&format=xlsx`}
        >
          Download
        </a>
        <h3>Download All Report</h3>
        <select
          value={month2}
          className="form-control"
          onChange={(e) => {
            setMonth2(e.target.value);
          }}
        >
          <option value="">Select Month</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <a
          style={{ margin: '8px 0' }}
          className="btn btn-primary"
          href={`${getHost()}/api/v2/attendance/attendance/report?month=${month2}`}
        >
          Download
        </a>
      </CardBody>
    </Card>
  );
};

export default Reports;
