import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHost } from '../../constants';
import {
  SCREEN_SAVER_VIDEO_SUCCESS,
  SCREEN_SAVER_VIDEO_ERROR,
  ADD_SCREEN_SAVER_VIDEO_SUCCESS,
  ADD_SCREEN_SAVER_VIDEO_ERROR,
  DELETE_SCREEN_SAVER_VIDEO_SUCCESS,
  DELETE_SCREEN_SAVER_VIDEO_ERROR,
} from '../../constants/ActionTypes';

export function* fetchScreensaverVideo(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/screen-saver-video`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: SCREEN_SAVER_VIDEO_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: SCREEN_SAVER_VIDEO_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: SCREEN_SAVER_VIDEO_ERROR };
    });
  yield put(result);
}

export function* createScreensaverVideo(params) {
  const { url, getScreensaverVideo, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/screen-saver-video`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      url,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getScreensaverVideo({
          token,
        });
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan Screensaver Video');
        return { type: ADD_SCREEN_SAVER_VIDEO_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_SCREEN_SAVER_VIDEO_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_SCREEN_SAVER_VIDEO_ERROR };
    });
  yield put(result);
}

export function* deleteScreensaverVideo(params) {
  const { id, token, getScreensaverVideo } = params.payload;
  const result = yield fetch(`${getHost()}/api/screen-saver-video/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getScreensaverVideo({
          token,
        });
        toast.success('Berhasil menghapus Screensaver Video');
        return { type: DELETE_SCREEN_SAVER_VIDEO_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_SCREEN_SAVER_VIDEO_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_SCREEN_SAVER_VIDEO_ERROR };
    });
  yield put(result);
}
