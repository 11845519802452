import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Media,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Autocomplete from 'react-google-autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faUpload } from '@fortawesome/free-solid-svg-icons';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import mimeType from 'mime-types';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@material-ui/pickers';

import exampleImage from '../../assets/img/illustrations/bg-card-shape.jpg';
import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import ContentWithAsideLayout from '../ContentWithAsideLayout';
import FalconDropzone from '../../components/common/FalconDropzone';
import { addUMKM, updateUMKM } from '../../redux/actions';
import { getHostV2 } from '../../constants';
import mapStyle from './mapStyle.json';

const DropzoneExample = ({ files, setFiles, field, multiple }) => {
  return (
    <Media className="flex-center pb-3 d-block d-md-flex text-center mb-2">
      <Media body className="ml">
        <FalconDropzone
          files={files[field]}
          onChange={(f) => {
            if (field === 'photos') {
              setFiles({
                ...files,
                [field]: [...files[field], ...f],
              });
            } else if (field === 'cover' || field === 'catalogue') {
              setFiles({
                ...files,
                [field]: f,
              });
            }
          }}
          multiple={multiple}
          accept="image/*"
          placeholder={
            <Fragment>
              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                <img src={cloudUpload} alt="" width={25} className="mr-2" />
                <Media>
                  <p className="fs-0 mb-0 text-700">Upload</p>
                </Media>
              </Media>
            </Fragment>
          }
        />
      </Media>
    </Media>
  );
};

DropzoneExample.propTypes = {
  files: PropTypes.object,
  setFiles: PropTypes.func,
  field: PropTypes.string,
  multiple: PropTypes.bool.isRequired,
};

const MyMapComponentEditor = withGoogleMap((props) => {
  useEffect(
    () => {
      if (props.disableMoveMarker) {
        props.refMap.current.panTo({
          lat: props.lat,
          lng: props.lng,
        });
      }
    },
    [props.disableMoveMarker, props.lat, props.lng, props.refMap],
    props.lng
  );

  const handleBoundsChanged = () => {
    props.setDisableMoveMarker(false);
    const mapCenter = props.refMap.current.getCenter();
    props.setForm({
      ...props.form,
      latitude: mapCenter.lat(),
      longitude: mapCenter.lng(),
    });
  };
  return (
    <GoogleMap
      ref={props.refMap}
      defaultZoom={15}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      onBoundsChanged={useCallback(handleBoundsChanged)}
      defaultOptions={{
        disableDefaultUI: true,
        zoomControl: true,
        styles: mapStyle,
      }}
    >
      {props.isMarkerShown && <Marker position={{ lat: props.lat, lng: props.lng }} />}
    </GoogleMap>
  );
});

const UMKMEditor = ({ umkm, addUMKM, updateUMKM }) => {
  const productTypes = [
    'Makanan dan Minuman',
    'Fashion',
    'Kerajinan',
    'Kesehatan',
    'Pertanian',
    'Peternakan',
    'Pondok Pesantren',
    'Lainnya',
  ];
  const features = ['QRIS', 'Halal'];
  const refMap = useRef(null);
  const { addUMKMLoading, updateUMKMLoading } = umkm;
  const [token, setToken] = useState(null);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    name: '',
    description: '',
    address: '',
    product: '',
    latitude: 1.128304,
    longitude: 104.056631,
    contact: '',
    email: '',
    type: '',
    orderSeq: '0',
    owner: '',
    other: false,
    expiredAt: new Date(),
    umkmType: '',
    features: [],
  });
  const [feature, setFeature] = useState('');
  const [files, setFiles] = useState({
    cover: [],
    photos: [],
    catalogue: [],
  });
  const lightBoxImages = files.photos.map((el) => {
    if (el.url) {
      return el.url;
    }
    return el.base64;
  });
  const [disableMoveMarker, setDisableMoveMarker] = useState(false);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { register, handleSubmit, errors } = useForm();
  const [showPath, setShowPath] = useState(true);
  const [catalogues, setCatalogues] = useState([]);
  const [catalogueForm, setCatalogueForm] = useState({
    name: '',
    price: '',
    maps: new URLSearchParams(new URL(window.location.href).search).get('id'),
  });

  const [openModal, setOpenModal] = useState(false);
  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(new URL(window.location.href).search);
    if (params.get('mobile') === 'true') {
      setShowPath(false);
    }
  }, []);

  const resetCatalogueForm = () => {
    setCatalogueForm({
      name: '',
      price: 0,
      maps: new URLSearchParams(new URL(window.location.href).search).get('id'),
    });
  };

  const reload = useCallback(() => {
    const getUserData = async (v) => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        if (v === 'Update') {
          const id = params.get('id');
          setMode(v);
          fetch(`${getHostV2()}/v1/maps/${id}`, {
            method: 'GET',
            headers: {
              Authorization: JSON.parse(data).data.token,
              'Content-Type': 'application/json',
            },
          })
            .then(async (results) => {
              const response = await results.json();
              if (results.status === 200) {
                setForm({
                  id: id,
                  name: response.data.name,
                  description: response.data.description,
                  address: response.data.address,
                  product: response.data.product,
                  latitude: response.data.latitude,
                  longitude: response.data.longitude,
                  contact: response.data.contact,
                  email: response.data.email,
                  type: response.data.type,
                  orderSeq: `${response.data.orderSeq}`,
                  owner: response.data.owner,
                  expiredAt: response.data.expiredAt,
                  other: !productTypes.includes(response.data.product) ? true : false,
                  umkmType: response.data.umkmType,
                  features: response.data.features,
                });
                refMap.current.panTo({
                  lat: response.data.latitude,
                  lng: response.data.longitude,
                });
                setFiles({
                  cover: response.data.cover,
                  photos: response.data.photos,
                  catalogue: [],
                });
                fetch(`${getHostV2()}/v1/catalogue?mapsID=${id}`, {
                  method: 'GET',
                  headers: {
                    Authorization: JSON.parse(data).data.token,
                    'Content-Type': 'application/json',
                  },
                })
                  .then(async (results2) => {
                    const response2 = await results2.json();
                    if (results.status === 200) {
                      setCatalogues(response2.data);
                    } else {
                      toast.error(response.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.toString());
                  });
              } else {
                toast.error(response.message);
              }
            })
            .catch((error) => {
              toast.error(error.toString());
            });
        }
      }
    };

    const params = new URLSearchParams(window.location.search);
    const m = params.get('mode');
    setMode(m);
    getUserData(m);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reload();
  }, [reload]);

  const onChangeInput = (e) => {
    if (e.target.name === 'latitude' || e.target.name === 'longitude') {
      if (e.target.value !== '') {
        return setForm({
          ...form,
          [e.target.name]: parseFloat(e.target.value),
        });
      }
    }
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  function dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const uploadImage = async (field, index) => {
    const formData = new FormData();
    const file = dataURItoBlob(files[field][index].base64);
    const fileName = `${moment().unix()}.${mimeType.extension(files[field][index].type)}`;
    var loadingTrue = Object.assign({}, files);
    loadingTrue[field][index].loading = true;
    setFiles(loadingTrue);
    formData.append('image', file, fileName);
    fetch(`${getHostV2()}/v1/upload`, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then(async (results) => {
        const response = await results.json();
        if (results.status === 201) {
          var done = Object.assign({}, files);
          done[field][index].uploaded = true;
          done[field][index].loading = false;
          done[field][index].url = response.data.url;
          done[field][index].thumbnailUrl = response.data.thumbnailUrl;
          done[field][index].width = response.data.width;
          done[field][index].height = response.data.height;
          setFiles(done);
          toast.success('Uploaded');
        } else {
          var loadingFalse = Object.assign({}, files);
          loadingFalse[field][index].loading = false;
          setFiles(loadingFalse);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const resetForm = () => {
    setForm({
      id: null,
      name: '',
      description: '',
      address: '',
      product: '',
      latitude: 1.128304,
      longitude: 104.056631,
      contact: '',
      email: '',
      type: '',
      orderSeq: '0',
      owner: '',
      other: false,
      umkmType: '',
      features: [],
    });
    setFiles({
      cover: [],
      photos: [],
    });
  };

  const onSubmit = (data) => {
    const tempFiles = Object.assign({}, files);
    tempFiles.cover = tempFiles.cover
      .filter((f) => f.uploaded)
      .map((el) => {
        return {
          id: el.id,
          loading: el.loading,
          path: el.path,
          size: el.size,
          thumbnailUrl: el.thumbnailUrl,
          type: el.type,
          uploaded: el.uploaded,
          url: el.url,
          width: el.width,
          height: el.height,
        };
      });
    tempFiles.photos = tempFiles.photos
      .filter((f) => f.uploaded)
      .map((el) => {
        return {
          id: el.id,
          loading: el.loading,
          path: el.path,
          size: el.size,
          thumbnailUrl: el.thumbnailUrl,
          type: el.type,
          uploaded: el.uploaded,
          url: el.url,
          width: el.width,
          height: el.height,
        };
      });
    if (mode === 'Tambah') {
      return addUMKM({
        ...form,
        ...data,
        cover: tempFiles.cover,
        photos: tempFiles.photos,
        resetForm,
        token,
      });
    }
    updateUMKM({
      ...form,
      ...data,
      cover: tempFiles.cover,
      photos: tempFiles.photos,
      token,
    });
  };

  const addCatalogue = () => {
    const tempFiles = Object.assign({}, files);
    let photos = {};
    tempFiles.catalogue = tempFiles.catalogue
      .filter((f) => f.uploaded)
      .forEach((el) => {
        photos = {
          id: el.id,
          loading: el.loading,
          path: el.path,
          size: el.size,
          thumbnailUrl: el.thumbnailUrl,
          type: el.type,
          uploaded: el.uploaded,
          url: el.url,
          width: el.width,
          height: el.height,
        };
      });
    if (!photos.uploaded) {
      return toast.error('Kamu belum mengunggah foto katalog');
    }
    const request = {
      ...catalogueForm,
      photos,
    };
    fetch(`${getHostV2()}/v1/catalogue`, {
      method: 'POST',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then(async (result) => {
        const response = await result.json();
        if (result.status === 200) {
          resetCatalogueForm();
          setFiles({
            ...files,
            catalogue: [],
          });
          closeModal();
          reload();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const updateFeature = (mode, value) => {
    setFeature(value);
    fetch(`${getHostV2()}/v1/maps/${new URLSearchParams(new URL(window.location.href).search).get('id')}/feature`, {
      method: 'PUT',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mode: mode,
        value: value,
      }),
    })
      .then(async (result) => {
        const response = await result.json();
        if (result.status === 201) {
          reload();
        } else {
          toast.error(response.message);
        }
        setFeature('');
      })
      .catch((error) => {
        toast.error(error.toString());
        setFeature('');
      });
  };

  return (
    <ContentWithAsideLayout
      aside={
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="type">
                  Tipe<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.type && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  value={form.type}
                  type="select"
                  name="type"
                  id="type"
                  innerRef={register({ required: true })}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      type: e.target.value,
                    })
                  }
                >
                  <option value="">Pilih Tipe</option>
                  <option value="UMKM">UMKM</option>
                  <option value="KUPVA">KUPVA</option>
                  <option value="PTD">PTD</option>
                  <option value="PSBI">PSBI</option>
                </Input>
              </FormGroup>
              {form.type === 'UMKM' && (
                <FormGroup>
                  <Label for="umkmType">UMKM Sub-kategori</Label>
                  <Input
                    value={form.umkmType}
                    type="select"
                    name="umkmType"
                    id="umkmType"
                    innerRef={register({ required: true })}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        umkmType: e.target.value,
                      })
                    }
                  >
                    <option value="">Pilih Tipe</option>
                    <option value="Kuliner">Kuliner</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Kerajinan">Kerajinan</option>
                  </Input>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="name">
                  Nama<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  innerRef={register({ required: true })}
                  defaultValue={form.name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="owner">Owner</Label>
                <Input
                  type="text"
                  name="owner"
                  id="owner"
                  innerRef={register({ required: false })}
                  defaultValue={form.owner}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Deskripsi</Label>
                <Input
                  defaultValue={form.description}
                  type="textarea"
                  name="description"
                  id="description"
                  innerRef={register({ required: true })}
                  rows={4}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address">
                  Alamat<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.address && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  innerRef={register({ required: true })}
                  defaultValue={form.address}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label for="product">{form.type === 'KUPVA' ? 'Status' : 'Bidang Usaha'}</Label>
                <Input
                  type="text"
                  name="product"
                  id="product"
                  innerRef={register({ required: true })}
                  defaultValue={form.product}
                />
              </FormGroup> */}
              {!form.other ? (
                <FormGroup>
                  <Label for="product">Bidang Usaha</Label>
                  <Input
                    value={form.product}
                    type="select"
                    name="product"
                    id="product"
                    innerRef={register({ required: true })}
                    onChange={(e) => {
                      if (e.target.value === 'Lainnya') {
                        return setForm({
                          ...form,
                          other: true,
                        });
                      }
                      setForm({
                        ...form,
                        product: e.target.value,
                      });
                    }}
                  >
                    <option value="">Pilih Tipe</option>
                    {productTypes.map((el) => (
                      <option key={el} value={el}>
                        {el}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label for="product">Bidang Usaha</Label>
                  <Input
                    type="text"
                    name="product"
                    id="product"
                    innerRef={register({ required: false })}
                    defaultValue={form.product}
                    onChange={(e) => setForm({ ...form, product: e.target.value })}
                  />
                </FormGroup>
              )}
              {form.type === 'UMKM' && (
                <FormGroup>
                  <Label for="feature">Fitur</Label>
                  <Input
                    value={feature}
                    type="select"
                    name="feature"
                    id="feature"
                    onChange={(e) => {
                      updateFeature('push', e.target.value);
                    }}
                  >
                    <option value="">Tambah Fitur</option>
                    {features
                      .filter((i) => !form.features.includes(i))
                      .map((el, i) => (
                        <option key={i} value={el}>
                          {el}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
              )}
              {form.features.map((el, i) => (
                <div key={i} style={{ display: 'flex', justifyContent: 'space-between', marginRight: '16px' }}>
                  <p key={i}>{el}</p>
                  <FontAwesomeIcon
                    size="1x"
                    icon={faTimes}
                    transform="grow-12 right-6"
                    className="text-danger"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      updateFeature('pull', el);
                    }}
                  />
                </div>
              ))}
              <div style={{ display: 'flex', flexDuration: 'row' }}>
                <FormGroup style={{ marginRight: '4px' }}>
                  <Label for="latitude">Latitude</Label>
                  <Input
                    value={form.latitude}
                    type="text"
                    name="latitude"
                    id="latitude"
                    onChange={onChangeInput.bind(this)}
                  />
                </FormGroup>
                <FormGroup style={{ marginLeft: '4px' }}>
                  <Label for="longitude">Longitude</Label>
                  <Input
                    value={form.longitude}
                    type="text"
                    name="longitude"
                    id="longitude"
                    onChange={onChangeInput.bind(this)}
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <Label for="contact">Kontak</Label>
                <Input type="text" name="contact" id="contact" innerRef={register} defaultValue={form.contact} />
              </FormGroup>
              <FormGroup>
                <Label for="email">E-mail</Label>
                <Input type="text" name="email" id="email" innerRef={register} defaultValue={form.email} />
              </FormGroup>
              {form.type === 'KUPVA' ? (
                <FormGroup>
                  <Label for="orderSeq">
                    No Urut Cabang<span style={{ color: 'red' }}>*</span>{' '}
                    {errors.orderSeq && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                  </Label>
                  <Input
                    type="text"
                    name="orderSeq"
                    id="orderSeq"
                    innerRef={register({ required: true })}
                    defaultValue={form.orderSeq}
                  />
                </FormGroup>
              ) : null}
              {form.type === 'KUPVA' ? (
                <FormGroup>
                  <Label>Masa Berlaku Izin</Label>
                  <br />
                  <DatePicker
                    format="D MMMM YYYY"
                    value={form.expiredAt}
                    onChange={(date) => {
                      setForm({
                        ...form,
                        expiredAt: new Date(date.toISOString()),
                      });
                    }}
                    animateYearScrolling
                    inputVariant="outlined"
                    autoOk
                  />
                </FormGroup>
              ) : null}
              <FormGroup>
                <Button
                  disabled={addUMKMLoading || updateUMKMLoading}
                  type="submit"
                  color="primary"
                  block
                  className="mt-3"
                >
                  {mode}
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      }
      isStickyAside
    >
      <Modal isOpen={openModal} toggle={closeModal}>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Form>
              <FormGroup>
                <Label for="name">Nama</Label>
                <Input
                  defaultValue={catalogueForm.name}
                  type="text"
                  name="name"
                  id="name"
                  rows={4}
                  onChange={(e) =>
                    setCatalogueForm({
                      ...catalogueForm,
                      name: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="price">Harga</Label>
                <Input
                  value={catalogueForm.price}
                  type="text"
                  name="price"
                  id="price"
                  rows={4}
                  onChange={(e) => {
                    setCatalogueForm({
                      ...catalogueForm,
                      price: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
                    });
                  }}
                />
              </FormGroup>
              <DropzoneExample files={files} setFiles={setFiles} field="catalogue" multiple={false} />
              <div>
                <div
                  style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}
                >
                  {files.catalogue.map((el, i) => (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px',
                        borderBottom: i === files.catalogue.length - 1 ? 'none' : '1px solid #d8e2ef',
                      }}
                    >
                      <img
                        alt={el.path}
                        src={el.thumbnailUrl ? el.thumbnailUrl : el.base64}
                        style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                      />
                      {showPath && (
                        <div style={{ width: '25vw' }}>
                          <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.path}</p>
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                          {bytesToSize(el.size)}
                        </p>
                        {el.uploaded && el.thumbnailUrl ? (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <FontAwesomeIcon
                              size="sm"
                              icon={faCheck}
                              transform="grow-12 right-6"
                              className="text-success"
                              style={{ margin: '0px 8px' }}
                            />
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                var fl = Object.assign({}, files);
                                fl.catalogue = fl.catalogue.filter((f) => f.id !== el.id);
                                setFiles(fl);
                              }}
                            >
                              <FontAwesomeIcon
                                size="1x"
                                icon={faTimes}
                                transform="grow-12 right-6"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        ) : el.loading ? (
                          <Spinner color="primary" style={{ margin: '0px 8px' }} />
                        ) : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                uploadImage('catalogue', i);
                              }}
                            >
                              <FontAwesomeIcon
                                size="sm"
                                icon={faUpload}
                                transform="grow-12 right-6"
                                className="text-primary"
                              />
                            </div>
                            <div
                              style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                              onClick={() => {
                                var fl = Object.assign({}, files);
                                fl.catalogue = fl.catalogue.filter((f) => f.id !== el.id);
                                setFiles(fl);
                              }}
                            >
                              <FontAwesomeIcon
                                size="1x"
                                icon={faTimes}
                                transform="grow-12 right-6"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ height: '16px' }} />
              </div>
            </Form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addCatalogue}>
            Ok
          </Button>
          <Button color="danger" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex]}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightBoxImages.length)}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
        />
      )}
      <Card>
        <CardBody>
          <h2>Logo</h2>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              alt="cover"
              src={
                files.cover.length === 0
                  ? exampleImage
                  : files.cover[0].url
                  ? files.cover[0].url
                  : files.cover[0].base64
              }
              style={{ width: '800px', height: '256px', objectFit: 'contain' }}
            />
          </div>
          <div style={{ height: '16px' }} />
          <DropzoneExample files={files} setFiles={setFiles} field="cover" multiple={false} />
          <div>
            <div style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}>
              {files.cover.map((el, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px',
                    borderBottom: i === files.cover.length - 1 ? 'none' : '1px solid #d8e2ef',
                  }}
                >
                  <img
                    alt={el.path}
                    src={el.thumbnailUrl ? el.thumbnailUrl : el.base64}
                    style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                  />
                  {showPath && (
                    <div style={{ width: '25vw' }}>
                      <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.path}</p>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                      {bytesToSize(el.size)}
                    </p>
                    {el.uploaded && el.thumbnailUrl ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCheck}
                          transform="grow-12 right-6"
                          className="text-success"
                          style={{ margin: '0px 8px' }}
                        />
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.cover = fl.cover.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    ) : el.loading ? (
                      <Spinner color="primary" style={{ margin: '0px 8px' }} />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            uploadImage('cover', i);
                          }}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faUpload}
                            transform="grow-12 right-6"
                            className="text-primary"
                          />
                        </div>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.cover = fl.cover.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div style={{ height: '16px' }} />
          </div>
          <hr />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>Katalog</h2>
            <Button
              style={{ maxWidth: '120px' }}
              type="button"
              color="primary"
              block
              className="mt-3"
              onClick={() => setOpenModal(true)}
            >
              Tambah Katalog
            </Button>
          </div>
          <div>
            <div style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}>
              {catalogues.map((el, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '8px',
                      borderBottom: i === catalogues.length - 1 ? 'none' : '1px solid #d8e2ef',
                    }}
                  >
                    <img
                      alt={i}
                      src={el.photos.thumbnailUrl}
                      style={{ width: '48px', height: '48px', objectFit: 'cover' }}
                    />
                    {showPath && (
                      <div style={{ width: '25vw' }}>
                        <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.name}</p>
                      </div>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                        Rp. {el.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                      </p>
                      <div
                        style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                        onClick={() => {
                          fetch(`${getHostV2()}/v1/catalogue/${el._id}`, {
                            method: 'DELETE',
                            headers: {
                              Authorization: token,
                              'Content-Type': 'application/json',
                            },
                          })
                            .then(async (result) => {
                              const response = await result.json();
                              if (result.status === 201) {
                                reload();
                              } else {
                                toast.error(response.message);
                              }
                            })
                            .catch((error) => {
                              toast.error(error.toString());
                            });
                        }}
                      >
                        <FontAwesomeIcon size="1x" icon={faTimes} transform="grow-12 right-6" className="text-danger" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ height: '16px' }} />
          </div>
          <hr />
          <h2>Kumpulan Foto</h2>
          <DropzoneExample files={files} setFiles={setFiles} field="photos" multiple={true} />
          <div>
            <div style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}>
              {files.photos.map((el, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px',
                    borderBottom: i === files.photos.length - 1 ? 'none' : '1px solid #d8e2ef',
                  }}
                >
                  <img
                    alt={el.path}
                    src={el.thumbnailUrl ? el.thumbnailUrl : el.base64}
                    style={{ width: '48px', height: '48px', objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => {
                      setIsLightBoxOpen(true);
                      setPhotoIndex(i);
                    }}
                  />
                  {showPath && (
                    <div style={{ width: '25vw' }}>
                      <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.path}</p>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                      {bytesToSize(el.size)}
                    </p>
                    {el.uploaded && el.thumbnailUrl ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCheck}
                          transform="grow-12 right-6"
                          className="text-success"
                          style={{ margin: '0px 8px' }}
                        />
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.photos = fl.photos.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    ) : el.loading ? (
                      <Spinner color="primary" style={{ margin: '0px 8px' }} />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            uploadImage('photos', i);
                          }}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faUpload}
                            transform="grow-12 right-6"
                            className="text-primary"
                          />
                        </div>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.photos = fl.photos.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <h2>Lokasi</h2>
          <Autocomplete
            style={{
              width: '100%',
              display: 'block',
              height: 'calc(1.5em + .625rem + 2px)',
              padding: '.3125rem 1rem',
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5',
              color: '#344050',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              border: '1px solid #d8e2ef',
              borderRadius: '.25rem',
              WebkitTransition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
              transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
            }}
            onPlaceSelected={(place) => {
              const { lat, lng } = place?.geometry?.location;
              setForm({
                ...form,
                latitude: lat(),
                longitude: lng(),
              });
              setDisableMoveMarker(true);
            }}
            types={['establishment']}
            componentRestrictions={{ country: 'id' }}
          />
          <div style={{ height: '16px' }} />
          <MyMapComponentEditor
            refMap={refMap}
            isMarkerShown
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `512px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={form.latitude}
            lng={form.longitude}
            setForm={setForm}
            form={form}
            disableMoveMarker={disableMoveMarker}
            setDisableMoveMarker={setDisableMoveMarker}
          />
        </CardBody>
      </Card>
    </ContentWithAsideLayout>
  );
};

UMKMEditor.propTypes = {
  umkm: PropTypes.object,
  addUMKM: PropTypes.func,
  updateUMKM: PropTypes.func,
};

const mapStateToProps = (state) => ({
  umkm: state.umkm,
});

const mapActionToProps = {
  addUMKM,
  updateUMKM,
};

export default connect(mapStateToProps, mapActionToProps)(UMKMEditor);
