import {
  LOGIN,
  USERS,
  ADD_USER,
  UPDATE_USER,
  DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  AGENDAS,
  ADD_AGENDA,
  UPDATE_AGENDA,
  VEHICLES,
  CARS,
  DRIVERS,
  ADD_TRIP,
  UPDATE_TRIP,
  DELETE_TRIP,
  UMKM,
  ADD_UMKM,
  UPDATE_UMKM,
  ACTIVITIES,
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  MEETING_ROOM,
  ADD_MEETING_ROOM,
  UPDATE_MEETING_ROOM,
  MEETING,
  ADD_MEETING,
  UPDATE_MEETING,
  DOCUMENT_CONFIG,
  ADD_DOCUMENT_CONFIG,
  UPDATE_DOCUMENT_CONFIG,
  DOCUMENT_NUMBER,
  ADD_DOCUMENT_NUMBER,
  UPDATE_DOCUMENT_NUMBER,
  SCREEN_SAVER,
  ADD_SCREEN_SAVER,
  UPDATE_SCREEN_SAVER,
  SCREEN_SAVER_VIDEO,
  ADD_SCREEN_SAVER_VIDEO,
  DELETE_SCREEN_SAVER_VIDEO,
  DELETE_USER,
  DELETE_AGENDA,
  DELETE_UMKM,
  DELETE_ACTIVITY,
  DELETE_MEETING,
  DELETE_DOCUMENT_NUMBER,
  DISPOSITION_HEAD,
  ADD_DISPOSITION_HEAD,
  DELETE_DISPOSITION_HEAD,
  DISPOSITION_UNIT,
  ADD_DISPOSITION_UNIT,
  DELETE_DISPOSITION_UNIT,
  DISPOSITION_ACTION,
  ADD_DISPOSITION_ACTION,
  DELETE_DISPOSITION_ACTION,
  TRIP_REQUEST,
  UPDATE_TRIP_REQUEST,
} from '../../constants/ActionTypes';

// USER
export const login = (payload) => ({
  type: LOGIN,
  payload,
});

export const getUsers = (payload) => ({
  type: USERS,
  payload,
});

export const addUser = (payload) => ({
  type: ADD_USER,
  payload,
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
});

export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload,
});

export const getDrivers = (payload) => ({
  type: DRIVERS,
  payload,
});

// DOCUMENT
export const getDocuments = (payload) => ({
  type: DOCUMENTS,
  payload,
});

export const addDocument = (payload) => ({
  type: ADD_DOCUMENT,
  payload,
});

export const deleteDocument = (payload) => ({
  type: DELETE_DOCUMENT,
  payload,
});

// AGENDA
export const getAgendas = (payload) => ({
  type: AGENDAS,
  payload,
});

export const addAgenda = (payload) => ({
  type: ADD_AGENDA,
  payload,
});

export const updateAgenda = (payload) => ({
  type: UPDATE_AGENDA,
  payload,
});

export const deleteAgenda = (payload) => ({
  type: DELETE_AGENDA,
  payload,
});

// VEHICLE
export const getVehicles = (payload) => ({
  type: VEHICLES,
  payload,
});

export const getCars = (payload) => ({
  type: CARS,
  payload,
});

export const addTrip = (payload) => ({
  type: ADD_TRIP,
  payload,
});

export const updateTrip = (payload) => ({
  type: UPDATE_TRIP,
  payload,
});

export const deleteTrip = (payload) => ({
  type: DELETE_TRIP,
  payload,
});

//UMKM
export const getUMKM = (payload) => ({
  type: UMKM,
  payload,
});

export const addUMKM = (payload) => ({
  type: ADD_UMKM,
  payload,
});

export const updateUMKM = (payload) => ({
  type: UPDATE_UMKM,
  payload,
});

export const deleteUMKM = (payload) => ({
  type: DELETE_UMKM,
  payload,
});

//UMKM
export const getActivities = (payload) => ({
  type: ACTIVITIES,
  payload,
});

export const addActivity = (payload) => ({
  type: ADD_ACTIVITY,
  payload,
});

export const updateActivity = (payload) => ({
  type: UPDATE_ACTIVITY,
  payload,
});

export const deleteActivity = (payload) => ({
  type: DELETE_ACTIVITY,
  payload,
});

//MEETING ROOM
export const getMeetingRooms = (payload) => ({
  type: MEETING_ROOM,
  payload,
});

export const addMeetingRoom = (payload) => ({
  type: ADD_MEETING_ROOM,
  payload,
});

export const updateMeetingRoom = (payload) => ({
  type: UPDATE_MEETING_ROOM,
  payload,
});

//MEETING
export const getMeetings = (payload) => ({
  type: MEETING,
  payload,
});

export const addMeeting = (payload) => ({
  type: ADD_MEETING,
  payload,
});

export const updateMeeting = (payload) => ({
  type: UPDATE_MEETING,
  payload,
});

export const deleteMeeting = (payload) => ({
  type: DELETE_MEETING,
  payload,
});

// DOCUMENT NUMBER
export const getDocumentConfigs = (payload) => ({
  type: DOCUMENT_CONFIG,
  payload,
});

export const addDocumentConfig = (payload) => ({
  type: ADD_DOCUMENT_CONFIG,
  payload,
});

export const updateDocumentConfig = (payload) => ({
  type: UPDATE_DOCUMENT_CONFIG,
  payload,
});

export const getDocumentNumbers = (payload) => ({
  type: DOCUMENT_NUMBER,
  payload,
});

export const addDocumentNumber = (payload) => ({
  type: ADD_DOCUMENT_NUMBER,
  payload,
});

export const updateDocumentNumber = (payload) => ({
  type: UPDATE_DOCUMENT_NUMBER,
  payload,
});

export const deleteDocumentNumber = (payload) => ({
  type: DELETE_DOCUMENT_NUMBER,
  payload,
});

// SCREENSAVER
export const getScreensaver = (payload) => ({
  type: SCREEN_SAVER,
  payload,
});

export const addScreensaver = (payload) => ({
  type: ADD_SCREEN_SAVER,
  payload,
});

export const updateScreensaver = (payload) => ({
  type: UPDATE_SCREEN_SAVER,
  payload,
});

// SCREENSAVER VIDEO
export const getScreensaverVideo = (payload) => ({
  type: SCREEN_SAVER_VIDEO,
  payload,
});

export const addScreensaverVideo = (payload) => ({
  type: ADD_SCREEN_SAVER_VIDEO,
  payload,
});

export const deleteScreensaverVideo = (payload) => ({
  type: DELETE_SCREEN_SAVER_VIDEO,
  payload,
});

// DISPOSITIONHEAD
export const getDispositionHead = (payload) => ({
  type: DISPOSITION_HEAD,
  payload,
});

export const addDispositionHead = (payload) => ({
  type: ADD_DISPOSITION_HEAD,
  payload,
});

export const deleteDispositionHead = (payload) => ({
  type: DELETE_DISPOSITION_HEAD,
  payload,
});

// DISPOSITIONUNIT
export const getDispositionUnit = (payload) => ({
  type: DISPOSITION_UNIT,
  payload,
});

export const addDispositionUnit = (payload) => ({
  type: ADD_DISPOSITION_UNIT,
  payload,
});

export const deleteDispositionUnit = (payload) => ({
  type: DELETE_DISPOSITION_UNIT,
  payload,
});

// DISPOSITIONACTION
export const getDispositionAction = (payload) => ({
  type: DISPOSITION_ACTION,
  payload,
});

export const addDispositionAction = (payload) => ({
  type: ADD_DISPOSITION_ACTION,
  payload,
});

export const deleteDispositionAction = (payload) => ({
  type: DELETE_DISPOSITION_ACTION,
  payload,
});

// TRIP REQUEST
export const getTripRequest = (payload) => ({
  type: TRIP_REQUEST,
  payload,
});

export const updateTripRequest = (payload) => ({
  type: UPDATE_TRIP_REQUEST,
  payload,
});
