import {
  MEETING_ROOM,
  MEETING_ROOM_SUCCESS,
  MEETING_ROOM_ERROR,
  ADD_MEETING_ROOM,
  ADD_MEETING_ROOM_SUCCESS,
  ADD_MEETING_ROOM_ERROR,
  UPDATE_MEETING_ROOM,
  UPDATE_MEETING_ROOM_SUCCESS,
  UPDATE_MEETING_ROOM_ERROR,
  MEETING,
  MEETING_SUCCESS,
  MEETING_ERROR,
  ADD_MEETING,
  ADD_MEETING_SUCCESS,
  ADD_MEETING_ERROR,
  UPDATE_MEETING,
  UPDATE_MEETING_SUCCESS,
  UPDATE_MEETING_ERROR,
  DELETE_MEETING,
  DELETE_MEETING_SUCCESS,
  DELETE_MEETING_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    meetingRooms: [],
    meetingRoomsLoading: false,
    addMeetingRoomLoading: false,
    updateMeetingRoomLoading: false,
    meetings: [],
    meetingsLoading: false,
    addMeetingLoading: false,
    updateMeetingLoading: false,
    deleteMeetingLoading: false,
  },
  action
) => {
  switch (action.type) {
    case MEETING_ROOM:
      return {
        ...state,
        meetingRoomsLoading: true,
      };
    case MEETING_ROOM_SUCCESS:
      return {
        ...state,
        meetingRooms: action.payload,
        meetingRoomsLoading: false,
      };
    case MEETING_ROOM_ERROR:
      return {
        ...state,
        meetingRoomsLoading: false,
      };

    case ADD_MEETING_ROOM:
      return {
        ...state,
        addMeetingRoomLoading: true,
      };
    case ADD_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        addMeetingRoomLoading: false,
      };
    case ADD_MEETING_ROOM_ERROR:
      return {
        ...state,
        addMeetingRoomLoading: false,
      };

    case UPDATE_MEETING_ROOM:
      return {
        ...state,
        updateMeetingRoomLoading: true,
      };
    case UPDATE_MEETING_ROOM_SUCCESS:
      return {
        ...state,
        updateMeetingRoomLoading: false,
      };
    case UPDATE_MEETING_ROOM_ERROR:
      return {
        ...state,
        updateMeetingRoomLoading: false,
      };

    case MEETING:
      return {
        ...state,
        meetingsLoading: true,
      };
    case MEETING_SUCCESS:
      return {
        ...state,
        meetings: action.payload,
        meetingsLoading: false,
      };
    case MEETING_ERROR:
      return {
        ...state,
        meetingsLoading: false,
      };

    case ADD_MEETING:
      return {
        ...state,
        addMeetingLoading: true,
      };
    case ADD_MEETING_SUCCESS:
      return {
        ...state,
        addMeetingLoading: false,
      };
    case ADD_MEETING_ERROR:
      return {
        ...state,
        addMeetingLoading: false,
      };

    case UPDATE_MEETING:
      return {
        ...state,
        updateMeetingLoading: true,
      };
    case UPDATE_MEETING_SUCCESS:
      return {
        ...state,
        updateMeetingLoading: false,
      };
    case UPDATE_MEETING_ERROR:
      return {
        ...state,
        updateMeetingLoading: false,
      };

    case DELETE_MEETING:
      return {
        ...state,
        deleteMeetingLoading: true,
      };
    case DELETE_MEETING_SUCCESS:
      return {
        ...state,
        deleteMeetingLoading: false,
      };
    case DELETE_MEETING_ERROR:
      return {
        ...state,
        deleteMeetingLoading: false,
      };

    default:
      return state;
  }
};
