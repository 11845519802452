import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Table,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas';
import { useForm } from 'react-hook-form';

import { getDocuments, addDocument, deleteDocument } from '../../redux/actions';

const Documents = ({ documentProp, getDocuments, addDocument, deleteDocument }) => {
  const [token, setToken] = useState(null);
  const { documents, addDocumentLoading } = documentProp;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    originalDocument: [],
    documentIn: true,
    documentType: '',
    documentNumber: '',
    documentDate: new Date(),
    name: '',
    sender: '',
    documentReceiveDate: new Date(),
    documentTo: '',
    documentFrom: '',
    documentAdditionalInformation: '',
    numerator: '',
    documentLink: '',
    other: false,
  });
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState(null);
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getDocuments({ token: JSON.parse(data).data.token });
      }
    };
    getUserData();
  }, [getDocuments, token]);

  const onChangeInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      originalDocument: [],
      documentIn: true,
      documentType: '',
      documentNumber: '',
      documentDate: new Date(),
      name: '',
      sender: '',
      documentReceiveDate: new Date(),
      documentTo: '',
      documentFrom: '',
      documentAdditionalInformation: '',
      numerator: '',
      documentLink: '',
      other: false,
    });
  };

  const onSubmit = (data) => {
    if (mode === 'Tambah') {
      return addDocument({
        ...form,
        documentIn: form.documentIn,
        documentType: form.documentType,
        date: form.documentDate,
        dateReceive: form.documentReceiveDate,
        to: form.documentTo,
        from: form.documentFrom,
        additional: form.documentAdditionalInformation,
        numerator: form.numerator,
        documentLink: form.documentLink,
        ...data,
        resetForm,
        getDocuments,
        token,
      });
    }
  };

  const openQrCodeModal = (v) => {
    setQrCodeModal(true);
    setQrCodeValue(JSON.stringify(v));
  };

  const closeQrCodeModal = () => {
    setQrCodeModal(false);
    setQrCodeValue(null);
  };

  return (
    <Card>
      <Modal isOpen={qrCodeModal} toggle={closeQrCodeModal}>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode size={256} value={qrCodeValue ? qrCodeValue : ''} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="primary"
              onClick={() => {
                html2canvas(document.querySelector('#react-qrcode-logo')).then((canvas) => {
                  const link = document.createElement('a');
                  link.download = `document_qrcode_${moment().unix()}.png`;
                  link.href = canvas.toDataURL();
                  link.click();
                });
              }}
            >
              <FontAwesomeIcon icon={faDownload} /> Download
            </Button>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeQrCodeModal} color="primary">
            Ok
          </Button>
          <Button onClick={closeQrCodeModal} color="danger">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <CardBody>
        {mode !== 'Update' && (
          <>
            <Button
              onClick={() => {
                setShowForm(!showForm);
                setMode('Tambah');
              }}
              color="primary"
            >
              Tambah Dokumen
            </Button>
            <div style={{ height: '16px' }} />
          </>
        )}
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="documentIn">Dokumen</Label>
              <Input
                value={form.documentIn}
                type="select"
                name="documentIn"
                id="documentIn"
                onChange={() => {
                  setForm({
                    ...form,
                    documentIn: !form.documentIn,
                  });
                }}
              >
                <option value={true}>Masuk</option>
                <option value={false}>Keluar</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="documentType">
                Tipe Dokumen<span style={{ color: 'red' }}>*</span>
              </Label>
              <Input
                value={form.documentType}
                type="select"
                name="documentType"
                id="documentType"
                onChange={(e) => {
                  if (e.target.value === 'Others') {
                    return setForm({
                      ...form,
                      documentType: '',
                      other: true,
                    });
                  }
                  setForm({
                    ...form,
                    documentType: e.target.value,
                    other: false,
                  });
                }}
              >
                <option value="">Pilih Tipe Dokumen</option>
                <option value="M01">M01</option>
                <option value="M02">M02</option>
                <option value="Surat">Surat</option>
                <option value="Faximile">Faximile</option>
                <option value="LDP">LDP</option>
                <option value="Berita Acara">Berita Acara</option>
                <option value="Others">Lainnya</option>
              </Input>
            </FormGroup>
            {form.other && (
              <FormGroup>
                <Input
                  value={form.documentType}
                  type="text"
                  name="documentType"
                  id="documentType2"
                  onChange={onChangeInput.bind(this)}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="documentNumber">
                Nomor Dokumen<span style={{ color: 'red' }}>*</span>{' '}
                {errors.documentNumber && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.documentNumber}
                type="text"
                name="documentNumber"
                id="documentNumber"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="numerator">Numerator</Label>
              <Input
                value={form.numerator}
                type="text"
                name="numerator"
                id="numerator"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="documentLink">Link Dokumen</Label>
              <Input
                value={form.documentLink}
                type="text"
                name="documentLink"
                id="documentLink"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Tanggal Dokumen</Label>
              <br />
              <DatePicker
                format="D MMMM YYYY"
                value={form.documentDate}
                onChange={(date) => {
                  setForm({
                    ...form,
                    documentDate: new Date(date.toISOString()),
                  });
                }}
                animateYearScrolling
                inputVariant="outlined"
                autoOk
              />
            </FormGroup>
            <FormGroup>
              <Label for="name">
                Perihal<span style={{ color: 'red' }}>*</span>{' '}
                {errors.name && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.name}
                type="text"
                name="name"
                id="name"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="sender">
                Pengirim<span style={{ color: 'red' }}>*</span>{' '}
                {errors.sender && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.sender}
                type="text"
                name="sender"
                id="sender"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label>Tanggal Terima</Label>
              <br />
              <DatePicker
                format="D MMMM YYYY"
                value={form.documentReceiveDate}
                onChange={(date) => {
                  setForm({
                    ...form,
                    documentReceiveDate: new Date(date.toISOString()),
                  });
                }}
                animateYearScrolling
                inputVariant="outlined"
                autoOk
              />
            </FormGroup>
            {form.documentIn ? (
              <FormGroup>
                <Label for="documentFrom">Dari</Label>
                <Input
                  value={form.documentFrom}
                  type="select"
                  name="documentFrom"
                  id="documentFrom"
                  onChange={onChangeInput.bind(this)}
                >
                  <option value="">Dari</option>
                  <option value="Kepala Perwakilan">Kepala Perwakilan</option>
                  <option value="Deputi Kepala Perwakilan">Deputi Kepala Perwakilan</option>
                  <option value="Kepala Tim">Kepala Tim</option>
                  {/* <option value="TIM ADVISORY EKONOMI DAN KEUANGAN">TIM ADVISORY EKONOMI DAN KEUANGAN</option>
                  <option value="TIM PENGEMBANGAN EKONOMI">TIM PENGEMBANGAN EKONOMI</option>
                  <option value="TIM PUR DAN OPERASIONAL SP">TIM PUR DAN OPERASIONAL SP</option> */}
                </Input>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label for="documentTo">Kepada</Label>
                <Input
                  value={form.documentTo}
                  type="text"
                  name="documentTo"
                  id="documentTo"
                  onChange={onChangeInput.bind(this)}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label for="documentAdditionalInformation">Informasi Tambahan</Label>
              <Input
                value={form.documentAdditionalInformation}
                type="text"
                name="documentAdditionalInformation"
                id="documentAdditionalInformation"
                onChange={onChangeInput.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <Button disabled={addDocumentLoading} type="submit" color="primary" block className="mt-3">
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>ID Dokumen</th>
                <th>Nomor Dokumen</th>
                <th>Dokumen</th>
                <th>Numerator</th>
                <th>Tipe Dokumen</th>
                <th>Tanggal Dokumen</th>
                <th>Pengirim/Pembuat</th>
                <th>Tanggal Terima/Buat</th>
                <th>Perihal</th>
                <th>Kepada</th>
                <th>Informasi Tambahan</th>
                <th>Dari</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {documents.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>{el.id}</td>
                  <td>{el.documentNumber}</td>
                  <td>{el.in}</td>
                  <td>{el.numerator}</td>
                  <td>{el.documentType}</td>
                  <td>{moment(el.date).format('D MMMM YYYY')}</td>
                  <td>{el.sender}</td>
                  <td>{moment(el.dateReceive).format('D MMMM YYYY')}</td>
                  <td>{el.name}</td>
                  <td>{el.to}</td>
                  <td>{el.additional}</td>
                  <td>{el.from}</td>
                  <td>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faQrcode}
                        transform="grow-12 right-6"
                        className="text-success"
                        onClick={() =>
                          openQrCodeModal({
                            id: el.id,
                            name: el.name,
                          })
                        }
                      />
                      <div style={{ width: '16px' }} />
                      <FontAwesomeIcon
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        icon={faTrash}
                        transform="grow-12 right-6"
                        className="text-danger"
                        onClick={() => deleteDocument({ id: el.id, getDocuments, token })}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Documents.propTypes = {
  documentProp: PropTypes.object,
  getDocuments: PropTypes.func,
  addDocument: PropTypes.func,
  deleteDocument: PropTypes.func,
};

const mapStateToProps = (state) => ({
  documentProp: state.document,
});

const mapActionToProps = {
  getDocuments,
  addDocument,
  deleteDocument,
};

export default connect(mapStateToProps, mapActionToProps)(Documents);
