export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const USERS = 'USERS';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_ERROR = 'USERS_ERROR';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const DOCUMENTS = 'DOCUMENTS';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const AGENDAS = 'AGENDAS';
export const AGENDAS_SUCCESS = 'AGENDAS_SUCCESS';
export const AGENDAS_ERROR = 'AGENDAS_ERROR';

export const ADD_AGENDA = 'ADD_AGENDA';
export const ADD_AGENDA_SUCCESS = 'ADD_AGENDA_SUCCESS';
export const ADD_AGENDA_ERROR = 'ADD_AGENDA_ERROR';

export const UPDATE_AGENDA = 'UPDATE_AGENDA';
export const UPDATE_AGENDA_SUCCESS = 'UPDATE_AGENDA_SUCCESS';
export const UPDATE_AGENDA_ERROR = 'UPDATE_AGENDA_ERROR';

export const DELETE_AGENDA = 'DELETE_AGENDA';
export const DELETE_AGENDA_SUCCESS = 'DELETE_AGENDA_SUCCESS';
export const DELETE_AGENDA_ERROR = 'DELETE_AGENDA_ERROR';

export const VEHICLES = 'VEHICLES';
export const VEHICLES_SUCCESS = 'VEHICLES_SUCCESS';
export const VEHICLES_ERROR = 'VEHICLES_ERROR';

export const CARS = 'CARS';
export const CARS_SUCCESS = 'CARS_SUCCESS';
export const CARS_ERROR = 'CARS_ERROR';

export const DRIVERS = 'DRIVERS';
export const DRIVERS_SUCCESS = 'DRIVERS_SUCCESS';
export const DRIVERS_ERROR = 'DRIVERS_ERROR';

export const ADD_TRIP = 'ADD_TRIP';
export const ADD_TRIP_SUCCESS = 'ADD_TRIP_SUCCESS';
export const ADD_TRIP_ERROR = 'ADD_TRIP_ERROR';

export const UPDATE_TRIP = 'UPDATE_TRIP';
export const UPDATE_TRIP_SUCCESS = 'UPDATE_TRIP_SUCCESS';
export const UPDATE_TRIP_ERROR = 'UPDATE_TRIP_ERROR';

export const DELETE_TRIP = 'DELETE_TRIP';
export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS';
export const DELETE_TRIP_ERROR = 'DELETE_TRIP_ERROR';

export const UMKM = 'UMKM';
export const UMKM_SUCCESS = 'UMKM_SUCCESS';
export const UMKM_ERROR = 'UMKM_ERROR';

export const ADD_UMKM = 'ADD_UMKM';
export const ADD_UMKM_SUCCESS = 'ADD_UMKM_SUCCESS';
export const ADD_UMKM_ERROR = 'ADD_UMKM_ERROR';

export const UPDATE_UMKM = 'UPDATE_UMKM';
export const UPDATE_UMKM_SUCCESS = 'UPDATE_UMKM_SUCCESS';
export const UPDATE_UMKM_ERROR = 'UPDATE_UMKM_ERROR';

export const DELETE_UMKM = 'DELETE_UMKM';
export const DELETE_UMKM_SUCCESS = 'DELETE_UMKM_SUCCESS';
export const DELETE_UMKM_ERROR = 'DELETE_UMKM_ERROR';

export const ACTIVITIES = 'ACTIVITIES';
export const ACTIVITIES_SUCCESS = 'ACTIVITIES_SUCCESS';
export const ACTIVITIES_ERROR = 'ACTIVITIES_ERROR';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS';
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY';
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_ERROR = 'UPDATE_ACTIVITY_ERROR';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';

export const MEETING_ROOM = 'MEETING_ROOM';
export const MEETING_ROOM_SUCCESS = 'MEETING_ROOM_SUCCESS';
export const MEETING_ROOM_ERROR = 'MEETING_ROOM_ERROR';

export const ADD_MEETING_ROOM = 'ADD_MEETING_ROOM';
export const ADD_MEETING_ROOM_SUCCESS = 'ADD_MEETING_ROOM_SUCCESS';
export const ADD_MEETING_ROOM_ERROR = 'ADD_MEETING_ROOM_ERROR';

export const UPDATE_MEETING_ROOM = 'UPDATE_MEETING_ROOM';
export const UPDATE_MEETING_ROOM_SUCCESS = 'UPDATE_MEETING_ROOM_SUCCESS';
export const UPDATE_MEETING_ROOM_ERROR = 'UPDATE_MEETING_ROOM_ERROR';

export const MEETING = 'MEETING';
export const MEETING_SUCCESS = 'MEETING_SUCCESS';
export const MEETING_ERROR = 'MEETING_ERROR';

export const ADD_MEETING = 'ADD_MEETING';
export const ADD_MEETING_SUCCESS = 'ADD_MEETING_SUCCESS';
export const ADD_MEETING_ERROR = 'ADD_MEETING_ERROR';

export const UPDATE_MEETING = 'UPDATE_MEETING';
export const UPDATE_MEETING_SUCCESS = 'UPDATE_MEETING_SUCCESS';
export const UPDATE_MEETING_ERROR = 'UPDATE_MEETING_ERROR';

export const DELETE_MEETING = 'DELETE_MEETING';
export const DELETE_MEETING_SUCCESS = 'DELETE_MEETING_SUCCESS';
export const DELETE_MEETING_ERROR = 'DELETE_MEETING_ERROR';

export const DOCUMENT_CONFIG = 'DOCUMENT_CONFIG';
export const DOCUMENT_CONFIG_SUCCESS = 'DOCUMENT_CONFIG_SUCCESS';
export const DOCUMENT_CONFIG_ERROR = 'DOCUMENT_CONFIG_ERROR';

export const ADD_DOCUMENT_CONFIG = 'ADD_DOCUMENT_CONFIG';
export const ADD_DOCUMENT_CONFIG_SUCCESS = 'ADD_DOCUMENT_CONFIG_SUCCESS';
export const ADD_DOCUMENT_CONFIG_ERROR = 'ADD_DOCUMENT_CONFIG_ERROR';

export const UPDATE_DOCUMENT_CONFIG = 'UPDATE_DOCUMENT_CONFIG';
export const UPDATE_DOCUMENT_CONFIG_SUCCESS = 'UPDATE_DOCUMENT_CONFIG_SUCCESS';
export const UPDATE_DOCUMENT_CONFIG_ERROR = 'UPDATE_DOCUMENT_CONFIG_ERROR';

export const DOCUMENT_NUMBER = 'DOCUMENT_NUMBER';
export const DOCUMENT_NUMBER_SUCCESS = 'DOCUMENT_NUMBER_SUCCESS';
export const DOCUMENT_NUMBER_ERROR = 'DOCUMENT_NUMBER_ERROR';

export const ADD_DOCUMENT_NUMBER = 'ADD_DOCUMENT_NUMBER';
export const ADD_DOCUMENT_NUMBER_SUCCESS = 'ADD_DOCUMENT_NUMBER_SUCCESS';
export const ADD_DOCUMENT_NUMBER_ERROR = 'ADD_DOCUMENT_NUMBER_ERROR';

export const UPDATE_DOCUMENT_NUMBER = 'UPDATE_DOCUMENT_NUMBER';
export const UPDATE_DOCUMENT_NUMBER_SUCCESS = 'UPDATE_DOCUMENT_NUMBER_SUCCESS';
export const UPDATE_DOCUMENT_NUMBER_ERROR = 'UPDATE_DOCUMENT_NUMBER_ERROR';

export const DELETE_DOCUMENT_NUMBER = 'DELETE_DOCUMENT_NUMBER';
export const DELETE_DOCUMENT_NUMBER_SUCCESS = 'DELETE_DOCUMENT_NUMBER_SUCCESS';
export const DELETE_DOCUMENT_NUMBER_ERROR = 'DELETE_DOCUMENT_NUMBER_ERROR';

export const SCREEN_SAVER = 'SCREEN_SAVER';
export const SCREEN_SAVER_SUCCESS = 'SCREEN_SAVER_SUCCESS';
export const SCREEN_SAVER_ERROR = 'SCREEN_SAVER_ERROR';

export const ADD_SCREEN_SAVER = 'ADD_SCREEN_SAVER';
export const ADD_SCREEN_SAVER_SUCCESS = 'ADD_SCREEN_SAVER_SUCCESS';
export const ADD_SCREEN_SAVER_ERROR = 'ADD_SCREEN_SAVER_ERROR';

export const UPDATE_SCREEN_SAVER = 'UPDATE_SCREEN_SAVER';
export const UPDATE_SCREEN_SAVER_SUCCESS = 'UPDATE_SCREEN_SAVER_SUCCESS';
export const UPDATE_SCREEN_SAVER_ERROR = 'UPDATE_SCREEN_SAVER_ERROR';

export const SCREEN_SAVER_VIDEO = 'SCREEN_SAVER_VIDEO';
export const SCREEN_SAVER_VIDEO_SUCCESS = 'SCREEN_SAVER_VIDEO_SUCCESS';
export const SCREEN_SAVER_VIDEO_ERROR = 'SCREEN_SAVER_VIDEO_ERROR';

export const ADD_SCREEN_SAVER_VIDEO = 'ADD_SCREEN_SAVER_VIDEO';
export const ADD_SCREEN_SAVER_VIDEO_SUCCESS = 'ADD_SCREEN_SAVER_VIDEO_SUCCESS';
export const ADD_SCREEN_SAVER_VIDEO_ERROR = 'ADD_SCREEN_SAVER_VIDEO_ERROR';

export const DELETE_SCREEN_SAVER_VIDEO = 'DELETE_SCREEN_SAVER_VIDEO';
export const DELETE_SCREEN_SAVER_VIDEO_SUCCESS = 'DELETE_SCREEN_SAVER_VIDEO_SUCCESS';
export const DELETE_SCREEN_SAVER_VIDEO_ERROR = 'DELETE_SCREEN_SAVER_VIDEO_ERROR';

export const DISPOSITION_HEAD = 'DISPOSITION_HEAD';
export const DISPOSITION_HEAD_SUCCESS = 'DISPOSITION_HEAD_SUCCESS';
export const DISPOSITION_HEAD_ERROR = 'DISPOSITION_HEAD_ERROR';

export const ADD_DISPOSITION_HEAD = 'ADD_DISPOSITION_HEAD';
export const ADD_DISPOSITION_HEAD_SUCCESS = 'ADD_DISPOSITION_HEAD_SUCCESS';
export const ADD_DISPOSITION_HEAD_ERROR = 'ADD_DISPOSITION_HEAD_ERROR';

export const UPDATE_DISPOSITION_HEAD = 'UPDATE_DISPOSITION_HEAD';
export const UPDATE_DISPOSITION_HEAD_SUCCESS = 'UPDATE_DISPOSITION_HEAD_SUCCESS';
export const UPDATE_DISPOSITION_HEAD_ERROR = 'UPDATE_DISPOSITION_HEAD_ERROR';

export const DELETE_DISPOSITION_HEAD = 'DELETE_DISPOSITION_HEAD';
export const DELETE_DISPOSITION_HEAD_SUCCESS = 'DELETE_DISPOSITION_HEAD_SUCCESS';
export const DELETE_DISPOSITION_HEAD_ERROR = 'DELETE_DISPOSITION_HEAD_ERROR';

export const DISPOSITION_UNIT = 'DISPOSITION_UNIT';
export const DISPOSITION_UNIT_SUCCESS = 'DISPOSITION_UNIT_SUCCESS';
export const DISPOSITION_UNIT_ERROR = 'DISPOSITION_UNIT_ERROR';

export const ADD_DISPOSITION_UNIT = 'ADD_DISPOSITION_UNIT';
export const ADD_DISPOSITION_UNIT_SUCCESS = 'ADD_DISPOSITION_UNIT_SUCCESS';
export const ADD_DISPOSITION_UNIT_ERROR = 'ADD_DISPOSITION_UNIT_ERROR';

export const UPDATE_DISPOSITION_UNIT = 'UPDATE_DISPOSITION_UNIT';
export const UPDATE_DISPOSITION_UNIT_SUCCESS = 'UPDATE_DISPOSITION_UNIT_SUCCESS';
export const UPDATE_DISPOSITION_UNIT_ERROR = 'UPDATE_DISPOSITION_UNIT_ERROR';

export const DELETE_DISPOSITION_UNIT = 'DELETE_DISPOSITION_UNIT';
export const DELETE_DISPOSITION_UNIT_SUCCESS = 'DELETE_DISPOSITION_UNIT_SUCCESS';
export const DELETE_DISPOSITION_UNIT_ERROR = 'DELETE_DISPOSITION_UNIT_ERROR';

export const DISPOSITION_ACTION = 'DISPOSITION_ACTION';
export const DISPOSITION_ACTION_SUCCESS = 'DISPOSITION_ACTION_SUCCESS';
export const DISPOSITION_ACTION_ERROR = 'DISPOSITION_ACTION_ERROR';

export const ADD_DISPOSITION_ACTION = 'ADD_DISPOSITION_ACTION';
export const ADD_DISPOSITION_ACTION_SUCCESS = 'ADD_DISPOSITION_ACTION_SUCCESS';
export const ADD_DISPOSITION_ACTION_ERROR = 'ADD_DISPOSITION_ACTION_ERROR';

export const UPDATE_DISPOSITION_ACTION = 'UPDATE_DISPOSITION_ACTION';
export const UPDATE_DISPOSITION_ACTION_SUCCESS = 'UPDATE_DISPOSITION_ACTION_SUCCESS';
export const UPDATE_DISPOSITION_ACTION_ERROR = 'UPDATE_DISPOSITION_ACTION_ERROR';

export const DELETE_DISPOSITION_ACTION = 'DELETE_DISPOSITION_ACTION';
export const DELETE_DISPOSITION_ACTION_SUCCESS = 'DELETE_DISPOSITION_ACTION_SUCCESS';
export const DELETE_DISPOSITION_ACTION_ERROR = 'DELETE_DISPOSITION_ACTION_ERROR';

export const TRIP_REQUEST = 'TRIP_REQUEST';
export const TRIP_REQUEST_SUCCESS = 'TRIP_REQUEST_SUCCESS';
export const TRIP_REQUEST_ERROR = 'TRIP_REQUEST_ERROR';

export const UPDATE_TRIP_REQUEST = 'UPDATE_TRIP_REQUEST';
export const UPDATE_TRIP_REQUEST_SUCCESS = 'UPDATE_TRIP_REQUEST_SUCCESS';
export const UPDATE_TRIP_REQUEST_ERROR = 'UPDATE_TRIP_REQUEST_ERROR';
