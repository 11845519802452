import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHost } from '../../constants';
import {
  ACTIVITIES_SUCCESS,
  ACTIVITIES_ERROR,
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_ERROR,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_ERROR,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_ERROR,
} from '../../constants/ActionTypes';

export function* fetchActivities(params) {
  const { token } = params.payload;
  const result = yield fetch(`${getHost()}/api/event`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: ACTIVITIES_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: ACTIVITIES_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ACTIVITIES_ERROR };
    });
  yield put(result);
}

export function* createActivity(params) {
  const { name, location, description, datetime, cover, photos, resetForm, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/event`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      location,
      description,
      datetime,
      cover,
      photos,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        window.scrollTo(0, 0);
        toast.success('Berhasil menambahkan kegiatan');
        return { type: ADD_ACTIVITY_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: ADD_ACTIVITY_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: ADD_ACTIVITY_ERROR };
    });
  yield put(result);
}

export function* updateActivity(params) {
  const { id, name, location, description, datetime, cover, photos, token } = params.payload;
  const result = yield fetch(`${getHost()}/api/event/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      name,
      location,
      description,
      datetime,
      cover,
      photos,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        toast.success('Berhasil memperbarui kegiatan');
        return { type: UPDATE_ACTIVITY_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_ACTIVITY_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_ACTIVITY_ERROR };
    });
  yield put(result);
}

export function* deleteActivity(params) {
  const { id, token, getActivities } = params.payload;
  const result = yield fetch(`${getHost()}/api/event/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        getActivities({ token });
        toast.success('Berhasil menghapus event');
        return { type: DELETE_ACTIVITY_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: DELETE_ACTIVITY_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: DELETE_ACTIVITY_ERROR };
    });
  yield put(result);
}
