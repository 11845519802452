import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Table, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Switch from 'react-switch';
import moment from 'moment';

import { getTripRequest, updateTripRequest, getUsers } from '../../redux/actions';

const Trip = ({ triprequest, user, getTripRequest, updateTripRequest }) => {
  const [token, setToken] = useState(null);
  const { tripRequest, tripRequestLoading, updateTripRequestLoading } = triprequest;
  const { users } = user;
  const [showForm, setShowForm] = useState(false);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    status: '',
    car: '',
    driver: ''
  });
  const { register, handleSubmit, errors } = useForm();
  const documentTypes = [
    'M01',
    'M02',
    'Surat',
    'Faksimili',
    'Berita Acara',
    'Perjanjian',
    'Risalah Rapat',
    'Pengumuman ',
    'Sertifikat',
    'Surat Kuasa',
    'UAM',
    'CBS'
  ];
  const statuses = ['pending', 'accepted', 'rejected', 'canceled'];

  useEffect(() => {
    const getUserData = async () => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getTripRequest({ token: JSON.parse(data).data.token, status: statuses });
      }
    };
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTripRequest]);

  const resetForm = () => {
    setShowForm(false);
    setMode(null);
    setForm({
      id: null,
      status: '',
      car: '',
      driver: ''
    });
  };

  // const setFilledForm = (v) => {
  //   window.scrollTo(0, 0);
  //   setShowForm(true);
  //   setMode('Update');
  //   setForm({
  //     id: v._id,
  //     subject: v.subject,
  //     documentType: v.documentType,
  //     to: v.to,
  //     creator: v.creator._id,
  //     published: v.published,
  //     documentNumber: v.documentNumber,
  //   });
  // };

  const reload = () => {
    getTripRequest({ token, status: statuses });
  };

  const onSubmit = data => {
    updateTripRequest({
      ...form,
      ...data,
      resetForm,
      reload,
      token
    });
  };

  console.log(tripRequest);
  return (
    <Card>
      <CardBody>
        {showForm && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="documentType">
                Tipe Dokumen<span style={{ color: 'red' }}>*</span>{' '}
                {errors.documentType && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.documentType}
                type="select"
                name="documentType"
                id="documentType"
                innerRef={register({ required: true })}
              >
                <option value="">Pilih Tipe Dokumen</option>
                {documentTypes.map(el => (
                  <option key={el} value={el}>
                    {el}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="subject">
                Perihal<span style={{ color: 'red' }}>*</span>{' '}
                {errors.subject && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input
                defaultValue={form.subject}
                type="text"
                name="subject"
                id="subject"
                innerRef={register({ required: true })}
              />
            </FormGroup>
            <FormGroup>
              <Label for="to">
                Kepada<span style={{ color: 'red' }}>*</span>{' '}
                {errors.to && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
              </Label>
              <Input defaultValue={form.to} type="text" name="to" id="to" innerRef={register({ required: true })} />
            </FormGroup>
            {mode === 'Update' && (
              <FormGroup>
                <Label for="creator">
                  Pengguna<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.creator && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.creator}
                  type="select"
                  name="creator"
                  id="creator"
                  innerRef={register({ required: true })}
                >
                  {users.map(el => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}
            {mode === 'Update' && (
              <FormGroup check>
                <Label>Published</Label>
                <br />
                <Switch onChange={e => setForm({ ...form, published: e })} checked={form.published} />
              </FormGroup>
            )}
            <FormGroup>
              <Button
                disabled={tripRequestLoading || updateTripRequestLoading}
                type="submit"
                color="primary"
                block
                className="mt-3"
              >
                {mode}
              </Button>
            </FormGroup>
            <Button color="danger" block className="mt-3" onClick={resetForm.bind(this)}>
              Batal
            </Button>
            <div style={{ height: '16px' }} />
          </Form>
        )}
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>Tipe Dokumen</th>
                <th>Tanggal</th>
                <th>Nomor Dokumen</th>
                <th>Perihal</th>
                <th>Kepada</th>
                <th>Pengguna</th>
                <th>Terpublish</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tripRequest.map((el, i) => (
                <tr key={el._id}>
                  <th>{i + 1}</th>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

Trip.propTypes = {
  triprequest: PropTypes.object,
  user: PropTypes.object,
  getTripRequest: PropTypes.func,
  updateTripRequest: PropTypes.func,
  getUsers: PropTypes.func
};

const mapStateToProps = state => ({
  triprequest: state.triprequest,
  user: state.user
});

const mapActionToProps = {
  getTripRequest,
  updateTripRequest,
  getUsers
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Trip);
