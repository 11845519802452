import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import PrivateRoute from './PrivateRoute';

import Users from './pages/Users';
import Documents from './pages/Documents';
import Agendas from './pages/Agendas';
import Reports from './pages/Reports';
import Vehicles from './pages/Vehicles';
import UMKM from './pages/UMKM';
import UMKMEditor from './pages/UMKMEditor';
import Activities from './pages/Activities';
import ActivityEditor from './pages/ActivityEditor';
import Meetings from './pages/Meetings';
import MeetingRooms from './pages/MeetingRooms';
import DocumentNumber from './pages/DocumentNumber';
import DocumentNumberSeting from './pages/DocumentNumberSeting';
import Screensaver from './pages/Screensaver';
import ScreensaverEditor from './pages/ScreensaverEditor';
import DocumentNumberReport from './pages/DocumentNumberReport';
import DispositionHead from './pages/disposition/Head';
import DispositionUnit from './pages/disposition/Unit';
import DispositionAction from './pages/disposition/Action';
import Trip from './pages/Trip';

const HomeComponent = () => {
  return (
    <div>
      <h1>Selamat Datang di WebDashboard GURINDAM</h1>
    </div>
  );
};

const DashboardLayout = ({ location }) => {
  const { isFluid, isTopNav, navbarStyle } = useContext(AppContext);

  const isKanban = getPageName('kanban');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
      {!isTopNav && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            <PrivateRoute path="/" exact Component={HomeComponent} />
            <PrivateRoute path="/users" exact Component={Users} />
            <PrivateRoute path="/documents" exact Component={Documents} />
            <PrivateRoute path="/agendas" exact Component={Agendas} />
            <PrivateRoute path="/reports" exact Component={Reports} />
            <PrivateRoute path="/vehicles" exact Component={Vehicles} />
            <PrivateRoute path="/maps" exact Component={UMKM} />
            <PrivateRoute path="/maps/editor" exact Component={UMKMEditor} />
            <PrivateRoute path="/activity" exact Component={Activities} />
            <PrivateRoute path="/activity/editor" exact Component={ActivityEditor} />
            <PrivateRoute path="/meeting" exact Component={Meetings} />
            <PrivateRoute path="/meeting-room" exact Component={MeetingRooms} />
            <PrivateRoute path="/document-number" exact Component={DocumentNumber} />
            <PrivateRoute path="/document-number-setting" exact Component={DocumentNumberSeting} />
            <PrivateRoute path="/document-number-report" exact Component={DocumentNumberReport} />
            <PrivateRoute path="/screen-saver" exact Component={Screensaver} />
            <PrivateRoute path="/screen-saver/editor" exact Component={ScreensaverEditor} />
            <PrivateRoute path="/structure/organization" exact Component={DispositionHead} />
            <PrivateRoute path="/disposition/unit" exact Component={DispositionUnit} />
            <PrivateRoute path="/disposition/action" exact Component={DispositionAction} />
            <PrivateRoute path="/trip" exact Component={Trip} />
            <Redirect to="/errors/404" />
          </Switch>
          {!isKanban && <Footer />}
        </div>
        <SidePanelModal path={location.pathname} />
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
