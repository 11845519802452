import {
  DISPOSITION_HEAD,
  DISPOSITION_HEAD_SUCCESS,
  DISPOSITION_HEAD_ERROR,
  ADD_DISPOSITION_HEAD,
  ADD_DISPOSITION_HEAD_SUCCESS,
  ADD_DISPOSITION_HEAD_ERROR,
  UPDATE_DISPOSITION_HEAD,
  UPDATE_DISPOSITION_HEAD_SUCCESS,
  UPDATE_DISPOSITION_HEAD_ERROR,
  DELETE_DISPOSITION_HEAD,
  DELETE_DISPOSITION_HEAD_SUCCESS,
  DELETE_DISPOSITION_HEAD_ERROR,
} from '../../constants/ActionTypes';

export default (
  state = {
    disposition: [],
    dispositionLoading: false,
    addDispositionLoading: false,
    updateDispositionLoading: false,
    deleteDispositionLoading: false,
  },
  action
) => {
  switch (action.type) {
    case DISPOSITION_HEAD:
      return {
        ...state,
        dispositionLoading: true,
      };
    case DISPOSITION_HEAD_SUCCESS:
      return {
        ...state,
        disposition: action.payload,
        dispositionLoading: false,
      };
    case DISPOSITION_HEAD_ERROR:
      return {
        ...state,
        dispositionLoading: false,
      };

    case ADD_DISPOSITION_HEAD:
      return {
        ...state,
        addDispositionLoading: true,
      };
    case ADD_DISPOSITION_HEAD_SUCCESS:
      return {
        ...state,
        addDispositionLoading: false,
      };
    case ADD_DISPOSITION_HEAD_ERROR:
      return {
        ...state,
        addDispositionLoading: false,
      };

    case UPDATE_DISPOSITION_HEAD:
      return {
        ...state,
        updateDispositionLoading: true,
      };
    case UPDATE_DISPOSITION_HEAD_SUCCESS:
      return {
        ...state,
        updateDispositionLoading: false,
      };
    case UPDATE_DISPOSITION_HEAD_ERROR:
      return {
        ...state,
        updateDispositionLoading: false,
      };

    case DELETE_DISPOSITION_HEAD:
      return {
        ...state,
        deleteDispositionLoading: true,
      };
    case DELETE_DISPOSITION_HEAD_SUCCESS:
      return {
        ...state,
        deleteDispositionLoading: false,
      };
    case DELETE_DISPOSITION_HEAD_ERROR:
      return {
        ...state,
        deleteDispositionLoading: false,
      };
    default:
      return state;
  }
};
