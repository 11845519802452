import { put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getHostV2 } from '../../constants';
import {
  TRIP_REQUEST_SUCCESS,
  TRIP_REQUEST_ERROR,
  UPDATE_TRIP_REQUEST_SUCCESS,
  UPDATE_TRIP_REQUEST_ERROR,
} from '../../constants/ActionTypes';

export function* fetchTripRequest(params) {
  const { token, status } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/trip-request?status=${status.join(',')}`, {
    method: 'GET',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 200) {
        return { type: TRIP_REQUEST_SUCCESS, payload: response.data };
      } else {
        toast.error(response.message);
        return { type: TRIP_REQUEST_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: TRIP_REQUEST_ERROR };
    });
  yield put(result);
}

export function* updateTripRequest(params) {
  const { id, status, car, driver, resetForm, reload, token } = params.payload;
  const result = yield fetch(`${getHostV2()}/v1/trip-request/${id}`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      status,
      car,
      driver,
    }),
  })
    .then(async (results) => {
      const response = await results.json();
      if (results.status === 201) {
        resetForm();
        reload();
        toast.success('Berhasil mengubah permintaan trip');
        return { type: UPDATE_TRIP_REQUEST_SUCCESS };
      } else {
        toast.error(response.message);
        return { type: UPDATE_TRIP_REQUEST_ERROR };
      }
    })
    .catch((error) => {
      toast.error(error.toString());
      return { type: UPDATE_TRIP_REQUEST_ERROR };
    });
  yield put(result);
}
