import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Form, FormGroup, Button, Media, Spinner, Label, Input, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import Lightbox from 'react-image-lightbox';
import moment from 'moment';
import mimeType from 'mime-types';
import { useForm } from 'react-hook-form';

import cloudUpload from '../../assets/img/icons/cloud-upload.svg';
import ContentWithAsideLayout from '../ContentWithAsideLayout';
import FalconDropzone from '../../components/common/FalconDropzone';
import {
  addScreensaver,
  updateScreensaver,
  getScreensaverVideo,
  addScreensaverVideo,
  deleteScreensaverVideo,
} from '../../redux/actions';
import { getHost } from '../../constants';

const DropzoneExample = ({ files, setFiles, field, multiple }) => {
  return (
    <Media className="flex-center pb-3 d-block d-md-flex text-center mb-2">
      <Media body className="ml">
        <FalconDropzone
          files={files[field]}
          onChange={(f) => {
            if (field === 'photos') {
              setFiles({
                ...files,
                [field]: [...files[field], ...f],
              });
            } else if (field === 'cover') {
              setFiles({
                ...files,
                [field]: f,
              });
            }
          }}
          multiple={multiple}
          accept="image/*"
          placeholder={
            <Fragment>
              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                <img src={cloudUpload} alt="" width={25} className="mr-2" />
                <Media>
                  <p className="fs-0 mb-0 text-700">Upload</p>
                </Media>
              </Media>
            </Fragment>
          }
        />
      </Media>
    </Media>
  );
};

DropzoneExample.propTypes = {
  files: PropTypes.object,
  setFiles: PropTypes.func,
  field: PropTypes.string,
  multiple: PropTypes.bool.isRequired,
};

const ScreensaverEditor = ({
  screensaver,
  screensaverVideo,
  addScreensaver,
  updateScreensaver,
  getScreensaverVideo,
  addScreensaverVideo,
  deleteScreensaverVideo,
}) => {
  const { addScreensaverLoading, updateScreensaverLoading } = screensaver;
  const { screensaversVideo, addScreensaverVideoLoading } = screensaverVideo;
  const [token, setToken] = useState(null);
  const [mode, setMode] = useState(null);
  const [form, setForm] = useState({
    id: null,
    url: '',
  });
  const [files, setFiles] = useState({
    photos: [],
  });
  const lightBoxImages = files.photos.map((el) => {
    if (el.url) {
      return el.url;
    }
    return el.base64;
  });
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    const getUserData = async (v) => {
      const data = await localStorage.getItem('loginInformation');
      if (data) {
        setToken(JSON.parse(data).data.token);
        getScreensaverVideo({ token: JSON.parse(data).data.token });
        if (v === 'Update') {
          const id = params.get('id');
          setMode(v);
          fetch(`${getHost()}/api/screen-saver/${id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${JSON.parse(data).data.token}`,
              'Content-Type': 'application/json',
            },
          })
            .then(async (results) => {
              const response = await results.json();
              if (results.status === 200) {
                setFilledForm({
                  ...response.data,
                  id,
                });
                setFiles({
                  photos: response.data.photos,
                });
              } else {
                toast.error(response.message);
              }
            })
            .catch((error) => {
              toast.error(error.toString());
            });
        }
      }
    };

    const params = new URLSearchParams(window.location.search);
    const m = params.get('mode');
    setMode(m);
    getUserData(m);
  }, [getScreensaverVideo]);

  const setFilledForm = (v) => {
    setForm({
      id: v.id,
    });
  };

  const bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  function dataURItoBlob(dataURI) {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const uploadImage = async (field, index) => {
    const formData = new FormData();
    const file = dataURItoBlob(files[field][index].base64);
    const fileName = `${moment().unix()}.${mimeType.extension(files[field][index].type)}`;
    var loadingTrue = Object.assign({}, files);
    loadingTrue[field][index].loading = true;
    setFiles(loadingTrue);
    formData.append('image', file, fileName);
    fetch(`${getHost()}/api/upload-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    })
      .then(async (results) => {
        const response = await results.json();
        if (results.status === 201) {
          var done = Object.assign({}, files);
          done[field][index].uploaded = true;
          done[field][index].loading = false;
          done[field][index].url = response.url;
          done[field][index].thumbnailUrl = response.thumbnailUrl;
          done[field][index].width = response.width;
          done[field][index].height = response.height;
          setFiles(done);
          toast.success('Uploaded');
        } else {
          var loadingFalse = Object.assign({}, files);
          loadingFalse[field][index].loading = true;
          setFiles(loadingFalse);
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error(error.toString());
      });
  };

  const resetForm = () => {
    setForm({
      id: null,
      name: '',
      description: '',
      address: '',
      product: '',
      latitude: 1.128304,
      longitude: 104.056631,
      contact: '',
      email: '',
      type: '',
    });
    setFiles({
      photos: [],
    });
  };

  const onSubmit = () => {
    const tempFiles = Object.assign({}, files);
    tempFiles.photos = tempFiles.photos
      .filter((f) => f.uploaded)
      .map((el) => {
        return {
          id: el.id,
          loading: el.loading,
          path: el.path,
          size: el.size,
          thumbnailUrl: el.thumbnailUrl,
          type: el.type,
          uploaded: el.uploaded,
          url: el.url,
          width: el.width,
          height: el.height,
        };
      });
    if (mode === 'Tambah') {
      return addScreensaver({
        ...form,
        photos: tempFiles.photos,
        resetForm,
        token,
      });
    }
    updateScreensaver({
      ...form,
      photos: tempFiles.photos,
      token,
    });
  };

  const onSubmitScreenSaverVideo = (data) => {
    addScreensaverVideo({
      getScreensaverVideo,
      token,
      ...data,
    });
  };

  return (
    <ContentWithAsideLayout
      aside={
        <Card>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmitScreenSaverVideo)}>
              <FormGroup>
                <Label for="url">
                  URL<span style={{ color: 'red' }}>*</span>{' '}
                  {errors.url && <span style={{ color: 'red' }}>Field ini tidak boleh kosong</span>}
                </Label>
                <Input
                  defaultValue={form.url}
                  type="text"
                  name="url"
                  id="url"
                  innerRef={register({ required: true })}
                />
              </FormGroup>
              <FormGroup>
                <Button disabled={addScreensaverVideoLoading} type="submit" color="primary" block className="mt-3">
                  Tambah Video Screensaver
                </Button>
              </FormGroup>
              <div style={{ height: '16px' }} />
            </Form>
            <Form>
              <FormGroup>
                <Button
                  disabled={addScreensaverLoading || updateScreensaverLoading}
                  type="button"
                  color="primary"
                  block
                  className="mt-3"
                  onClick={() => onSubmit()}
                >
                  Update Screensaver Photo
                </Button>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      }
      isStickyAside
    >
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex]}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setIsLightBoxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % lightBoxImages.length)}
          reactModalStyle={{ overlay: { zIndex: 999999 } }}
        />
      )}
      <Card>
        <CardBody>
          <h2>Screensaver Video</h2>
          <Table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '0' }}>
            <thead className="thead-light">
              <tr>
                <th>#</th>
                <th>YouTube URL</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {screensaversVideo.map((el, i) => (
                <tr key={el.id}>
                  <th>{i + 1}</th>
                  <td>{el.url}</td>
                  <td>
                    <FontAwesomeIcon
                      size="sm"
                      style={{ cursor: 'pointer' }}
                      icon={faTrash}
                      transform="grow-12 right-6"
                      className="text-danger"
                      onClick={() => deleteScreensaverVideo({ token, getScreensaverVideo, id: el._id })}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <h2>Screensaver Photo</h2>
          <DropzoneExample files={files} setFiles={setFiles} field="photos" multiple={true} />
          <div>
            <div style={{ borderWidth: '1px', border: '1px solid #d8e2ef', padding: '8px', borderRadius: '.25rem' }}>
              {files.photos.map((el, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px',
                    borderBottom: i === files.photos.length - 1 ? 'none' : '1px solid #d8e2ef',
                  }}
                >
                  <img
                    alt={el.path}
                    src={el.thumbnailUrl ? el.thumbnailUrl : el.base64}
                    style={{ width: '48px', height: '48px', objectFit: 'cover', cursor: 'pointer' }}
                    onClick={() => {
                      setIsLightBoxOpen(true);
                      setPhotoIndex(i);
                    }}
                  />
                  <div style={{ width: '25vw' }}>
                    <p style={{ marginBottom: '0px', marginLeft: '16px' }}>{el.path}</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <p style={{ marginBottom: '0px', marginLeft: '16px', justifySelf: 'end' }}>
                      {bytesToSize(el.size)}
                    </p>
                    {el.uploaded && el.thumbnailUrl ? (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <FontAwesomeIcon
                          size="sm"
                          icon={faCheck}
                          transform="grow-12 right-6"
                          className="text-success"
                          style={{ margin: '0px 8px' }}
                        />
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.photos = fl.photos.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    ) : el.loading ? (
                      <Spinner color="primary" style={{ margin: '0px 8px' }} />
                    ) : (
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            uploadImage('photos', i);
                          }}
                        >
                          <FontAwesomeIcon
                            size="sm"
                            icon={faUpload}
                            transform="grow-12 right-6"
                            className="text-primary"
                          />
                        </div>
                        <div
                          style={{ width: '24px', height: '24px', marginLeft: '8px', cursor: 'pointer' }}
                          onClick={() => {
                            var fl = Object.assign({}, files);
                            fl.photos = fl.photos.filter((f) => f.id !== el.id);
                            setFiles(fl);
                          }}
                        >
                          <FontAwesomeIcon
                            size="1x"
                            icon={faTimes}
                            transform="grow-12 right-6"
                            className="text-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <div style={{ height: '16px' }} />
        </CardBody>
      </Card>
    </ContentWithAsideLayout>
  );
};

ScreensaverEditor.propTypes = {
  screensaver: PropTypes.object,
  screensaverVideo: PropTypes.object,
  addScreensaver: PropTypes.func,
  updateScreensaver: PropTypes.func,
  getScreensaverVideo: PropTypes.func,
  addScreensaverVideo: PropTypes.func,
  deleteScreensaverVideo: PropTypes.func,
};

const mapStateToProps = (state) => ({
  screensaver: state.screensaver,
  screensaverVideo: state.screensaverVideo,
});

const mapActionToProps = {
  addScreensaver,
  updateScreensaver,
  getScreensaverVideo,
  addScreensaverVideo,
  deleteScreensaverVideo,
};

export default connect(mapStateToProps, mapActionToProps)(ScreensaverEditor);
